.element-header .element-header-nav-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50%;  /* Fully rounded corners (circle if width=height) */
  text-decoration: none;
  text-align: center;
  width: 200px;
  max-width: 100%;
}
/* Media query for screen size less than 425px */
@media screen and (max-width: 424px) {
  .element-footer .element-footer-nav-list {
    grid-gap: 10px; /* Reduce gap for smaller screens */
    justify-content: center; /* Center-align the items for smaller screens */
    left: 13px; /* Reset the left position for a more centered layout */
    position: relative;
  }

  .element-footer .element-footer-logo a {
    height: 150px; /* Reduce the logo size */
    width: 150px; /* Reduce the logo size */
    right: 20px; /* Adjust logo position */
    position: relative;
  }
}

@media screen and (max-width: 478px) {
  .element-search-bar .search-bar {
    display: block;
    height: 48px;
    padding: 0px 0px 0px 0px;
    position: relative;
    width: 100%;
    top: -91%;
  }
}
@media screen and (max-width: 478px) {
    h2.title.header.raise {
        margin-left: 100px !important; /* Forces the override */
        padding-top: 10%;
    }
}


/* Global styles */
.button-primary.on-green {
    margin-top: -9px;
    position: absolute;
    margin-left: -18.5%;
}

/* Specific styles for screen widths <= 2915px */
@media screen and (min-width: 807px) {
    .button-primary.on-green {
        margin-top: -15px;
        position: absolute;
        margin-left: -18.5%;
    }
}

/* Styles for screens larger than 1440px */
@media screen and (min-width: 1441px) {
    .button-primary.on-green {
        /* Adjust the 'top' and 'left' values for larger screens */
        top: 25px; /* Adjust this value as needed */
        left: -50px; /* Adjust this value as needed */
        position: relative; /* You can modify this if needed */
    }
}

h2.title.header {
    text-align: center;
    margin-left: 150px;
    letter-spacing: -3px;
    margin-top: -20px;

}
h2.title.header.raise {
    margin-left: 20px;
}
h3.heading-5.green.centre {
    text-align: centre;
}
h2.title.zwart {
    text-align: left;
}
h3.heading-5.white {
    text-align: left;
}
:root{
    --grid-max-width:1220px;
    --grid-default-spacing:10px;
    --header-fixed-height:40px;
    --header-inline-height:100px;
    --footer-inline-height:100px;
    --background-image:none;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
    --cell-100:100%;
    --color-action:#FF9C00;
    --color-action-dark:#CC7A00;
    --color-action-light:#FFD699;
    --color-black:#000000;
    --color-black-dark:#222222;
    --color-black-light:#555555;
    --color-gray:#B8B8B8;
    --color-gray-dark:#989898;
    --color-gray-light:#D8D8D8;
    --color-theme:#1D242E;
    --color-theme-dark:#1D242E;
    --color-theme-light:#048FCB;
    --color-theme2:#FFFFFF;
    --color-theme2-dark:#FFFFFF;
    --color-theme2-light:#FFFFFF;
    --color-theme3:#FFFFFF;
    --color-theme3-dark:#FFFFFF;
    --color-theme3-light:#FFFFFF;
    --color-white:#FFFFFF;
    --color-white-dark:#E0E0E0;
    --color-white-light:#F0F0F0;
    --color-blue:#3399FF;
    --color-blue-dark:#004D99;
    --color-blue-light:#99CCFF;
    --color-green:#3DC23D;
    --color-green-dark:#247524;
    --color-green-light:#B1E7B1;
    --color-orange:#FF6633;
    --color-orange-dark:#CC4422;
    --color-orange-light:#FFCC99;
    --color-red:#CC0000;
    --color-red-dark:#800000;
    --color-red-light:#FF6666;
    --fa-family:'Font Awesome 5 Free';
    --fb-family:'Font Awesome 5 Brand';
}
 .web-element p > img{
    float:left;
    max-width:300px;
    margin:0px 15px 15px 0px;
    vertical-align:top;
}
 .web-element p > img[align="right"]{
    float:right;
    margin:0px 0px 15px 15px;
}
 .web-element .background-image-cover{
    background-image:var(--background-image);
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    height:100%;
    min-height:20px;
    min-width:20px;
    width:100%;
}
 .web-element .background-image-contain{
    background-image:var(--background-image);
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    height:100%;
    min-height:20px;
    min-width:20px;
    width:100%;
}
 .web-element .background-image-stretch{
    background-image:var(--background-image);
    background-position:left top;
    background-repeat:no-repeat;
    background-size:100% 100%;
    height:100%;
    min-height:20px;
    min-width:20px;
    width:100%;
}
 .web-element .background-image-cover.background-image-left-top,.web-element .background-image-contain.background-image-left-top{
    background-position:left top;
}
 .web-element .background-image-cover.background-image-left-center,.web-element .background-image-contain.background-image-left-center{
    background-position:left center;
}
 .web-element .background-image-cover.background-image-left-right,.web-element .background-image-contain.background-image-left-right{
    background-position:left right;
}
 .web-element .background-image-cover.background-image-center-top,.web-element .background-image-contain.background-image-center-top{
    background-position:center top;
}
 .web-element .background-image-cover.background-image-center-center,.web-element .background-image-contain.background-image-center-center{
    background-position:center center;
}
 .web-element .background-image-cover.background-image-center-right,.web-element .background-image-contain.background-image-center-right{
    background-position:center right;
}
 .web-element .background-image-cover.background-image-right-top,.web-element .background-image-contain.background-image-right-top{
    background-position:right top;
}
 .web-element .background-image-cover.background-image-right-center,.web-element .background-image-contain.background-image-right-center{
    background-position:right center;
}
 .web-element .background-image-cover.background-image-right-right,.web-element .background-image-contain.background-image-right-right{
    background-position:right right;
}
 .web-element .image{
    overflow:hidden;
}
 .web-element .image > a,.web-element .background-image > a{
    display:block;
    height:100%;
    width:100%;
}
 .web-element .image .image-content,.web-element .background-image .image-content{
    padding:var(--grid-default-spacing);
    z-index:10;
}
 .web-element .background-image-border-radius{
    border-radius:var(--grid-default-spacing) !important;
}
 .web-element .background-image-border-radius-0{
    border-radius:none !important;
}
 .web-element .background-image-border-radius-10{
    border-radius:10px !important;
}
 .web-element .background-image-border-radius-20{
    border-radius:20px !important;
}
 .web-element .background-image-border-radius-30{
    border-radius:30px !important;
}
 .web-element .background-image-border-radius-50{
    border-radius:50% !important;
}
 .web-element .background-image-grayscale{
    filter:grayscale(80%);
}
 .web-element .background-image-grayscale:hover{
    filter:grayscale(0%);
    transition:all .5s;
}
 .web-element .background-image-mirror:hover{
    filter:FlipH;
    transform:scaleX(-1);
    transition:all .5s;
}
 .web-element .background-image-zoom:hover{
    transform:scale(1.2);
    transition:all .5s;
}
 @media (min-width:320px) {
     .web-element .background-image-300{
        background-image:var(--background-image-300);
    }
}
 @media (min-width:420px) {
     .web-element .background-image > img,.web-element .background-image > a > img{
        display:none;
    }
     .web-element .background-image-400{
        background-image:var(--background-image-400);
    }
}
 @media (min-width:520px) {
     .web-element .background-image-500{
        background-image:var(--background-image-500);
    }
}
 @media (min-width:620px) {
     .web-element .background-image-600{
        background-image:var(--background-image-600);
    }
}
 @media (min-width:720px) {
     .web-element .background-image-700{
        background-image:var(--background-image-700);
    }
}
 @media (min-width:820px) {
     .web-element .background-image-800{
        background-image:var(--background-image-800);
    }
}
 @media (min-width:920px) {
     .web-element .background-image-900{
        background-image:var(--background-image-900);
    }
}
 @media (min-width:1020px) {
     .web-element .background-image-1000{
        background-image:var(--background-image-1000);
    }
}
 @media (min-width:1120px) {
     .web-element .background-image-1100{
        background-image:var(--background-image-1100);
    }
}
 @media (min-width:1220px) {
     .web-element .background-image-1200{
        background-image:var(--background-image-1200);
    }
}
 @media (min-width:1320px) {
     .web-element .background-image-1300{
        background-image:var(--background-image-1300);
    }
}
 @media (min-width:1420px) {
     .web-element .background-image-1400{
        background-image:var(--background-image-1400);
    }
}
 @media (min-width:1520px) {
     .web-element .background-image-1500{
        background-image:var(--background-image-1500);
    }
}
 @media (min-width:1620px) {
     .web-element .background-image-1600{
        background-image:var(--background-image-1600);
    }
}
 .web-element .grid-simple{
    display:grid;
    grid-column-gap:var(--grid-default-spacing);
    grid-row-gap:var(--grid-default-spacing);
    grid-template-columns:var(--cell-100);
    margin:0;
    padding:0;
}
 .web-element .grid-simple.grid-300-10{
    grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
}
 .web-element .grid-simple.grid-300-8{
    grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
}
 .web-element .grid-simple.grid-300-6{
    grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
}
 .web-element .grid-simple.grid-300-5{
    grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
}
 .web-element .grid-simple.grid-300-4{
    grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
}
 .web-element .grid-simple.grid-300-3{
    grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
}
 .web-element .grid-simple.grid-300-2{
    grid-template-columns:var(--cell-50) var(--cell-50);
}
 .web-element .grid-simple.grid-300-1{
    grid-template-columns:var(--cell-100);
}
 .web-element .grid-simple .cell-300-10{
    grid-column-end:span 10;
}
 .web-element .grid-simple .cell-300-8{
    grid-column-end:span 8;
}
 .web-element .grid-simple .cell-300-6{
    grid-column-end:span 6;
}
 .web-element .grid-simple .cell-300-5{
    grid-column-end:span 5;
}
 .web-element .grid-simple .cell-300-4{
    grid-column-end:span 4;
}
 .web-element .grid-simple .cell-300-3{
    grid-column-end:span 3;
}
 .web-element .grid-simple .cell-300-2{
    grid-column-end:span 2;
}
 .web-element .grid-simple .cell-300-1{
    grid-column-end:span 1;
}
 @media (min-width:370px) {
     .web-element .grid-simple.grid-350-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-350-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-350-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-350-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-350-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-350-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-350-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-350-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-350-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-350-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-350-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-350-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-350-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-350-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-350-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-350-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:420px) {
     .web-element .grid-simple.grid-400-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-400-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-400-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-400-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-400-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-400-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-400-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-400-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-400-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-400-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-400-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-400-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-400-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-400-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-400-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-400-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:470px) {
     .web-element .grid-simple.grid-450-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-450-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-450-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-450-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-450-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-450-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-450-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-450-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-450-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-450-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-450-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-450-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-450-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-450-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-450-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-450-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:520px) {
     .web-element .grid-simple.grid-500-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-500-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-500-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-500-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-500-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-500-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-500-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-500-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-500-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-500-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-500-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-500-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-500-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-500-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-500-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-500-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:570px) {
     .web-element .grid-simple.grid-550-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-550-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-550-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-550-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-550-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-550-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-550-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-550-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-550-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-550-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-550-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-550-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-550-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-550-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-550-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-550-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:620px) {
     .web-element .grid-simple.grid-600-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-600-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-600-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-600-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-600-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-600-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-600-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-600-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-600-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-600-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-600-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-600-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-600-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-600-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-600-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-600-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:670px) {
     .web-element .grid-simple.grid-650-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-650-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-650-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-650-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-650-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-650-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-650-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-650-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-650-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-650-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-650-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-650-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-650-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-650-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-650-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-650-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:720px) {
     .web-element .grid-simple.grid-700-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-700-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-700-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-700-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-700-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-700-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-700-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-700-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-700-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-700-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-700-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-700-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-700-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-700-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-700-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-700-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:770px) {
     .web-element .grid-simple.grid-750-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-750-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-750-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-750-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-750-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-750-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-750-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-750-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-750-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-750-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-750-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-750-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-750-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-750-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-750-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-750-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:820px) {
     .web-element .grid-simple.grid-800-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-800-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-800-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-800-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-800-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-800-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-800-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-800-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-800-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-800-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-800-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-800-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-800-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-800-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-800-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-800-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:920px) {
     .web-element .grid-simple.grid-900-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-900-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-900-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-900-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-900-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-900-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-900-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-900-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-900-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-900-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-900-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-900-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-900-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-900-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-900-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-900-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1020px) {
     .web-element .grid-simple.grid-1000-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1000-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1000-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1000-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1000-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1000-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1000-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1000-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1000-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1000-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1000-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1000-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1000-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1000-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1000-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1000-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1120px) {
     .web-element .grid-simple.grid-1100-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1100-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1100-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1100-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1100-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1100-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1100-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1100-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1100-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1100-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1100-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1100-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1100-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1100-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1100-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1100-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1220px) {
     .web-element .grid-simple.grid-1200-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1200-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1200-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1200-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1200-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1200-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1200-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1200-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1200-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1200-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1200-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1200-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1200-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1200-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1200-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1200-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1320px) {
     .web-element .grid-simple.grid-1300-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1300-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1300-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1300-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1300-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1300-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1300-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1300-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1300-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1300-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1300-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1300-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1300-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1300-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1300-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1300-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1420px) {
     .web-element .grid-simple.grid-1400-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1400-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1400-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1400-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1400-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1400-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1400-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1400-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1400-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1400-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1400-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1400-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1400-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1400-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1400-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1400-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1520px) {
     .web-element .grid-simple.grid-1500-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1500-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1500-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1500-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1500-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1500-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1500-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1500-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1500-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1500-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1500-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1500-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1500-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1500-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1500-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1500-1{
        grid-column-end:span 1;
    }
}
 @media (min-width:1620px) {
     .web-element .grid-simple.grid-1600-10{
        grid-template-columns:var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10) var(--cell-10);
    }
     .web-element .grid-simple.grid-1600-8{
        grid-template-columns:var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12) var(--cell-12);
    }
     .web-element .grid-simple.grid-1600-6{
        grid-template-columns:var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16) var(--cell-16);
    }
     .web-element .grid-simple.grid-1600-5{
        grid-template-columns:var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20) var(--cell-20);
    }
     .web-element .grid-simple.grid-1600-4{
        grid-template-columns:var(--cell-25) var(--cell-25) var(--cell-25) var(--cell-25);
    }
     .web-element .grid-simple.grid-1600-3{
        grid-template-columns:var(--cell-33) var(--cell-33) var(--cell-33);
    }
     .web-element .grid-simple.grid-1600-2{
        grid-template-columns:var(--cell-50) var(--cell-50);
    }
     .web-element .grid-simple.grid-1600-1{
        grid-template-columns:var(--cell-100);
    }
     .web-element .grid-simple .cell-1600-10{
        grid-column-end:span 10;
    }
     .web-element .grid-simple .cell-1600-8{
        grid-column-end:span 8;
    }
     .web-element .grid-simple .cell-1600-6{
        grid-column-end:span 6;
    }
     .web-element .grid-simple .cell-1600-5{
        grid-column-end:span 5;
    }
     .web-element .grid-simple .cell-1600-4{
        grid-column-end:span 4;
    }
     .web-element .grid-simple .cell-1600-3{
        grid-column-end:span 3;
    }
     .web-element .grid-simple .cell-1600-2{
        grid-column-end:span 2;
    }
     .web-element .grid-simple .cell-1600-1{
        grid-column-end:span 1;
    }
}
 .web-element .web-grid.grid-spacing-0{
    --grid-default-spacing:0px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-10{
    --grid-default-spacing:10px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-20{
    --grid-default-spacing:20px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-30{
    --grid-default-spacing:30px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-40{
    --grid-default-spacing:40px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-50{
    --grid-default-spacing:50px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .web-grid.grid-spacing-60{
    --grid-default-spacing:60px;
    --cell-10:calc((100% - (9 * var(--grid-default-spacing))) / 10);
    --cell-12:calc((100% - (7 * var(--grid-default-spacing))) / 8);
    --cell-16:calc((100% - (5 * var(--grid-default-spacing))) / 6);
    --cell-20:calc((100% - (4 * var(--grid-default-spacing))) / 5);
    --cell-25:calc((100% - (3 * var(--grid-default-spacing))) / 4);
    --cell-33:calc((100% - (2 * var(--grid-default-spacing))) / 3);
    --cell-50:calc(((100% - var(--grid-default-spacing)) / 2));
    --cell-66:calc((((100% - (2 * var(--grid-default-spacing))) / 3) * 2) + (1 * var(--grid-default-spacing)));
    --cell-75:calc((((100% - (3 * var(--grid-default-spacing))) / 4) * 3) + (2 * var(--grid-default-spacing)));
}
 .web-element .ratio-1x1 > *,.web-element .ratio-1x2 > *,.web-element .ratio-1x3 > *,.web-element .ratio-1x4 > *,.web-element .ratio-1x5 > *,.web-element .ratio-2x1 > *,.web-element .ratio-2x2 > *,.web-element .ratio-2x3 > *,.web-element .ratio-2x4 > *,.web-element .ratio-2x5 > *,.web-element .ratio-3x1 > *,.web-element .ratio-3x2 > *,.web-element .ratio-3x3 > *,.web-element .ratio-3x4 > *,.web-element .ratio-3x5 > *,.web-element .ratio-4x1 > *,.web-element .ratio-4x2 > *,.web-element .ratio-4x3 > *,.web-element .ratio-4x4 > *,.web-element .ratio-4x5 > *,.web-element .ratio-5x1 > *,.web-element .ratio-5x2 > *,.web-element .ratio-5x3 > *,.web-element .ratio-5x4 > *,.web-element .ratio-5x5 > *,.web-element .ratio-16x9 > *{
    display:block;
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
}
 .web-element .ratio-1x1{
    height:auto;
    overflow:hidden;
    padding-bottom:100%;
}
 .web-element .ratio-1x2{
    height:auto;
    overflow:hidden;
    padding-bottom:200%;
}
 .web-element .ratio-1x3{
    height:auto;
    overflow:hidden;
    padding-bottom:300%;
}
 .web-element .ratio-1x4{
    height:auto;
    overflow:hidden;
    padding-bottom:400%;
}
 .web-element .ratio-1x5{
    height:auto;
    overflow:hidden;
    padding-bottom:500%;
}
 .web-element .ratio-2x1{
    height:auto;
    overflow:hidden;
    padding-bottom:50%;
}
 .web-element .ratio-2x2{
    height:auto;
    overflow:hidden;
    padding-bottom:100%;
}
 .web-element .ratio-2x3{
    height:auto;
    overflow:hidden;
    padding-bottom:150%;
}
 .web-element .ratio-2x4{
    height:auto;
    overflow:hidden;
    padding-bottom:200%;
}
 .web-element .ratio-2x5{
    height:auto;
    overflow:hidden;
    padding-bottom:250%;
}
 .web-element .ratio-3x1{
    height:auto;
    overflow:hidden;
    padding-bottom:33%;
}
 .web-element .ratio-3x2{
    height:auto;
    overflow:hidden;
    padding-bottom:66%;
}
 .web-element .ratio-3x3{
    height:auto;
    overflow:hidden;
    padding-bottom:100%;
}
 .web-element .ratio-3x4{
    height:auto;
    overflow:hidden;
    padding-bottom:133%;
}
 .web-element .ratio-3x5{
    height:auto;
    overflow:hidden;
    padding-bottom:166%;
}
 .web-element .ratio-4x1{
    height:auto;
    overflow:hidden;
    padding-bottom:25%;
}
 .web-element .ratio-4x2{
    height:auto;
    overflow:hidden;
    padding-bottom:50%;
}
 .web-element .ratio-4x3{
    height:auto;
    overflow:hidden;
    padding-bottom:75%;
}
 .web-element .ratio-4x4{
    height:auto;
    overflow:hidden;
    padding-bottom:100%;
}
 .web-element .ratio-4x5{
    height:auto;
    overflow:hidden;
    padding-bottom:125%;
}
 .web-element .ratio-5x1{
    height:auto;
    overflow:hidden;
    padding-bottom:20%;
}
 .web-element .ratio-5x2{
    height:auto;
    overflow:hidden;
    padding-bottom:40%;
}
 .web-element .ratio-5x3{
    height:auto;
    overflow:hidden;
    padding-bottom:60%;
}
 .web-element .ratio-5x4{
    height:auto;
    overflow:hidden;
    padding-bottom:80%;
}
 .web-element .ratio-5x5{
    height:auto;
    overflow:hidden;
    padding-bottom:100%;
}
 .web-element .ratio-16x9{
    height:auto;
    overflow:hidden;
    padding-bottom:56.25%;
}
 @media (min-width:320px) {
     .web-element .ratio-300-1x1{
        padding-bottom:100%;
    }
     .web-element .ratio-300-1x2{
        padding-bottom:200%;
    }
     .web-element .ratio-300-1x3{
        padding-bottom:300%;
    }
     .web-element .ratio-300-1x4{
        padding-bottom:400%;
    }
     .web-element .ratio-300-1x5{
        padding-bottom:500%;
    }
     .web-element .ratio-300-2x1{
        padding-bottom:50%;
    }
     .web-element .ratio-300-2x2{
        padding-bottom:100%;
    }
     .web-element .ratio-300-2x3{
        padding-bottom:150%;
    }
     .web-element .ratio-300-2x4{
        padding-bottom:200%;
    }
     .web-element .ratio-300-2x5{
        padding-bottom:250%;
    }
     .web-element .ratio-300-3x1{
        padding-bottom:33%;
    }
     .web-element .ratio-300-3x2{
        padding-bottom:66%;
    }
     .web-element .ratio-300-3x3{
        padding-bottom:100%;
    }
     .web-element .ratio-300-3x4{
        padding-bottom:133%;
    }
     .web-element .ratio-300-3x5{
        padding-bottom:166%;
    }
     .web-element .ratio-300-4x1{
        padding-bottom:25%;
    }
     .web-element .ratio-300-4x2{
        padding-bottom:50%;
    }
     .web-element .ratio-300-4x3{
        padding-bottom:75%;
    }
     .web-element .ratio-300-4x4{
        padding-bottom:100%;
    }
     .web-element .ratio-300-4x5{
        padding-bottom:125%;
    }
     .web-element .ratio-300-5x1{
        padding-bottom:20%;
    }
     .web-element .ratio-300-5x2{
        padding-bottom:40%;
    }
     .web-element .ratio-300-5x3{
        padding-bottom:60%;
    }
     .web-element .ratio-300-5x4{
        padding-bottom:80%;
    }
     .web-element .ratio-300-5x5{
        padding-bottom:100%;
    }
     .web-element .ratio-300-16x9{
        padding-bottom:56.25%;
    }
}
 @media (min-width:620px) {
     .web-element .ratio-600-1x1{
        padding-bottom:100%;
    }
     .web-element .ratio-600-1x2{
        padding-bottom:200%;
    }
     .web-element .ratio-600-1x3{
        padding-bottom:300%;
    }
     .web-element .ratio-600-1x4{
        padding-bottom:400%;
    }
     .web-element .ratio-600-1x5{
        padding-bottom:500%;
    }
     .web-element .ratio-600-2x1{
        padding-bottom:50%;
    }
     .web-element .ratio-600-2x2{
        padding-bottom:100%;
    }
     .web-element .ratio-600-2x3{
        padding-bottom:150%;
    }
     .web-element .ratio-600-2x4{
        padding-bottom:200%;
    }
     .web-element .ratio-600-2x5{
        padding-bottom:250%;
    }
     .web-element .ratio-600-3x1{
        padding-bottom:33%;
    }
     .web-element .ratio-600-3x2{
        padding-bottom:66%;
    }
     .web-element .ratio-600-3x3{
        padding-bottom:100%;
    }
     .web-element .ratio-600-3x4{
        padding-bottom:133%;
    }
     .web-element .ratio-600-3x5{
        padding-bottom:166%;
    }
     .web-element .ratio-600-4x1{
        padding-bottom:25%;
    }
     .web-element .ratio-600-4x2{
        padding-bottom:50%;
    }
     .web-element .ratio-600-4x3{
        padding-bottom:75%;
    }
     .web-element .ratio-600-4x4{
        padding-bottom:100%;
    }
     .web-element .ratio-600-4x5{
        padding-bottom:125%;
    }
     .web-element .ratio-600-5x1{
        padding-bottom:20%;
    }
     .web-element .ratio-600-5x2{
        padding-bottom:40%;
    }
     .web-element .ratio-600-5x3{
        padding-bottom:60%;
    }
     .web-element .ratio-600-5x4{
        padding-bottom:80%;
    }
     .web-element .ratio-600-5x5{
        padding-bottom:100%;
    }
     .web-element .ratio-600-16x9{
        padding-bottom:56.25%;
    }
}
 @media (min-width:920px) {
     .web-element .ratio-900-1x1{
        padding-bottom:100%;
    }
     .web-element .ratio-900-1x2{
        padding-bottom:200%;
    }
     .web-element .ratio-900-1x3{
        padding-bottom:300%;
    }
     .web-element .ratio-900-1x4{
        padding-bottom:400%;
    }
     .web-element .ratio-900-1x5{
        padding-bottom:500%;
    }
     .web-element .ratio-900-2x1{
        padding-bottom:50%;
    }
     .web-element .ratio-900-2x2{
        padding-bottom:100%;
    }
     .web-element .ratio-900-2x3{
        padding-bottom:150%;
    }
     .web-element .ratio-900-2x4{
        padding-bottom:200%;
    }
     .web-element .ratio-900-2x5{
        padding-bottom:250%;
    }
     .web-element .ratio-900-3x1{
        padding-bottom:33%;
    }
     .web-element .ratio-900-3x2{
        padding-bottom:66%;
    }
     .web-element .ratio-900-3x3{
        padding-bottom:100%;
    }
     .web-element .ratio-900-3x4{
        padding-bottom:133%;
    }
     .web-element .ratio-900-3x5{
        padding-bottom:166%;
    }
     .web-element .ratio-900-4x1{
        padding-bottom:25%;
    }
     .web-element .ratio-900-4x2{
        padding-bottom:50%;
    }
     .web-element .ratio-900-4x3{
        padding-bottom:75%;
    }
     .web-element .ratio-900-4x4{
        padding-bottom:100%;
    }
     .web-element .ratio-900-4x5{
        padding-bottom:125%;
    }
     .web-element .ratio-900-5x1{
        padding-bottom:20%;
    }
     .web-element .ratio-900-5x2{
        padding-bottom:40%;
    }
     .web-element .ratio-900-5x3{
        padding-bottom:60%;
    }
     .web-element .ratio-900-5x4{
        padding-bottom:80%;
    }
     .web-element .ratio-900-5x5{
        padding-bottom:100%;
    }
     .web-element .ratio-900-16x9{
        padding-bottom:56.25%;
    }
}
 @media (min-width:1220px) {
     .web-element .ratio-1200-1x1{
        padding-bottom:100%;
    }
     .web-element .ratio-1200-1x2{
        padding-bottom:200%;
    }
     .web-element .ratio-1200-1x3{
        padding-bottom:300%;
    }
     .web-element .ratio-1200-1x4{
        padding-bottom:400%;
    }
     .web-element .ratio-1200-1x5{
        padding-bottom:500%;
    }
     .web-element .ratio-1200-2x1{
        padding-bottom:50%;
    }
     .web-element .ratio-1200-2x2{
        padding-bottom:100%;
    }
     .web-element .ratio-1200-2x3{
        padding-bottom:150%;
    }
     .web-element .ratio-1200-2x4{
        padding-bottom:200%;
    }
     .web-element .ratio-1200-2x5{
        padding-bottom:250%;
    }
     .web-element .ratio-1200-3x1{
        padding-bottom:33%;
    }
     .web-element .ratio-1200-3x2{
        padding-bottom:66%;
    }
     .web-element .ratio-1200-3x3{
        padding-bottom:100%;
    }
     .web-element .ratio-1200-3x4{
        padding-bottom:133%;
    }
     .web-element .ratio-1200-3x5{
        padding-bottom:166%;
    }
     .web-element .ratio-1200-4x1{
        padding-bottom:25%;
    }
     .web-element .ratio-1200-4x2{
        padding-bottom:50%;
    }
     .web-element .ratio-1200-4x3{
        padding-bottom:75%;
    }
     .web-element .ratio-1200-4x4{
        padding-bottom:100%;
    }
     .web-element .ratio-1200-4x5{
        padding-bottom:125%;
    }
     .web-element .ratio-1200-5x1{
        padding-bottom:20%;
    }
     .web-element .ratio-1200-5x2{
        padding-bottom:40%;
    }
     .web-element .ratio-1200-5x3{
        padding-bottom:60%;
    }
     .web-element .ratio-1200-5x4{
        padding-bottom:80%;
    }
     .web-element .ratio-1200-5x5{
        padding-bottom:100%;
    }
     .web-element .ratio-1200-16x9{
        padding-bottom:56.25%;
    }
}
 @media (min-width:1520px) {
     .web-element .ratio-1500-1x1{
        padding-bottom:100%;
    }
     .web-element .ratio-1500-1x2{
        padding-bottom:200%;
    }
     .web-element .ratio-1500-1x3{
        padding-bottom:300%;
    }
     .web-element .ratio-1500-1x4{
        padding-bottom:400%;
    }
     .web-element .ratio-1500-1x5{
        padding-bottom:500%;
    }
     .web-element .ratio-1500-2x1{
        padding-bottom:50%;
    }
     .web-element .ratio-1500-2x2{
        padding-bottom:100%;
    }
     .web-element .ratio-1500-2x3{
        padding-bottom:150%;
    }
     .web-element .ratio-1500-2x4{
        padding-bottom:200%;
    }
     .web-element .ratio-1500-2x5{
        padding-bottom:250%;
    }
     .web-element .ratio-1500-3x1{
        padding-bottom:33%;
    }
     .web-element .ratio-1500-3x2{
        padding-bottom:66%;
    }
     .web-element .ratio-1500-3x3{
        padding-bottom:100%;
    }
     .web-element .ratio-1500-3x4{
        padding-bottom:133%;
    }
     .web-element .ratio-1500-3x5{
        padding-bottom:166%;
    }
     .web-element .ratio-1500-4x1{
        padding-bottom:25%;
    }
     .web-element .ratio-1500-4x2{
        padding-bottom:50%;
    }
     .web-element .ratio-1500-4x3{
        padding-bottom:75%;
    }
     .web-element .ratio-1500-4x4{
        padding-bottom:100%;
    }
     .web-element .ratio-1500-4x5{
        padding-bottom:125%;
    }
     .web-element .ratio-1500-5x1{
        padding-bottom:20%;
    }
     .web-element .ratio-1500-5x2{
        padding-bottom:40%;
    }
     .web-element .ratio-1500-5x3{
        padding-bottom:60%;
    }
     .web-element .ratio-1500-5x4{
        padding-bottom:80%;
    }
     .web-element .ratio-1500-5x5{
        padding-bottom:100%;
    }
     .web-element .ratio-1500-16x9{
        padding-bottom:56.25%;
    }
}
 .web-elememt .owl-carousel,.web-elememt .owl-carousel .owl-item{
    -webkit-tap-highlight-color:transparent;
    position:relative
}
 .web-elememt .owl-carousel{
    display:none;
    width:100%;
    z-index:1
}
 .web-elememt .owl-carousel .owl-stage{
    position:relative;
    -ms-touch-action:pan-Y;
    touch-action:manipulation;
    -moz-backface-visibility:hidden
}
 .web-elememt .owl-carousel .owl-stage:after{
    content:".";
    display:block;
    clear:both;
    visibility:hidden;
    line-height:0;
    height:0
}
 .web-elememt .owl-carousel .owl-stage-outer{
    position:relative;
    overflow:hidden;
    -webkit-transform:translate3d(0,0,0)
}
 .web-elememt .owl-carousel .owl-item,.web-elememt .owl-carousel .owl-wrapper{
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0)
}
 .web-elememt .owl-carousel .owl-item{
    min-height:1px;
    float:left;
    -webkit-backface-visibility:hidden;
    -webkit-touch-callout:none
}
 .web-elememt .owl-carousel .owl-item img{
    display:block;
    width:100%
}
 .web-elememt .owl-carousel .owl-dots.disabled,.web-elememt .owl-carousel .owl-nav.disabled{
    display:none
}
 .web-elememt .no-js .owl-carousel,.web-elememt .owl-carousel.owl-loaded{
    display:block
}
 .web-elememt .owl-carousel .owl-dot,.web-elememt .owl-carousel .owl-nav .owl-next,.web-elememt .owl-carousel .owl-nav .owl-prev{
    cursor:pointer;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
 .web-elememt .owl-carousel .owl-nav .owl-next,.web-elememt .owl-carousel .owl-nav .owl-prev,.web-elememt .owl-carousel .owl-dot{
    background:0 0;
    color:inherit;
    border:none;
    padding:0!important;
    font:inherit
}
 .web-elememt .owl-carousel.owl-loading{
    opacity:0;
    display:block
}
 .web-elememt .owl-carousel.owl-hidden{
    opacity:0
}
 .web-elememt .owl-carousel.owl-refresh .owl-item{
    visibility:hidden
}
 .web-elememt .owl-carousel.owl-drag .owl-item{
    -ms-touch-action:pan-y;
    touch-action:pan-y;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
 .web-elememt .owl-carousel.owl-grab{
    cursor:move;
    cursor:grab
}
 .web-elememt .owl-carousel.owl-rtl{
    direction:rtl
}
 .web-elememt .owl-carousel.owl-rtl .owl-item{
    float:right
}
 .web-elememt .owl-carousel .animated{
    animation-duration:1s;
    animation-fill-mode:both
}
 .web-elememt .owl-carousel .owl-animated-in{
    z-index:0
}
 .web-elememt .owl-carousel .owl-animated-out{
    z-index:1
}
 .web-elememt .owl-carousel .fadeOut{
    animation-name:fadeOut
}
 @keyframes fadeOut{
    0%{
        opacity:1
    }
     100%{
        opacity:0
    }
}
 .web-elememt .owl-height{
    transition:height .5s ease-in-out
}
 .web-elememt .owl-carousel .owl-item .owl-lazy{
    opacity:0;
    transition:opacity .4s ease
}
 .web-elememt .owl-carousel .owl-item .owl-lazy:not([src]),.web-elememt .owl-carousel .owl-item .owl-lazy[src^=""]{
    max-height:0
}
 .web-elememt .owl-carousel .owl-item img.owl-lazy{
    transform-style:preserve-3d
}
 .web-elememt .owl-carousel .owl-video-wrapper{
    position:relative;
    height:100%;
    background:#000
}
 .web-elememt .owl-carousel .owl-video-play-icon{
    position:absolute;
    height:80px;
    width:80px;
    left:50%;
    top:50%;
    margin-left:-40px;
    margin-top:-40px;
    /* background:url(owl.video.play.png) no-repeat; */
    cursor:pointer;
    z-index:1;
    -webkit-backface-visibility:hidden;
    transition:transform .1s ease
}
 .web-elememt .owl-carousel .owl-video-play-icon:hover{
    -ms-transform:scale(1.3,1.3);
    transform:scale(1.3,1.3)
}
 .web-elememt .owl-carousel .owl-video-playing .owl-video-play-icon,.web-elememt .owl-carousel .owl-video-playing .owl-video-tn{
    display:none
}
 .web-elememt .owl-carousel .owl-video-tn{
    opacity:0;
    height:100%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    transition:opacity .4s ease
}
 .web-elememt .owl-carousel .owl-video-frame{
    position:relative;
    z-index:1;
    height:100%;
    width:100%
}
 .web-elememt .owl-theme .owl-dots,.web-elememt .owl-theme .owl-nav{
    text-align:center;
    -webkit-tap-highlight-color:transparent
}
 .web-elememt .owl-theme .owl-nav{
    margin-top:10px
}
 .web-elememt .owl-theme .owl-nav [class*=owl-]{
    color:#FFF;
    font-size:14px;
    margin:5px;
    padding:4px 7px;
    background:#D6D6D6;
    display:inline-block;
    cursor:pointer;
}
 .web-elememt .owl-theme .owl-nav [class*=owl-]:hover{
    background:#869791;
    color:#FFF;
    text-decoration:none
}
 .web-elememt .owl-theme .owl-nav .disabled{
    opacity:.5;
    cursor:default
}
 .web-elememt .owl-theme .owl-nav.disabled+.owl-dots{
    margin-top:10px
}
 .web-elememt .owl-theme .owl-dots .owl-dot{
    display:inline-block;
    zoom:1
}
 .web-elememt .owl-theme .owl-dots .owl-dot span{
    width:10px;
    height:10px;
    margin:5px 7px;
    background:#D6D6D6;
    display:block;
    -webkit-backface-visibility:visible;
    transition:opacity .2s ease;
}
 .web-elememt .owl-theme .owl-dots .owl-dot.active span,.web-elememt .owl-theme .owl-dots .owl-dot:hover span{
    background:#869791
}
 .formbuilder .formfield-row{
    margin-bottom:10px;
}
 .formbuilder .formfield-columns{
    display:grid;
    grid-template-columns:100%;
}
 @media (min-width:520px) {
     .formbuilder .formfield-columns{
        grid-template-columns:160px auto;
    }
     .formbuilder .formbuilder-buttons{
        padding-left:160px;
    }
}
 .formbuilder .formfield-label{
    font-weight:bold;
}
 .formbuilder label{
    box-sizing:border-box;
    display:block;
}
 .formbuilder label[for]{
    cursor:pointer;
}
 .formbuilder label.disabled{
    cursor:default;
}
 .formbuilder .label,.formbuilder .text{
    line-height:150%;
    min-height:30px;
    padding:5px 0px 0px 0px;
}
 .formbuilder .input{
    min-height:30px;
}
 .formbuilder .input + .input{
    margin-top:4px;
}
 .formbuilder .input-labels{
    line-height:100%;
    overflow:hidden;
}
 .formbuilder .input-label{
    color:#888888;
    font-size:10px;
    font-style:italic;
    line-height:100%;
    min-height:14px;
    padding:3px 0px 0px 10px;
}
 .formbuilder .label > em{
    color:#FF0000;
}
 .formbuilder .input *::placeholder{
    color:#CCCCCC;
}
 .formbuilder .input-scrollable .formfield-input{
    border:#888888 solid 1px;
    max-height:145px;
    overflow-y:auto;
    padding:10px 10px 30px 10px;
}
 .formbuilder .input-labels .size-icon,.formbuilder .input .size-icon{
    display:inline-block !important;
    vertical-align:top;
    width:30px;
}
 .formbuilder .input-labels .size-small,.formbuilder .input .size-small{
    display:inline-block !important;
    vertical-align:top;
    width:100px;
}
 body .formbuilder .input-labels .size-medium,body .formbuilder .input-labels .size-auto,body .formbuilder .input .size-medium,body .formbuilder .input .size-auto{
    display:inline-block;
    vertical-align:top;
    width:210px;
}
 body .formbuilder .input-labels .size-large,body .formbuilder .input .size-large{
    display:inline-block;
    vertical-align:top;
    width:430px;
}
 .formbuilder .input-labels .size-small + *,.formbuilder .input-labels .size-medium + *,.formbuilder .input-labels .size-large + *,.formbuilder .input-labels .size-auto + *,.formbuilder .input .size-small + *,.formbuilder .input .size-medium + *,.formbuilder .input .size-large + *,.formbuilder .input .size-auto + *{
    margin-left:10px;
}
 .formbuilder .input-labels * + .size-icon,.formbuilder .input * + .size-icon{
    margin-left:3px;
}
 .formbuilder .input .chosen-select + .chosen-container,.formbuilder .input .chosen-select-required + .chosen-container{
    margin-left:0px;
}
 .formbuilder .input .chosen-select.select-units + .chosen-container,.formbuilder .input .chosen-select-required.select-units + .chosen-container{
    margin-left:10px;
}
 .formbuilder .formfield-input-sample .input-sample{
    background-color:#CCCCCC;
    border:#CCCCCC solid 1px;
    border-radius:6px;
    min-height:30px;
    padding:8px 5px 3px 5px;
}
 .formbuilder .formfield-input-sample .input-sample.language{
    background-position:5px 7px;
    background-repeat:no-repeat;
    background-size:20px 15px;
    padding-left:32px;
}
 .formbuilder .formfield-instruction{
    font-size:80%;
    padding:5px 10px 10px 10px;
}
 .formbuilder .formbuilder-buttons button{
    display:inline-block;
    margin-right:5px;
}
 .formbuilder button.formbuilder-icon{
    background-color:#DDDDDD;
    border:#888888 solid 1px;
    border-radius:4px;
    display:block;
    line-height:30px;
    height:30px;
    overflow:hidden;
    padding:0px;
    text-align:center;
}
 .formbuilder button.formbuilder-icon::before{
    left:-1px;
    top:-1px;
}
 .formbuilder table + .input-message,.formbuilder table + .input-message + .input-message,.formbuilder table + .input-message + .input-message + .input-message{
    top:-12px;
}
 .formbuilder .input-message{
    color:#000000;
    margin-bottom:4px;
    margin-top:4px;
    padding:5px 30px 5px 30px;
}
 .formbuilder .input-message::before{
    color:rgba(160,160,160,1);
    content:'\f059';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    font-style:normal;
    font-size:16px;
    font-variant:normal;
    height:36px;
    left:0px;
    line-height:32px;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:-4px;
    width:26px;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .input-message .close{
    cursor:pointer;
    height:24px;
    position:absolute;
    right:3px;
    text-align:center;
    top:5px;
    width:24px;
}
 .formbuilder .input-message .close:before{
    color:inherit;
    content:' ';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-size:inherit;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .textarea + .input-message{
    margin-top:0px;
}
 .formbuilder .input-message > code{
    display:block;
    font-family:"Courier New",Courier;
    font-size:11px;
}
 .formbuilder .input-message-debug::before{
    color:rgba(160,160,160,1);
    content:'\f188';
}
 .formbuilder .input-message-error::before{
    color:rgba(235,30,35,1);
    content:'\f071';
}
 .formbuilder .input-message-hint::before{
    color:rgba(62,131,167,1);
    content:'\f059';
}
 .formbuilder .input-message-notice::before{
    color:rgba(62,131,167,1);
    content:'\f05a';
}
 .formbuilder .input-message-success::before{
    color:rgba(138,176,62,1);
    content:'\f058';
}
 .formbuilder .input-message-warning::before{
    color:rgba(248,124,0,1);
    content:'\f071';
}
 .formbuilder .validation-error{
    border:#CC0000 solid 1px !important;
    background-color:#FFF0F0;
}
 .formbuilder .validation-warning{
    border:#FF9933 solid 1px !important;
    background-color:#FFF0E0;
}
 .formbuilder input[type="color"],.formbuilder input[type="date"],.formbuilder input[type="datetime-local"],.formbuilder input[type="email"],.formbuilder input[type="number"],.formbuilder input[type="password"],.formbuilder input[type="tel"],.formbuilder input[type="text"],.formbuilder input[type="time"],.formbuilder input[type="url"]{
    border:#888888 solid 1px;
    border-radius:4px;
    box-sizing:border-box;
    display:block;
    font-family:inherit;
    font-size:inherit;
    height:30px;
    line-height:28px;
    outline:none;
    padding:5px 0px 4px 9px;
    vertical-align:top;
    width:100%;
}
 .formbuilder .input input[type="color"].textfield-color{
    cursor:pointer;
    padding:0px 2px 0px 2px;
    text-transform:uppercase;
    width:30px;
}
 .formbuilder .input input.textfield-country{
    text-transform:uppercase;
}
 .formbuilder .input input[type="text"].textfield-color + .preview-color{
    background-color:#FFFFFF;
    border:#AAAAAA solid 1px;
    border-radius:4px;
    cursor:pointer;
    display:inline-block;
    height:30px;
    margin-left:4px;
    position:absolute;
    width:30px;
}
 .formbuilder .input input[type="color"][data-value]::after{
    content:attr(data-value);
    display:block;
    left:44px;
    position:absolute;
    text-transform:uppercase;
    top:4px;
}
 .formbuilder .input .size-icon input[type="color"]{
    box-sizing:border-box;
    cursor:pointer;
    display:block;
    height:30px;
    padding:0px 2px 0px 2px;
    text-transform:uppercase;
    width:30px;
}
 .formbuilder input[disabled][type="color"],.formbuilder input[disabled][type="date"],.formbuilder input[disabled][type="datetime-local"],.formbuilder input[disabled][type="email"],.formbuilder input[disabled][type="number"],.formbuilder input[disabled][type="password"],.formbuilder input[disabled][type="tel"],.formbuilder input[disabled][type="text"],.formbuilder input[disabled][type="time"],.formbuilder input[disabled][type="url"]{
    background-color:#DDDDDD;
}
 .formbuilder .input button.textarea-translation,.formbuilder .input button.textfield-password-random,.formbuilder .input button.textfield-translation{
    background-color:#CCCCCC;
    border:none;
    border-radius:6px;
    height:30px;
}
 .formbuilder .input button.textarea-translation::after,.formbuilder .input button.textfield-translation::after{
    content:'\f15d';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-size:16px;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    line-height:30px;
    text-align:center;
    text-rendering:auto;
    width:100%;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .input .textfield:focus{
    border:#222222 solid 1px;
    outline:none;
}
 .formbuilder .error .input .textfield{
    /* background-color:#FFF0F0;
    */
     color:#FF0000;
}
 .formbuilder .error .input .textfield:focus{
    border:#FF0000 solid 1px;
}
 .formbuilder .warning .input .textfield{
    /* background-color:#FFF0E0;
    */
     color:#FF9966;
}
 .formbuilder .warning .input .textfield:focus{
    border:#FF9966 solid 1px;
}
 .formbuilder .input .textfield.textfield-code,.formbuilder .input .textfield.textfield-color,.formbuilder .input .textfield.textfield-country,.formbuilder .input .textfield.textfield-serial{
    text-transform:uppercase;
}
 .formbuilder .input .textfield.textfield-email,.formbuilder .input .textfield.textfield-hash,.formbuilder .input .textfield.textfield-ip,.formbuilder .input .textfield.textfield-ip4,.formbuilder .input .textfield.textfield-ip6,.formbuilder .input .textfield.textfield-slug{
    text-transform:lowercase;
}
 .formbuilder .input .textfield::placeholder{
    text-transform:none;
}
 .formbuilder .input .textarea{
    border:#AAAAAA solid 1px;
    border-radius:4px;
    box-sizing:border-box;
    display:block;
    font-family:inherit;
    font-size:inherit;
    height:78px;
    padding:7px 7px 7px 9px;
    resize:vertical;
    width:100%;
}
 .formbuilder .input .textarea:focus{
    border:#AAAAAA solid 1px;
    box-shadow:0 0 0 1px #222222;
    outline:none;
    /* outline:#CCCCCC auto 1px;
    */
}
 .formbuilder select.select{
    background-color:#FFFFFF;
    border:#888888 solid 1px;
    border-radius:4px;
    box-sizing:border-box;
    color:inherit;
    display:block;
    font-family:inherit;
    font-size:inherit;
    height:30px;
    line-height:28px;
    outline:none;
    padding:5px 0px 4px 4px;
    vertical-align:top;
    width:100%;
}
 .formbuilder input.textfield option[disabled],.formbuilder select.select option[disabled]{
    color:#999999;
}
 .formbuilder select.select option.fas{
    font-family:inherit;
    font-size:inherit;
    font-style:inherit;
    font-variant:inherit;
    font-weight:inherit;
}
 .formbuilder select.select option.fas::before{
    color:inherit;
    display:inline;
    font-family:'Font Awesome 5 Free';
    font-size:inherit;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    line-height:inherit;
    margin-right:4px;
    text-rendering:auto;
}
 body .chosen-container{
    position:relative;
    display:inline-block;
    vertical-align:top;
    font-size:inherit;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
 body .chosen-container *{
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
}
 body .chosen-container .chosen-drop{
    position:absolute;
    top:100%;
    z-index:1010;
    width:100%;
    border:1px solid #888888;
    border-top:0;
    background:#fff;
    clip:rect(0,0,0,0);
    -webkit-clip-path:inset(100% 100%);
    clip-path:inset(100% 100%);
    outline:none;
}
 body .chosen-container.chosen-with-drop .chosen-drop{
    clip:auto;
    -webkit-clip-path:none;
    clip-path:none;
}
 body .chosen-container a{
    cursor:pointer;
}
 body .chosen-container .search-choice .group-name,.chosen-container .chosen-single .group-name{
    margin-right:4px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-weight:normal;
    color:#999999;
}
 body .chosen-container .search-choice .group-name:after,.chosen-container .chosen-single .group-name:after{
    content:":";
    padding-left:2px;
    vertical-align:top;
}
 body .chosen-container-single .chosen-single{
    position:relative;
    display:block;
    overflow:hidden;
    padding:0 0 0 10px;
    height:30px;
    border:1px solid #888888;
    border-radius:5px;
    background-color:#fff;
    background-clip:padding-box;
    color:#000000;
    text-decoration:none;
    white-space:nowrap;
    line-height:28px;
    outline:none;
}
 body .chosen-container-single .chosen-default{
    color:#000000;
}
 body .chosen-container-single .chosen-single span{
    display:block;
    overflow:hidden;
    margin-right:26px;
    text-overflow:ellipsis;
    white-space:nowrap;
}
 body .chosen-container-single .chosen-single-with-deselect span{
    margin-right:38px;
}
 body .chosen-container-single .chosen-single abbr{
    position:absolute;
    top:6px;
    right:26px;
    display:block;
    width:12px;
    height:12px;
    /* background:url("images/chosen-sprite.png") -42px 1px no-repeat; */
    font-size:1px;
}
 body .chosen-container-single .chosen-single abbr:hover{
    background-position:-42px -10px;
}
 body .chosen-container-single.chosen-disabled .chosen-single abbr:hover{
    background-position:-42px -10px;
}
 body .chosen-container-single .chosen-single div{
    position:absolute;
    top:3px;
    right:0;
    display:block;
    width:18px;
    height:100%;
}
 body .chosen-container-single .chosen-single div b{
    display:block;
    width:100%;
    height:100%;
    /* background:url("images/chosen-sprite.png") no-repeat 0px 2px; */
}
 body .chosen-container-single .chosen-search{
    position:relative;
    z-index:1010;
    margin:0;
    padding:3px 4px;
    white-space:nowrap;
}
 body .chosen-container-single .chosen-search input[type="text"]{
    margin:1px 0;
    padding:4px 20px 4px 5px;
    width:100%;
    height:auto;
    outline:0;
    border:1px solid #888888;
    /* background:url("images/chosen-sprite.png") no-repeat 100% -20px; */
    font-size:1em;
    font-family:inherit;
    line-height:normal;
    border-radius:0;
}
 body .chosen-container-single .chosen-drop{
    margin-top:-1px;
    border-radius:0 0 4px 4px;
    background-clip:padding-box;
}
 body .chosen-container-single.chosen-container-single-nosearch .chosen-search{
    position:absolute;
    clip:rect(0,0,0,0);
    -webkit-clip-path:inset(100% 100%);
    clip-path:inset(100% 100%);
}
 body .chosen-container .chosen-results{
    color:#000000;
    position:relative;
    overflow-x:hidden;
    overflow-y:auto;
    margin:0 4px 4px 0;
    padding:0 0 0 4px;
    max-height:240px;
    -webkit-overflow-scrolling:touch;
}
 body .chosen-container .chosen-results li{
    display:none;
    margin:0;
    padding:5px 6px;
    list-style:none;
    line-height:15px;
    word-wrap:break-word;
    -webkit-touch-callout:none;
}
 body .chosen-container .chosen-results li.active-result{
    display:list-item;
    cursor:pointer;
}
 body .chosen-container .chosen-results li.disabled-result{
    display:list-item;
    color:#ccc;
    cursor:default;
}
 body .chosen-container .chosen-results li.highlighted{
    background-color:#3875d7;
    color:#fff;
}
 body .chosen-container .chosen-results li.no-results{
    color:#777;
    display:list-item;
    background:#f4f4f4;
}
 body .chosen-container .chosen-results li.group-result{
    display:list-item;
    font-weight:bold;
    cursor:default;
}
 body .chosen-container .chosen-results li.group-option{
    padding-left:15px;
}
 body .chosen-container .chosen-results li em{
    font-style:normal;
    text-decoration:underline;
}
 body .chosen-container-multi .chosen-choices{
    position:relative;
    overflow:hidden;
    margin:0;
    padding:0 5px;
    width:100%;
    height:auto;
    border:1px solid #888888;
    background-color:#fff;
    cursor:text;
}
 body .chosen-container-multi .chosen-choices li{
    float:left;
    list-style:none;
}
 body .chosen-container-multi .chosen-choices li.search-field{
    margin:0;
    padding:0;
    white-space:nowrap;
}
 body .chosen-container-multi .chosen-choices li.search-field input[type="text"]{
    margin:1px 0;
    padding:0;
    height:25px;
    outline:0;
    border:0 !important;
    background:transparent !important;
    color:#999;
    font-size:100%;
    font-family:inherit;
    line-height:normal;
    border-radius:0;
    width:25px;
    outline:none;
}
 body .chosen-container-multi .chosen-choices li.search-choice{
    position:relative;
    margin:3px 5px 3px 0;
    padding:3px 20px 3px 5px;
    border:1px solid #888888;
    max-width:100%;
    border-radius:3px;
    background-color:#eeeeee;
    background-size:100% 19px;
    background-repeat:repeat-x;
    background-clip:padding-box;
    color:#000000;
    line-height:13px;
    cursor:default;
    outline:none;
}
 body .chosen-container-multi .chosen-choices li.search-choice span{
    word-wrap:break-word;
}
 body .chosen-container-multi .chosen-choices li.search-choice .search-choice-close{
    position:absolute;
    top:4px;
    right:3px;
    display:block;
    width:12px;
    height:12px;
    /* background:url("images/chosen-sprite.png") -42px 1px no-repeat; */
    font-size:1px;
}
 body .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover{
    background-position:-42px -10px;
}
 body .chosen-container-multi .chosen-choices li.search-choice-disabled{
    padding-right:5px;
    border:1px solid #ccc;
    background-color:#e4e4e4;
    color:#666;
}
 body .chosen-container-multi .chosen-choices li.search-choice-focus{
    background:#d4d4d4;
}
 body .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close{
    background-position:-42px -10px;
}
 body .chosen-container-multi .chosen-results{
    margin:0;
    padding:0;
}
 body .chosen-container-multi .chosen-drop .result-selected{
    display:list-item;
    color:#ccc;
    cursor:default;
}
 body .chosen-container-active .chosen-single{
    border:1px solid #222222;
}
 body .chosen-container-active.chosen-with-drop .chosen-single{
    border:1px solid #888888;
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
}
 body .chosen-container-active.chosen-with-drop .chosen-single div{
    border-left:none;
    background:transparent;
}
 body .chosen-container-active.chosen-with-drop .chosen-single div b{
    background-position:-18px 2px;
}
 body .chosen-container-active .chosen-choices{
    border:1px solid #222222;
}
 body .chosen-container-active .chosen-choices li.search-field input[type="text"]{
    color:#222222 !important;
}
 body .chosen-disabled{
    opacity:0.5 !important;
    cursor:default;
}
 body .chosen-disabled .chosen-single{
    cursor:default;
}
 body .chosen-disabled .chosen-choices .search-choice .search-choice-close{
    cursor:default;
}
 body .chosen-rtl{
    text-align:right;
}
 body .chosen-rtl .chosen-single{
    overflow:visible;
    padding:0 8px 0 0;
}
 body .chosen-rtl .chosen-single span{
    margin-right:0;
    margin-left:26px;
    direction:rtl;
}
 body .chosen-rtl .chosen-single-with-deselect span{
    margin-left:38px;
}
 body .chosen-rtl .chosen-single div{
    right:auto;
    left:3px;
}
 body .chosen-rtl .chosen-single abbr{
    right:auto;
    left:26px;
}
 body .chosen-rtl .chosen-choices li{
    float:right;
}
 body .chosen-rtl .chosen-choices li.search-field input[type="text"]{
    direction:rtl;
}
 body .chosen-rtl .chosen-choices li.search-choice{
    margin:3px 5px 3px 0;
    padding:3px 5px 3px 19px;
}
 body .chosen-rtl .chosen-choices li.search-choice .search-choice-close{
    right:auto;
    left:4px;
}
 body .chosen-rtl.chosen-container-single .chosen-results{
    margin:0 0 4px 4px;
    padding:0 4px 0 0;
}
 .chosen-rtl .chosen-results li.group-option{
    padding-right:15px;
    padding-left:0;
}
 body .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div{
    border-right:none;
}
 body .chosen-rtl .chosen-search input[type="text"]{
    padding:4px 5px 4px 20px;
    /* background:url("images/chosen-sprite.png") no-repeat -30px -20px; */
    direction:rtl;
}
 body .chosen-rtl.chosen-container-single .chosen-single div b{
    background-position:6px 2px;
}
 body .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b{
    background-position:-12px 2px;
}
 @media only screen and (-webkit-min-device-pixel-ratio:1.5),only screen and (min-resolution:144dpi),only screen and (min-resolution:1.5dppx) {
     body .chosen-rtl .chosen-search input[type="text"],body .chosen-container-single .chosen-single abbr,body .chosen-container-single .chosen-single div b,body .chosen-container-single .chosen-search input[type="text"],body .chosen-container-multi .chosen-choices .search-choice .search-choice-close,body .chosen-container .chosen-results-scroll-down span,body .chosen-container .chosen-results-scroll-up span{
        /* background-image:url("chosen-sprite@2x.png") !important; */
        background-size:52px 37px !important;
        background-repeat:no-repeat !important;
    }
}
 body .chosen-container ul li.indent-1{
    padding-left:20px;
}
 body .chosen-container ul li.indent-2{
    padding-left:40px;
}
 body .chosen-container ul li.indent-3{
    padding-left:60px;
}
 body .chosen-container ul li.indent-4{
    padding-left:80px;
}
 body .chosen-container ul li.indent-5{
    padding-left:100px;
}
 body .chosen-container ul li.indent-6{
    padding-left:120px;
}
 .formbuilder input[type="range"]{
    border:none !important;
    cursor:pointer;
    height:25px;
    outline:none;
    vertical-align:middle;
}
 .formbuilder .input .checkbox-input,.formbuilder .input .radiobox-input{
    display:none;
}
 .formbuilder .checkbox-column label,.formbuilder .radiobox-column label{
    display:inline-block;
}
 .formbuilder .checkbox-columns .label,.formbuilder .checkbox-columns .text,.formbuilder .radiobox-columns .label,.formbuilder .radiobox-columns .text{
    padding:3px 8px 3px 0px;
}
 .formbuilder .input .checkbox-input + label.checkbox-label,.formbuilder .input .radiobox-input + label.radiobox-label{
    border:#222222 solid 1px;
    border-radius:4px;
    box-sizing:border-box;
    cursor:pointer;
    height:22px;
    line-height:20px;
    margin:0px 0px 0px 0px !important;
    padding:1px 0px 0px 0px !important;
    text-align:center;
    width:22px;
}
 .formbuilder .input .radiobox-input + label.radiobox-label{
    border-radius:50% !important;
}
 .formbuilder .input .checkbox-input:checked + label.checkbox-label::after,.formbuilder .input .radiobox-input:checked + label.radiobox-label::after{
    content:'\f00c';
    display:block;
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    font-style:normal;
    font-variant:normal;
    text-align:center;
    text-rendering:auto;
    width:100%;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .formfield-checkboxes-vertical.checkboxes-vertical-scroll,.formbuilder .formfield-radioboxes-vertical.radioboxes-vertical-scroll{
    border:1px solid #CCCCCC;
    max-height:172px;
    overflow-y:auto;
    padding:5px;
}
 .formbuilder .formfield-checkbox .checkbox-columns,.formbuilder .formfield-checkboxes-vertical .checkbox-columns,.formbuilder .formfield-radioboxes-vertical .radiobox-columns{
    display:grid;
    grid-template-columns:30px auto;
}
 .formbuilder .formfield-checkboxes-horizontal .checkbox-columns,.formbuilder .formfield-radioboxes-horizontal .radiobox-columns{
    display:grid;
    grid-template-columns:30px auto 10px 30px auto 10px 30px auto 10px 30px auto 10px 30px auto 10px 30px auto 20px;
}
 .formbuilder .checkbox-row.indent-1,.formbuilder .checkbox-row.layer-1{
    margin-left:28px;
}
 .formbuilder .checkbox-row.indent-2,.formbuilder .checkbox-row.layer-2{
    margin-left:56px;
}
 .formbuilder .checkbox-row.indent-3,.formbuilder .checkbox-row.layer-3{
    margin-left:84px;
}
 .formbuilder .checkbox-row.indent-4,.formbuilder .checkbox-row.layer-4{
    margin-left:112px;
}
 .formbuilder .input-checkbox-custom,.formbuilder .input-radio-custom{
    display:grid;
    grid-template-columns:30px auto;
}
 .select-sortable{
    border-radius:6px;
    display:grid;
    grid-template-columns:100%;
    grid-gap:10px;
}
 @media (min-width:820px) {
     .select-sortable{
        grid-template-columns:calc((100% - 10px) / 2) calc((100% - 10px) / 2);
    }
}
 .select-sortable .left,.select-sortable .right{
    border:#AAAAAA solid 1px;
    height:250px;
    overflow-x:hidden;
    overflow-y:scroll;
    padding:5px 5px 30px 5px;
}
 .select-sortable .left::after,.select-sortable .right::after{
    clear:both;
    content:'';
    display:block;
}
 .select-sortable ul{
    box-sizing:border-box;
    list-style-type:none;
    margin:0;
    min-height:100%;
    padding:0;
    width:100%;
    z-index:10;
}
 .select-sortable li,.select-sortable li{
    background-color:#FFFFFF;
    border:#AAAAAA solid 1px;
    box-sizing:border-box;
    cursor:grab;
    font-size:inherit;
    height:30px;
    line-height:28px;
    margin:0 0px 5px 0px;
    padding:0px 40px 0px 10px;
    position:relative;
    width:100%;
    z-index:20;
}
 .select-sortable li.ui-sortable-placeholder{
    border:#AAAAAA dashed 1px;
    visibility:visible !important;
}
 .select-sortable li:hover{
    background-color:#E0E0E0;
}
 .select-sortable li .select-sortable-button{
    border:none;
    display:block;
    height:28px;
    position:absolute;
    top:0px;
    right:0px;
    width:28px;
}
 .select-sortable li .select-sortable-button::before{
    color:inherit;
    content:'\f001';
    cursor:pointer;
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:14px;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    height:28px;
    left:0px;
    line-height:28px;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:0px;
    width:28px;
}
 .select-sortable .left li .select-sortable-button::before{
    content:'\f00d';
}
 .select-sortable .right li .select-sortable-button::before{
    content:'\f067';
}
 .formbuilder input.upload-file-input{
    display:none;
    outline:none;
    overflow:hidden;
}
 .formbuilder .upload-file-selected .checkbox-column .input{
    padding-top:4px;
    max-height:30px;
}
 .formbuilder .upload-file-selected .checkbox-column label.label,.formbuilder .upload-file-selected .checkbox-column label.text{
    display:block;
    line-height:30px;
    padding:0px;
}
 .formbuilder .upload-file-selected[data-upload-message]::after{
    content:attr(data-upload-message);
    display:inline;
    font-style:italic;
    padding-left:20px;
}
 .formbuilder .upload-file-button{
    background-color:#DDDDDD;
    border:none;
    border-radius:4px;
    font-family:inherit;
    font-size:inherit;
    line-height:30px;
    margin:0px 10px 0px 0px !important;
    padding:0px 8px 0px 8px;
    text-align:center;
}
 .formbuilder .upload-file-button::after{
    content:'\f07c';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    font-style:normal;
    font-variant:normal;
    line-height:1;
    text-rendering:auto;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .upload-file-area{
    border:#888888 dashed 1px;
    border-radius:4px;
    color:#888888;
    height:202px;
    width:100%;
}
 .formbuilder .upload-file-area:hover,.formbuilder .upload-file-area.autoupload-is-dragover{
    border-color:#222222;
    color:#222222;
}
 .formbuilder .upload-file-area::before{
    content:'\f07c';
    display:block;
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    font-size:60px;
    font-style:normal;
    font-variant:normal;
    height:115px;
    line-height:60px;
    padding:55px 0px 0px 0px;
    text-align:center;
    text-rendering:auto;
    width:100%;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
}
 .formbuilder .upload-file-area::after{
    content:attr(data-autoupload-status);
    display:block;
    padding:2px;
    text-align:center;
    width:100%;
}
 .formbuilder.autoupload-is-uploading .upload-file-button,.formbuilder.autoupload-is-uploading .upload-file-area{
    cursor:wait;
}
 @font-face{
    font-family:"Font Awesome 5 Brands";
    font-style:normal;
    font-weight:400;
    font-display:block;
    src:url(https://grid.com/data/fonts/fa-brands-400.eot);
    src:url(https://grid.com/data/fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),url(../Font/fa-brands-400.woff2) format("woff2"),url(../Font/fa-brands-400.woff) format("woff"),url(../Font/fa-brands-400.ttf) format("truetype"),url(https://grid.com/data/fonts/fa-brands-400.svg#fontawesome) format("svg")
}
 @font-face{
    font-family:"Font Awesome 5 Free";
    font-style:normal;
    font-weight:900;
    font-display:block;
    src:url(https://grid.com/data/fonts/fa-solid-900.eot);
    src:url(https://grid.com/data/fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(../Font/fa-solid-900.woff2) format("woff2"),url(../Font/fa-solid-900.woff) format("woff"),url(../Font/fa-solid-900.ttf) format("truetype"),url(https://grid.com/data/fonts/fa-solid-900.svg#fontawesome) format("svg")
}
 .web-element [fab],.web-element [fas],.web-element [fab20],.web-element [fab30],.web-element [fab40],.web-element [fas20],.web-element [fas30],.web-element [fas40]{
    position:relative;
}
 .web-element [fab20],.web-element [fas20]{
    padding-left:24px;
}
 .web-element [fab30],.web-element [fas30]{
    padding-left:34px;
}
 .web-element [fab40],.web-element [fas40]{
    padding-left:44px;
}
 .web-element [fab]::before,.web-element [fab20]::before,.web-element [fab30]::before,.web-element [fab40]::before,.web-element [fas]::before,.web-element [fas20]::before,.web-element [fas30]::before,.web-element [fas40]::before{
    color:inherit;
    content:attr(fas);
    cursor:inherit;
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:inherit;
    font-style:normal;
    font-variant:normal;
    font-weight:normal;
    height:20px;
    left:0px;
    line-height:20px;
    pointer-events:none;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:0px;
    width:20px;
}
 .web-element [fab]::before,.web-element [fab20]::before,.web-element [fab30]::before,.web-element [fab40]::before{
    font-family:'Font Awesome 5 Brands';
}
 .web-element [fab20]::before,.web-element [fas20]::before{
    content:attr(fas20);
    font-size:12px;
    height:20px;
    line-height:20px;
    width:20px;
}
 .web-element [fab30]::before,.web-element [fas30]::before{
    content:attr(fas30);
    font-size:15px;
    height:30px;
    line-height:30px;
    width:30px;
}
 .web-element [fab40]::before,.web-element [fas40]::before{
    content:attr(fas40);
    font-size:18px;
    height:40px;
    line-height:40px;
    width:40px;
}
 .web-element [fab]::before{
    content:attr(fab);
}
 .web-element [fab20]::before{
    content:attr(fab20);
}
 .web-element [fab30]::before{
    content:attr(fab30);
}
 .web-element [fab40]::before{
    content:attr(fab40);
}
 body .web-element.element-contact-form{
    font-family:verdana;
    margin:auto;
    padding:10px;
    width:300px;
}
 .element-contact-form .formfield-label{
    margin-bottom:10px;
}
 .element-contact-form .formfield-input{
    margin-bottom:10px;
}
 .element-contact-form .textfield,.element-contact-form .textarea,.element-contact-form .select{
    width:100%;
}
 .element-contact-form .formfield-textfield-name > .input{
    display:grid;
    grid-template-columns:2fr 1fr 2fr;
    column-gap:10px;
}
 .element-contact-form .formfield-textfield-address > .input{
    display:grid;
    grid-template-columns:3fr 1fr 1fr;
    column-gap:10px;
}
 .element-contact-form .toggle-address-street-label .input-labels{
    display:none;
}
 .element-contact-form label{
    display:inline;
    margin:0px;
    font-weight:normal;
}
 .element-contact-form .checkbox-column,.element-contact-form .checkbox-column > *{
    display:inline;
}
 .element-contact-form .checkbox-label{
    margin-left:5px;
}
 .element-contact-form .formbuilder-captcha{
    margin-bottom:10px;
}
 .element-contact-form .contact-form-button{
    padding:10px;
}
 body div.content-image{
    background-color:#F0F0F0;
    background-image:none;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    min-height:20px;
    min-width:20px;
    position:relative;
    width:100%;
}
 body div.content-image > a{
    background:transparent;
    cursor:pointer;
    display:block;
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%;
}
 body img.content-image{
    border:none;
    outline:none;
}
 @media (min-width:400px) {
     body div.content-image{
        background-image:var(--background-image);
    }
     body div.content-image img.content-image{
        visibility:hidden;
    }
}
 body .web-element.element-scrollbox{
    align-items:center;
    display:grid;
    grid-gap:10px;
    grid-template-areas:"scrollboxNav" "scrollboxContent";
    padding:10px;
    position:relative;
}
 .element-scrollbox .scrollbox-nav{
    align-items:center;
    display:grid;
    grid-area:scrollboxNav;
    grid-gap:10px;
    grid-template-areas:"scrollboxPrev scrollboxIndex scrollboxNext";
}
 .element-scrollbox .scrollbox-prev,.element-scrollbox .scrollbox-next{
    border-radius:50%;
    cursor:pointer;
    grid-area:scrollboxPrev;
    height:40px;
    overflow:hidden;
    padding-left:0;
    width:40px;
}
 .element-scrollbox .scrollbox-next{
    grid-area:scrollboxNext;
}
 .element-scrollbox .scrollbox-index{
    align-items:center;
    display:flex;
    grid-area:scrollboxIndex;
    overflow:hidden;
    padding:0;
    position:relative;
}
 .element-scrollbox .scrollbox-title{
    text-align:center;
}
 .element-scrollbox .scrollbox-nav .scrollbox-title{
    flex:1 0 100%;
    list-style:none;
    position:relative;
    transition:opacity 1s ease-out;
}
 .element-scrollbox .scrollbox-content{
    grid-area:scrollboxContent;
    height:300px;
    overflow-y:auto;
    padding-right:8px;
    position:relative;
}
 .element-scrollbox .scrollbox-content .scrollbox-title{
    padding:0 40px;
}
 .element-scrollbox .scrollbox-content .scrollbox-title:first-child{
    height:0;
    overflow:hidden;
}
 .element-scrollbox .scrollbox-content .scrollbox-title:first-child .scrollbox-bookmark{
    position:absolute;
    top:0;
}
 .element-scrollbox .scrollbox-content .scrollbox-description{
    text-align:center;
    margin-bottom:30px;
}
 .element-scrollbox .scrollbox-content .scrollbox-description:last-child{
    margin-bottom:0;
    min-height:101%;
}
 @media (min-width:920px) {
     .element-scrollbox{
        display:block;
        min-height:500px;
        padding:30px;
    }
     .element-scrollbox .scrollbox-nav{
        display:inline-block;
        vertical-align:top;
        width:calc(50% - 15px);
    }
     .element-scrollbox .scrollbox-content{
        left:calc(50% + 15px);
        right:0;
    }
     .element-scrollbox .scrollbox-nav{
        text-align:right;
        margin-right:30px;
    }
     .element-scrollbox .scrollbox-prev,.element-scrollbox .scrollbox-next{
        display:none;
    }
     .element-scrollbox .scrollbox-index{
        display:inline-block;
        margin:30px 0 132px;
        width:auto;
    }
     .element-scrollbox .scrollbox-nav .scrollbox-title{
        cursor:pointer;
        margin:30px 0 30px 50px;
        opacity:0.5;
        text-align:left;
        width:auto;
    }
     .element-scrollbox .scrollbox-nav .scrollbox-title.active{
        margin-left:0;
        opacity:1;
        padding-left:50px;
    }
     .element-scrollbox .scrollbox-nav .scrollbox-title.active::before{
        border-radius:50%;
        content:'';
        cursor:inherit;
        display:block;
        font-family:'Font Awesome 5 Free';
        font-size:18px;
        font-style:normal;
        font-variant:normal;
        font-weight:900;
        height:40px;
        left:0px;
        line-height:40px;
        pointer-events:none;
        position:absolute;
        text-align:center;
        text-rendering:auto;
        top:50%;
        transform:translateY(-50%);
        width:40px;
    }
     .element-scrollbox .scrollbox-content{
        bottom:30px;
        height:unset;
        position:absolute;
        top:30px;
    }
     .element-scrollbox .scrollbox-content .scrollbox-title{
        height:unset;
        margin-bottom:10px;
        overflow:unset;
        padding:unset;
        text-align:left;
    }
     .element-scrollbox .scrollbox-content .scrollbox-title:first-child{
        height:unset;
        overflow:unset;
    }
     .element-scrollbox .scrollbox-content .scrollbox-title .scrollbox-bookmark{
        position:absolute;
        top:0;
    }
     .element-scrollbox .scrollbox-content .scrollbox-description{
        text-align:left;
    }
}
 :root{
    --color-theme:#0DBC81;
    --color-theme-80:#37C394;
    --color-theme-60:#62CBA7;
    --color-theme-50:#11a574;
    --color-theme-40:#175D45;
    --color-theme2:#236B3B;
    --color-theme2-80:#275637;
    --color-theme2-60:#1C4429;
    --color-theme2-40:#17301F;
    --color-text-primary:#FFFFFF;
    --color-text-secondary:#A0A0A0;
    --color-gray:#B5B5B5;
    --color-gray1:#7c7c7c;
    --color-gray-dark:#737373;
    --color-gray-dark1:#363636;
    --color-gray-light:#F0F0F0;
    --color-white:#FFFFFF;
    --color-dark-background:#1D1D1F;
    --color-icon-background:#3D3D3D;
    --color-icon:#FFFFFF;
    --color-warning:#DEB31A;
}
 @font-face{
    font-display:swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family:'Source Sans Pro';
    font-style:normal;
    font-weight:600;
    src:url('../Font/source-sans-pro-v21-latin-600.eot');
    /* IE9 Compat Modes */
     src:url('../Font/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
     url('../Font/source-sans-pro-v21-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
     url('../Font/source-sans-pro-v21-latin-600.woff') format('woff'),
    /* Modern Browsers */
     url('../Font/source-sans-pro-v21-latin-600.ttf') format('truetype'),
    /* Safari,Android,iOS */
     url('https://grid.com/data/fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}
 @font-face{
    font-display:swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family:'Source Sans Pro';
    font-style:normal;
    font-weight:700;
    src:url('../Font/source-sans-pro-v21-latin-700.eot');
    /* IE9 Compat Modes */
     src:url('../Font/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
     url('../Font/source-sans-pro-v21-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
     url('../Font/source-sans-pro-v21-latin-700.woff2') format('woff'),
    /* Modern Browsers */
     url('../Font/source-sans-pro-v21-latin-700.ttf') format('truetype'),
    /* Safari,Android,iOS */
     url('https://grid.com/data/fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}
 @font-face{
    font-display:swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family:'Source Sans Pro';
    font-style:normal;
    font-weight:900;
    src:url('../Font/source-sans-pro-v21-latin-900.eot');
    /* IE9 Compat Modes */
     src:url('../Font/source-sans-pro-v21-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
     url('../Font//source-sans-pro-v21-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
     url('../Font//source-sans-pro-v21-latin-900.woff2') format('woff'),
    /* Modern Browsers */
     url('../Font/source-sans-pro-v21-latin-900.ttf') format('truetype'),
    /* Safari,Android,iOS */
     url('https://grid.com/data/fonts/source-sans-pro-v21-latin-900.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}
 @font-face{
    font-display:swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family:'Inter';
    font-style:normal;
    font-weight:500;
    src:url('../Font/inter-v12-latin-500.eot');
    /* IE9 Compat Modes */
     src:url('../Font/inter-v12-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
     url('../Font/inter-v12-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
     url('../Font/inter-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */
     url('../Font/inter-v12-latin-500.ttf') format('truetype'),
    /* Safari,Android,iOS */
     url('https://grid.com/data/fonts/inter-v12-latin-500.svg#Inter') format('svg');
    /* Legacy iOS */
}
 @font-face{
    font-display:swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family:'Inter';
    font-style:normal;
    font-weight:700;
    src:url('../Font/inter-v12-latin-700.eot');
    /* IE9 Compat Modes */
     src:url('../Font/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
     url('../Font/inter-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
     url('../Font/inter-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */
     url('../Font/inter-v12-latin-700.ttf') format('truetype'),
    /* Safari,Android,iOS */
     url('https://grid.com/data/fonts/inter-v12-latin-700.svg#Inter') format('svg');
    /* Legacy iOS */
}
 body .web-layout{
    background-color:var(--color-dark-background);
    color:#FFFFFF;
    font-family:'Source Sans Pro',Arial,sans-serif;
    font-size:14px;
    font-weight:400;
    min-height:100vh;
    line-height:normal;
}
 .web-element .background-image-300{
    background-image:var(--background-image-300);
}
 .web-element.blog-content-image-container.image-container-300{
    max-width:var(--max-width-300);
    max-height:var(--max-height-300);
}
 .web-element .blog-content-image{
    background-size:contain;
}
 @media (min-width:620px) {
     .web-element .background-image-600{
        background-image:var(--background-image-600);
    }
     .web-element.blog-content-image-container.image-container-600{
        max-width:var(--max-width-600);
        max-height:var(--max-height-600);
    }
}
 @media (min-width:920px) {
     .web-element .background-image-900{
        background-image:var(--background-image-900);
    }
     .web-element.blog-content-image-container.image-container-900{
        max-width:var(--max-width-900);
        max-height:var(--max-height-900);
    }
}
 @media (min-width:1220px) {
     .web-element .background-image-1200{
        background-image:var(--background-image-1200);
    }
     .web-element.blog-content-image-container.image-container-1200{
        max-width:var(--max-width-1200);
        max-height:var(--max-height-1200);
    }
}
 body .web-blog-header{
    background-position:50%;
    background-size:cover;
    overflow:hidden;
    max-height:250px;
    min-height:30vh;
    position:relative;
    z-index:-1;
}
 body .web-blog-header img{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    width:100%;
}
 @media(min-width:767px) {
     body .web-blog-header{
        max-height:410px;
        min-height:48vh;
    }
}
 .owl-carousel,.owl-carousel .owl-item{
    -webkit-tap-highlight-color:transparent;
    position:relative
}
 .owl-carousel{
    display:none;
    width:100%;
    z-index:1
}
 .owl-carousel .owl-stage{
    position:relative;
    -ms-touch-action:pan-Y;
    touch-action:manipulation;
    -moz-backface-visibility:hidden
}
 .owl-carousel .owl-stage:after{
    content:".";
    display:block;
    clear:both;
    visibility:hidden;
    line-height:0;
    height:0
}
 .owl-carousel .owl-stage-outer{
    position:relative;
    overflow:hidden;
    -webkit-transform:translate3d(0,0,0)
}
 .owl-carousel .owl-item,.owl-carousel .owl-wrapper{
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0)
}
 .owl-carousel .owl-item{
    min-height:1px;
    float:left;
    -webkit-backface-visibility:hidden;
    -webkit-touch-callout:none
}
 .owl-carousel .owl-item img{
    display:block;
    width:100%
}
 .owl-carousel .owl-dots.disabled,.owl-carousel .owl-nav.disabled{
    display:none
}
 .no-js .owl-carousel,.owl-carousel.owl-loaded{
    display:block
}
 .owl-carousel .owl-dot,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-nav .owl-prev{
    cursor:pointer;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
 .owl-carousel .owl-nav .owl-next,.owl-carousel .owl-nav .owl-prev,.owl-carousel .owl-dot{
    background:0 0;
    color:inherit;
    border:none;
    padding:0!important;
    font:inherit
}
 .owl-carousel.owl-loading{
    opacity:0;
    display:block
}
 .owl-carousel.owl-hidden{
    opacity:0
}
 .owl-carousel.owl-refresh .owl-item{
    visibility:hidden
}
 .owl-carousel.owl-drag .owl-item{
    -ms-touch-action:pan-y;
    touch-action:pan-y;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
 .owl-carousel.owl-grab{
    cursor:move;
    cursor:grab
}
 .owl-carousel.owl-rtl{
    direction:rtl
}
 .owl-carousel.owl-rtl .owl-item{
    float:right
}
 .owl-carousel .animated{
    animation-duration:1s;
    animation-fill-mode:both
}
 .owl-carousel .owl-animated-in{
    z-index:0
}
 .owl-carousel .owl-animated-out{
    z-index:1
}
 .owl-carousel .fadeOut{
    animation-name:fadeOut
}
 @keyframes fadeOut{
    0%{
        opacity:1
    }
     100%{
        opacity:0
    }
}
 .owl-height{
    transition:height .5s ease-in-out
}
 .owl-carousel .owl-item .owl-lazy{
    opacity:0;
    transition:opacity .4s ease
}
 .owl-carousel .owl-item .owl-lazy:not([src]),.owl-carousel .owl-item .owl-lazy[src^=""]{
    max-height:0
}
 .owl-carousel .owl-item img.owl-lazy{
    transform-style:preserve-3d
}
 .owl-carousel .owl-video-wrapper{
    position:relative;
    height:100%;
    background:#000
}
 .owl-carousel .owl-video-play-icon{
    position:absolute;
    height:80px;
    width:80px;
    left:50%;
    top:50%;
    margin-left:-40px;
    margin-top:-40px;
    /* background:url(owl.video.play.png) no-repeat; */
    cursor:pointer;
    z-index:1;
    -webkit-backface-visibility:hidden;
    transition:transform .1s ease
}
 .owl-carousel .owl-video-play-icon:hover{
    -ms-transform:scale(1.3,1.3);
    transform:scale(1.3,1.3)
}
 .owl-carousel .owl-video-playing .owl-video-play-icon,.owl-carousel .owl-video-playing .owl-video-tn{
    display:none
}
 .owl-carousel .owl-video-tn{
    opacity:0;
    height:100%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    transition:opacity .4s ease
}
 .owl-carousel .owl-video-frame{
    position:relative;
    z-index:1;
    height:100%;
    width:100%
}
 .owl-theme .owl-dots,.owl-theme .owl-nav{
    text-align:center;
    -webkit-tap-highlight-color:transparent
}
 .owl-theme .owl-nav{
    margin-top:10px
}
 .owl-theme .owl-nav [class*=owl-]{
    color:#FFF;
    font-size:14px;
    margin:5px;
    padding:4px 7px;
    background:#D6D6D6;
    display:inline-block;
    cursor:pointer;
}
 .owl-theme .owl-nav [class*=owl-]:hover{
    background:#869791;
    color:#FFF;
    text-decoration:none
}
 .owl-theme .owl-nav .disabled{
    opacity:.5;
    cursor:default
}
 .owl-theme .owl-nav.disabled+.owl-dots{
    margin-top:10px
}
 .owl-theme .owl-dots .owl-dot{
    display:inline-block;
    zoom:1
}
 .owl-theme .owl-dots .owl-dot span{
    width:10px;
    height:10px;
    margin:5px 7px;
    background:#D6D6D6;
    display:block;
    -webkit-backface-visibility:visible;
    transition:opacity .2s ease;
}
 .owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{
    background:#869791
}
 .web-element .owl-carousel,.web-element .owl-dots,.web-element .owl-nav{
    overflow:visible;
}
 .web-element .owl-carousel .owl-stage-outer{
    overflow:hidden !important;
    width:100%;
}
 .web-element .owl-carousel.owl-dots .owl-dots{
    /* bottom:10px;
    */
     display:block;
    height:20px;
    left:0px;
    position:absolute;
    text-align:center;
    width:100%;
}
 .web-element .owl-carousel.owl-dots .owl-dots > button{
    background-color:var(--color-gray-dark);
    border-radius:50%;
    display:inline-block;
    height:20px;
    width:20px;
}
 .web-element .owl-carousel.owl-dots .owl-dots > button.active{
    background-color:var(--color-theme);
}
 .web-element .owl-carousel.owl-dots .owl-dots > button + button{
    margin-left:5px;
}
 .web-element .owl-carousel.owl-nav .owl-nav{
    /* bottom:5px;
    */
     display:block;
    height:40px;
    left:0px;
    position:absolute;
    width:100%;
}
 .web-element .owl-carousel.owl-nav .owl-nav > button{
    background-color:var(--color-theme);
    border-radius:50%;
    color:#FFFFFF;
    height:40px;
    position:absolute;
    top:0px;
    width:40px;
}
 .web-element .owl-carousel.owl-nav .owl-nav > button::before{
    color:inherit;
    content:'\f053';
    cursor:pointer;
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:24px;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    height:40px;
    left:0px;
    line-height:40px;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:0px;
    width:40px;
}
 .web-element .owl-carousel.owl-nav .owl-nav > button.owl-prev{
    left:5px;
}
 .web-element .owl-carousel.owl-nav .owl-nav > button.owl-next{
    right:5px;
}
 .web-element .owl-carousel.owl-nav .owl-nav > button.owl-next::before{
    content:'\f054';
}
 @media (min-width:1220px) {
     .web-element .owl-carousel.owl-nav .owl-nav{
        bottom:100px;
    }
     .web-element .owl-carousel.owl-nav .owl-nav > button{
        background-color:var(--color-theme);
        color:inherit;
    }
     .web-element .owl-carousel.owl-nav .owl-nav > button.owl-prev{
        color:#FFFFFF;
        left:-50px;
    }
     .web-element .owl-carousel.owl-nav .owl-nav > button.owl-next{
        color:#FFFFFF;
        right:-50px;
    }
}
 .dark-mode .chatbox{
    color:var(--color-white);
}
 .dark-mode .chatbox .chatbox-body,.dark-mode .chatbox .chatbox-footer{
    background-color:var(--color-black);
}
 .dark-mode .chatbox .chatbox-form-name-label,.dark-mode .chatbox .chatbox-form-phone-label,.dark-mode .chatbox .chatbox-form-email-label,.dark-mode .chatbox .chatbox-expired-message,.dark-mode .chatbox .chatbox-input textarea::placeholder{
    color:var(--color-gray);
}
 .dark-mode .chatbox .chatbox-input textarea,.dark-mode .chatbox .chatbox-form-phone-input,.dark-mode .chatbox .chatbox-form-email-input,.dark-mode .chatbox .chatbox-form-name-input,.dark-mode .chatbox .chatbox-form-description{
    color:var(--color-white);
}
 .dark-mode .chatbox ::-webkit-scrollbar-thumb,.dark-mode .chatbox .message-assistant .message-text,.dark-mode .chatbox .chatbox-feedback-message,.dark-mode .chatbox .chatbox-input textarea,.dark-mode .chatbox .chatbox-feedback-yes,.dark-mode .chatbox .chatbox-feedback-no{
    background-color:var(--color-gray-dark1);
}
 .dark-mode .chatbox .message-assistant .message-text,.dark-mode .chatbox .chatbox-feedback-message,.dark-mode .chatbox .chatbox-input textarea,.dark-mode .chatbox .chatbox-feedback-yes,.dark-mode .chatbox .chatbox-feedback-no{
    color:var(--color-white);
}
 .dark-mode .chatbox .chatbox-form,.dark-mode .chatbox .chatbox-footer{
    border-top-color:var(--color-gray-dark);
}
 .dark-mode .chatbox .chatbox-form-name-input,.dark-mode .chatbox .chatbox-form-phone-input,.dark-mode .chatbox .chatbox-form-email-input,.dark-mode .chatbox .chatbox-form-name-container,.dark-mode .chatbox .chatbox-form-phone-container,.dark-mode .chatbox .chatbox-form-email-container,.dark-mode .chatbox .chatbox-form{
    background-color:var(--color-gray-dark1);
}
 .dark-mode .chatbox .chatbox-form-fields input{
    background-color:var(--color-gray-dark2);
}
 .dark-mode .chatbox .chatbox-btn-send.unclickable,.dark-mode .chatbox .chatbox-form-btn-send.unclickable,.dark-mode .chatbox .chatbox-btn-send-feedback.unclickable{
    background-color:var(--color-gray);
}
 .dark-mode .chatbox .chatbox-btn-send.clickable,.dark-mode .chatbox .chatbox-form-btn-send.clickable,.dark-mode .chatbox .chatbox-btn-send-feedback.clickable{
    background-color:var(--color-green2);
}
 .dark-mode .chatbox .message-user .message-timestamp,.dark-mode .chatbox .message-assistant .message-timestamp,.dark-mode .chatbox .message-timestamp-feedback{
    color:var(--color-gray-dark);
}
 .dark-mode .chatbox .chatbox-form-btn-send,.dark-mode .chatbox .chatbox-btn-send-feedback,.dark-mode .chatbox .chatbox-feedback-yes,.dark-mode .chatbox .chatbox-feedback-no,.dark-mode .chatbox .chatbox-form-name-input,.dark-mode .chatbox .chatbox-form-phone-input,.dark-mode .chatbox .chatbox-form-email-input{
    color:var(--color-white);
}
 .dark-mode .chatbox .chatbox-btn-send:hover,.dark-mode .chatbox .chatbox-form-btn-send:hover,.dark-mode .chatbox .chatbox-btn-send-feedback:hover{
    background-color:var(--color-green2-dark);
}
 .dark-mode .chatbox .message-user .message-text,.dark-mode .chatbox .chatbox-user-response,.dark-mode .chatbox .chatbox-feedback-yes:hover,.dark-mode .chatbox .chatbox-feedback-no:hover{
    background-color:var(--color-green2);
}
 .dark-mode .chatbox .chatbox-expired-message{
    color:var(--color-gray);
}
 .dark-mode .chatbox .chatbox-form-cancel,.dark-mode .chatbox .chatbox-header-logo,.dark-mode .chatbox .chatbox-btn-send{
    color:var(--color-white);
}
 .dark-mode .chatbox .chatbox-form-cancel:before{
    background-repeat:no-repeat;
    background-size:contain;
    background-image:url('https://grid.com/data/template/cross-circle.png');
    content:'';
    cursor:pointer;
    height:30px;
    position:absolute;
    right:10px;
    top:10px;
    width:30px;
}
 body .chatbox-container{
    /* font-family:Source Sans Pro;
    */
     position:relative;
}
 .chatbox .chatbox-header{
    background-color:var(--color-theme);
}
 body .chatbox{
    color:var(--color-white);
}
 .chatbox .chatbox-body,.chatbox .chatbox-footer{
    background-color:var(--color-gray-light);
}
 .chatbox .chatbox-form-name-label,.chatbox .chatbox-form-phone-label,.chatbox .chatbox-form-email-label,.chatbox .chatbox-expired-message,.chatbox .chatbox-input textarea::placeholder{
    /* font-family:Source Sans Pro;
    */
     color:var(--color-gray);
}
 .chatbox .chatbox-input textarea,.chatbox .chatbox-form-phone-input,.chatbox .chatbox-form-email-input,.chatbox .chatbox-form-name-input{
    color:var(--color-gray);
}
 .chatbox .message-assistant .message-text,.chatbox .chatbox-feedback-message,.chatbox .chatbox-input textarea,.chatbox .chatbox-feedback-yes,.chatbox .chatbox-feedback-no{
    color:var(--color-gray-dark);
}
 .chatbox ::-webkit-scrollbar-thumb,.chatbox .message-assistant .message-text,.chatbox .chatbox-feedback-message,.chatbox .chatbox-input textarea,.chatbox .chatbox-feedback-yes,.chatbox .chatbox-feedback-no{
    /* font-family:Source Sans Pro;
    */
     background-color:var(--color-white);
}
 .chatbox .chatbox-form,.chatbox .chatbox-footer{
    border-top-color:var(--color-gray);
}
 .chatbox .chatbox-form-name-input,.chatbox .chatbox-form-phone-input,.chatbox .chatbox-form-email-input,.chatbox .chatbox-form-name-container,.chatbox .chatbox-form-phone-container,.chatbox .chatbox-form-email-container{
    background-color:var(--color-white);
}
 .chatbox .chatbox-form{
    background-color:var(--color-gray-light);
}
 .chatbox .chatbox-form-name-container,.chatbox .chatbox-form-phone-container,.chatbox .chatbox-form-email-container{
    color:var(--color-gray)
}
 .chatbox .chatbox-form-fields input{
    background-color:var(--color-white);
}
 .chatbox .chatbox-btn-send.unclickable,.chatbox .chatbox-form-btn-send.unclickable,.chatbox .chatbox-btn-send-feedback.unclickable{
    background-color:var(--color-gray);
}
 .chatbox .chatbox-btn-send.clickable,.chatbox .chatbox-form-btn-send.clickable,.chatbox .chatbox-btn-send-feedback.clickable{
    background-color:var(--color-theme);
}
 .chatbox .message-user .message-timestamp,.chatbox .message-assistant .message-timestamp,.chatbox .message-timestamp-feedback,.chatbox .chatbox-form-description{
    color:var(--color-gray-dark);
}
 .chatbox .chatbox-form-btn-send,.chatbox .chatbox-btn-send-feedback{
    font-family:Source Sans Pro;
    color:var(--color-white);
}
 .chatbox .chatbox-feedback-yes,.chatbox .chatbox-feedback-no,.chatbox .chatbox-form-name-input,.chatbox .chatbox-form-phone-input,.chatbox .chatbox-form-email-input{
    font-family:Source Sans Pro;
    color:var(--color-gray-dark);
}
 .chatbox .chatbox-feedback-yes:hover,.chatbox .chatbox-feedback-no:hover{
    color:var(--color-white);
}
 .chatbox .chatbox-btn-send:hover,.chatbox .chatbox-form-btn-send:hover,.chatbox .chatbox-btn-send-feedback:hover{
    background-color:var(--color-theme-80);
}
 .chatbox .message-user .message-text,.chatbox .chatbox-user-response,.chatbox .chatbox-feedback-yes:hover,.chatbox .chatbox-feedback-no:hover{
    background-color:var(--color-theme);
}
 .chatbox .chatbox-expired-message{
    color:var(--color-gray);
}
 .chatbox .chatbox-form-cancel,.chatbox .chatbox-header-logo,.chatbox .chatbox-btn-send{
    color:var(--color-white);
}
 body .chatbox{
    bottom:0px;
    border-radius:0px;
    border:none;
    box-shadow:0 2px 4px rgba(0,0,0,0.2);
    font-size:18px;
    font-weight:bold;
    height:0%;
    overflow:hidden;
    position:fixed;
    right:0px;
    transition:all 0.3s ease;
    width:100vw;
    z-index:100;
    display:grid;
    grid-template-rows:70px calc(100% - 150px) 80px;
    grid-template-areas:"header" "body" "footer";
}
 body .chatbox.active{
    height:55%;
    width:100%;
}
 .chatbox .chatbox-header{
    display:grid;
    padding:10px;
    font-size:24px;
    grid-area:header;
    grid-gap:10px;
    grid-template-columns:50px auto;
    grid-template-areas:"logo text";
}
 .chatbox .chatbox-header-logo{
    border:none;
    grid-area:logo;
    font-size:20px;
    font-weight:bold;
    margin-bottom:auto;
    margin-top:auto;
}
 .chatbox .chatbox-header-logo::before{
    content:'\f4b6';
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:30px;
    line-height:1;
    text-align:center;
}
 .chatbox .chatbox-header-text{
    grid-area:text;
    margin-bottom:auto;
    margin-top:auto;
}
 .chatbox .chatbox-header-title{
    font-size:16px;
    font-weight:bold;
}
 .chatbox .chatbox-header-intro{
    font-size:15px;
    font-weight:normal;
}
 .chatbox .close-chatbox::before{
    content:'';
    background-image:url('https://grid.com/data/template/cross-circle.png');
    background-size:contain;
    background-repeat:no-repeat;
    cursor:pointer;
    position:absolute;
    right:10px;
    top:10px;
    height:30px;
    width:30px;
}
 .chatbox .chatbox-body{
    font-size:20px;
    grid-area:body;
    padding:20px;
    overflow-y:auto;
}
 .chatbox ::-webkit-scrollbar{
    width:10px;
}
 .chatbox ::-webkit-scrollbar-thumb{
    height:100px;
}
 .chatbox .chatbox-footer,.chatbox .chatbox-footer.feedback{
    border-top-style:solid;
    border-top-width:1px;
    box-sizing:border-box;
    display:grid;
    grid-area:footer;
    grid-template-columns:calc(100% - 65px) 50px;
    grid-template-areas:"input send-button";
    grid-gap:15px;
    padding:15px;
    width:100%;
}
 .chatbox .chatbox-footer.feedback{
    bottom:0px;
    left:0px;
    position:absolute;
    top:0px;
    z-index:101;
}
 .chatbox .chatbox-form{
    position:absolute;
    bottom:0;
    box-sizing:border-box;
    left:0;
    border-top-style:solid;
    border-top-width:1px;
    display:grid;
    grid-template-rows:auto 60px 60px 60px 50px;
    grid-template-areas:"header" "name" "email" "phone" "btn-send";
    height:340px;
    max-height:100%;
    grid-gap:5px;
    overflow-y:auto;
    padding:15px;
    width:100%;
    z-index:101;
}
 .chatbox .chatbox-form-header{
    display:grid;
    margin-bottom:10px;
    grid-area:header;
    grid-template-columns:calc(100% - 40px) 40px;
    grid-template-areas:"description cancel";
}
 .chatbox .chatbox-form-description{
    font-size:14px;
    grid-area:description;
}
 .chatbox .chatbox-form-cancel{
    grid-area:cancel;
}
 .chatbox .chatbox-form-cancel:before{
    background-image:url('https://grid.com/data/template/cross-circle-gray.png');
    background-repeat:no-repeat;
    background-size:contain;
    content:'';
    cursor:pointer;
    height:30px;
    position:absolute;
    right:10px;
    top:10px;
    width:30px;
}
 .chatbox .chatbox-form-name-label,.chatbox .chatbox-form-phone-label,.chatbox .chatbox-form-email-label{
    display:none;
    font-size:12px;
    margin-bottom:unset;
}
 .chatbox .chatbox-form-name-input,.chatbox .chatbox-form-phone-input,.chatbox .chatbox-form-email-input{
    border:none;
    font-weight:normal;
    outline:none;
    padding:0;
    width:-webkit-fill-available;
}
 .chatbox .chatbox-form-name-container,.chatbox .chatbox-form-phone-container,.chatbox .chatbox-form-email-container{
    border:solid;
    border-radius:8px;
    border-width:2px;
    display:flex;
    font-size:14px;
    padding:7px 15px 7px 15px;
}
 .chatbox .chatbox-form-name-container{
    grid-area:name;
}
 .chatbox .chatbox-form-phone-container{
    grid-area:phone;
}
 .chatbox .chatbox-form-email-container{
    grid-area:email;
}
 .chatbox .chatbox-form-btn-send{
    border:none;
    border-radius:50%;
    box-sizing:border-box;
    cursor:pointer;
    grid-area:btn-send;
    height:42px;
    justify-self:end;
    padding:10px;
    margin-top:auto;
    width:42px;
}
 .chatbox .chatbox-btn-send-feedback{
    border:none;
    border-radius:50%;
    box-sizing:border-box;
    cursor:pointer;
    font-size:23px;
    height:50px;
    justify-self:end;
    padding:13px;
    margin-top:auto;
    width:50px;
}
 .chatbox .chatbox-form-btn-send::before,.chatbox .chatbox-btn-send-feedback::before{
    content:'\f1d8';
    display:block;
    font-family:'Font Awesome 5 Free';
    line-height:1;
    text-align:center;
}
 .chatbox .chatbox-footer .chatbox-input{
    height:30px;
    grid-area:input;
}
 .chatbox .chatbox-input textarea{
    border:none;
    border-radius:5px;
    box-sizing:border-box;
    font-size:15px;
    font-weight:normal;
    height:50px;
    padding:15px;
    resize:none;
    width:100%;
}
 .chatbox .chatbox-input textarea:focus,.chatbox .chatbox-input textarea:active{
    outline:none;
    border:none;
}
 .chatbox .chatbox-btn-send{
    align-items:center;
    border:none;
    border-radius:50%;
    cursor:pointer;
    display:inherit;
    grid-area:send-button;
    padding:10px;
}
 .chatbox .chatbox-btn-send.unclickable,.chatbox .chatbox-form-btn-send.unclickable,.chatbox .chatbox-btn-send-feedback.unclickable{
    pointer-events:none;
    cursor:not-allowed;
}
 .chatbox .chatbox-btn-send::before{
    content:'\f1d8';
    display:block;
    font-size:23px;
    font-family:'Font Awesome 5 Free';
    line-height:1;
    text-align:center;
}
 .chatbox .message-user .message-text,.chatbox .chatbox-user-response{
    border-radius:10px 10px 0 10px;
    clear:both;
    float:right;
    font-size:15px;
    font-weight:normal;
    margin-top:20px;
    max-width:80%;
    padding:20px;
    width:80%;
}
 .chatbox .message-assistant .message-text,.chatbox .chatbox-feedback-message{
    border-radius:10px 10px 10px 0;
    clear:both;
    float:left;
    font-size:15px;
    font-weight:normal;
    margin-top:20px;
    max-width:80%;
    padding:20px;
    width:80%;
}
 .chatbox .message-user .message-timestamp,.chatbox .message-assistant .message-timestamp,.chatbox .message-timestamp-feedback{
    clear:both;
    font-size:15px;
    margin-top:10px;
}
 .chatbox .message-assistant .message-timestamp,.chatbox .message-timestamp-feedback{
    font-weight:normal;
    float:left;
}
 .chatbox .message-user .message-timestamp{
    font-weight:normal;
    float:right;
}
 .chatbox .chatbox-feedback-buttons{
    clear:both;
    float:left;
    margin-top:10px;
    width:80%;
}
 .chatbox .chatbox-feedback-yes,.chatbox .chatbox-feedback-no{
    outline:none;
    border:none;
    border-radius:26px;
    font-size:15px;
    font-weight:normal;
    padding:15px;
    width:100px;
}
 .chatbox .chatbox-expired-message{
    clear:both;
    font-size:17px;
    text-align:center
}
 .chatbox .chatbox-body.chatbox-waiting::after{
    content:'';
    clear:both;
    width:45px;
    height:30px;
    float:left;
    background-image:url('https://grid.com/data/gifs/loading-dots.gif');
    background-size:45px 40px;
    background-repeat:no-repeat;
}
 .chatbox .chatbox-feedback-rating{
    font-size:0;
}
 .chatbox .chatbox-feedback-rating-star{
    background-image:url('https://grid.com/data/images-not-gitignore/star-unfilled.png');
    background-repeat:no-repeat;
    background-size:contain;
    cursor:pointer;
    display:inline-block;
    font-size:10px;
    line-height:1;
    height:30px;
    width:30px;
}
 .chatbox .chatbox-feedback-rating-star.filled{
    background-image:url('https://grid.com/data/images-not-gitignore/star-filled.png');
}
 @media (min-width:620px) {
     body .chatbox-container .chatbox{
        border-top-left-radius:10px;
        border-top-right-radius:10px;
        bottom:15px;
        left:initial;
        right:10px;
        width:460px;
    }
}
 #element-id-footer-ads a,#element-id-header-ads a{
    color:var(--base-color-neutral--neutral-darker);
    background-color:rgba(13,188,129,0);
    border:1px #000;
    text-decoration:none;
}
 #element-id-footer-ads a:hover,#element-id-header-ads a:hover{
    color:var(--base-color-neutral--neutral-darkest);
}
 body .element-header.header-energy{
    background-color:var(--color-black);
}
 .element-header.header-energy .element-header-wrapper{
    min-height:80px;
}
 .element-header.header-energy .element-header-logo a{
    /* background-image:url('./data/template/grid-energy-logo.webp'); */
    height:15px;
    width:150px;
}
 body .element-footer.footer-energy{
    border-top:1px solid var(--color-black-dark);
}
 .element-footer.footer-energy a{
    text-decoration:none;
}
 .element-footer.footer-energy .element-footer-wrapper{
    margin-top:48px;
    padding-top:68px;
}
 .element-footer.footer-energy .element-footer-top{
    border-bottom:1px solid var(--color-black-dark);
    min-height:92px;
}
 .element-footer.footer-energy .element-footer-logo a{
    /* background-image:url('./data/template/grid-energy-logo.webp'); */
    height:24px;
    width:240px;
}
 .element-footer.footer-energy .element-footer-bottom{
    align-items:flex-start;
    text-align:left;
}
 .element-footer.footer-energy .element-footer-reserved{
    color:var(--color-white);
}
 .element-footer.footer-energy .element-footer-legal-links-item{
    font-size:14px;
}
 .element-cookie-banner.cookie-banner-energy .element-cookie-banner-wrapper{
    background-color:var(--color-black);
}
 @media (min-width:920px) {
     .element-footer.footer-energy .element-footer-wrapper{
        padding-top:52px;
        padding-bottom:80px;
    }
     .element-footer.footer-energy .element-footer-top{
        min-height:136px;
    }
}
 body .web-element.element-contact-form.element-ads-form{
    font-size:14px;
    width:100%;
}
 body .element-ads-form .formbuilder-fields{
    display:grid;
    grid-gap:0 30px;
    grid-template-areas:'cell-4' 'cell-5' 'cell-6' 'cell-7';
    grid-template-columns:100%;
}
 body .element-ads-form .formbuilder-fields > *{
    display:none;
}
 body .element-ads-form .formbuilder-fields > *:nth-child(4) {
     display:block;
    grid-area:cell-4;
}
 body .element-ads-form .formbuilder-fields > *:nth-child(5) {
     display:block;
    grid-area:cell-5;
}
 body .element-ads-form .formbuilder-fields > *:nth-child(6) {
     display:block;
    grid-area:cell-6;
}
 body .element-ads-form .formbuilder-fields > *:nth-child(7) {
     display:block;
    grid-area:cell-7;
}
 body .element-ads-form .formfield-columns{
    grid-template-columns:100%;
}
 body .element-ads-form .formbuilder .formfield-columns .input{
    position:relative;
}
 body .element-ads-form .formbuilder .formfield-columns input,body .element-ads-form .formbuilder .formfield-columns textarea{
    border-color:var(--color-gray);
    width:100%;
}
 body .element-ads-form .formbuilder .label > em{
    color:var(--text-color--text-secondary);
}
 body .formfield-input.input.captcha > .formfield-columns > .captcha-group .captcha-group{
    display:grid;
    grid-template-areas:'cell-2' 'cell-1';
    grid-template-columns:100%;
    grid-gap:10px 30px;
    position:relative;
}
 body .formfield-input.input.captcha > .formfield-columns > .captcha-group .captcha-group > *:first-child{
    grid-area:cell-1;
}
 body .formfield-input.input.captcha > .formfield-columns > .captcha-group .captcha-group > *:last-child{
    grid-area:cell-2;
}
 body .formfield-input.input.captcha > .formfield-columns .captcha-image{
    border:1px solid var(--color-gray);
    border-radius:4px;
    height:60px;
    overflow:hidden;
    position:relative;
    width:94px;
}
 body .formfield-input.input.captcha > .formfield-columns .captcha-image > img{
    min-width:96px;
    height:62px;
    position:absolute;
    left:-1px;
    top:-1px;
    right:-1px;
    bottom:-1px;
}
 body .element-ads-form .formbuilder .formbuilder-buttons{
    padding:0;
}
 body .element-ads-form .formbuilder .formbuilder-buttons button{
    background-color:var(--color-theme);
    border:none;
    border-radius:1000px;
    box-sizing:border-box;
    color:var(--color-white);
    cursor:pointer;
    display:inline-block;
    font-family:inherit;
    font-size:inherit;
    font-weight:600;
    line-height:40px;
    padding:0 20px;
    text-align:center;
    width:auto;
}
 @media screen and (min-width:992px) {
     body .element-ads-form .formbuilder-fields{
        grid-template-areas:'cell-4 cell-5' 'cell-6 cell-7';
        grid-template-columns:calc(50% - 15px) calc(50% - 15px);
    }
     body .formfield-input.input.captcha > .formfield-columns > .captcha-group .captcha-group{
        grid-template-areas:'cell-2 cell-1';
        grid-template-columns:calc(50% - 15px) calc(50% - 15px);
    }
     body .formfield-input.input.captcha > .formfield-columns .captcha-image{
        position:absolute;
        bottom:0;
    }
}
 .element-faq .faq-container{
    border-radius:10px;
    font-family:"Source Sans Pro",Arial,sans-serif;
    margin-bottom:20px;
    padding:10px;
}
 .element-faq .faq-content-wrapper{
    margin:auto;
    max-width:1140px;
}
 .element-faq .faq-item-answer-text p{
    font-size:14px;
}
 .element-faq .faq-item-answer-text{
    color:var(--color-gray-dark);
    font-size:14px;
    margin:0;
    margin-bottom:15px;
    text-align:start;
}
 .element-faq .faq-item-answer-wrapper{
    background-color:var(--color-gray-light);
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    max-height:0;
    overflow:hidden;
    padding:0px 15px;
    transition:max-height .2s cubic-bezier(0,1,0,1) -0.1s;
    z-index:1000;
}
 .element-faq .faq-item-answer-wrapper.show{
    max-height:9999px;
    transition-timing-function:cubic-bezier(0.5,0,1,0);
    transition-delay:0s;
}
 .element-faq .faq-item-arrow{
    text-align:end;
    transition:0.3s;
}
 .element-faq .faq-item-arrow::before{
    color:var(--color-theme);
    content:'\f107';
    font-family:'Font Awesome 5 Free';
    font-size:18px;
    transform:rotate(90deg);
    transition:transform 0.3s;
}
 .element-faq .faq-item-content{
    margin-bottom:15px;
}
 .element-faq .faq-item-question-text{
    font-size:16px;
    font-weight:700;
    margin:0;
    text-align:start;
    transition:color 0.3s;
}
 .element-faq .faq-item-question-wrapper{
    align-items:center;
    background-color:var(--color-gray-light);
    border-radius:10px;
    cursor:pointer;
    display:grid;
    grid-template-columns:calc(100% - 16px) 11px;
    justify-content:space-between;
    padding:15px;
    transition:all 1.4s ease;
}
 .element-faq .faq-item-question-wrapper.open{
    border-radius:10px 10px 0px 0px;
    padding-bottom:10px;
    transition:all 0.1s ease;
}
 .element-faq .faq-item-question-wrapper.open .faq-item-arrow{
    transform:rotate(180deg);
    transition:0.3s;
}
 .element-faq .faq-item-question-wrapper.open .faq-item-question-text{
    color:var(--color-theme);
    transition:color 0.3s;
}
 .element-faq .faq-title{
    border-radius:5px 5px 0 0;
    color:#fff;
    margin-bottom:15px;
}
 .element-faq .faq-title-text{
    color:var(--color-dark-background);
    font-size:35px;
    font-weight:700;
    line-height:100%;
    margin:0;
    text-align:center;
}
 @media (min-width:479px) {
     .element-faq .faq-container{
        padding:20px;
    }
     .element-faq .faq-item-question-text{
        font-size:16px;
    }
}
 @media(min-width:479px) {
     .element-faq .faq-container{
        padding:20px;
    }
     .element-faq .faq-item-question-text{
        font-size:16px;
    }
}
 @media(min-width:768px) {
     .element-faq .faq-container{
        padding:30px;
    }
     .element-faq .faq-title-text{
        font-size:45px;
    }
     .element-faq .faq-item-question-text{
        font-size:18px;
    }
     .element-faq .faq-item-answer-text,.element-faq .faq-item-answer-text p{
        font-size:16px;
    }
}
 @media (min-width:991px) {
     .element-faq .faq-item-content,.element-faq .faq-title{
        margin-bottom:20px;
    }
     .element-faq .faq-title-text{
        font-size:65px;
    }
     .element-faq .faq-item-question-text{
        font-size:24px;
    }
     .element-faq .faq-item-answer-text,.element-faq .faq-item-answer-text p{
        font-size:18px;
    }
     .element-faq .faq-container{
        padding:40px;
    }
}
 body .web-feedback-background{
    background-color:rgba(0,0,0,0.5);
    bottom:0px;
    height:100%;
    position:fixed;
    right:0px;
    width:100%;
    z-index:100;
}
 body .web-feedback-form{
    bottom:80%;
    font-family:Source Sans Pro;
    position:fixed;
    right:-360px;
    transition:right 0.3s ease-in-out;
    z-index:150;
}
 body .web-feedback-form.open{
    right:0px;
    transition:right 0.3s ease-in-out;
}
 .web-feedback-form .feedback-form{
    background-color:white;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
    box-shadow:0 2px 4px rgba(0,0,0,0.2);
    display:grid;
    grid-template-areas:"cancel" "content";
    grid-template-rows:40px 480px;
    height:520px;
    position:absolute;
    right:0px;
    width:310px;
}
 .feedback-form .feedback-form-cancel{
    grid-area:cancel;
}
 .feedback-form .cancel-btn{
    background-size:contain;
    background-repeat:no-repeat;
    background-image:url('https://grid.com/data/template/feedback-cancel.png');
    content:'';
    cursor:pointer;
    height:27px;
    position:absolute;
    right:15px;
    top:15px;
    width:27px;
}
 .feedback-form .feedback-form-content,.feedback-form .feedback-form-content.thank-you{
    display:grid;
    padding:20px;
}
 .feedback-form .feedback-form-content{
    grid-area:content;
    grid-template-areas:"title" "description" "feedback-types" "feedback-message" "submit";
    grid-template-rows:50px 80px 140px 130px 40px;
}
 .feedback-form .feedback-form-title,.feedback-form .feedback-thank-you-title{
    color:var(--color-black);
    font-size:24px;
    font-weight:700;
    grid-area:title;
    margin-top:10px;
    text-align:center;
}
 .feedback-form .feedback-form-description,.feedback-form .feedback-thank-you-description{
    color:var(--color-gray1);
    font-size:16px;
    font-weight:normal;
    grid-area:description;
    text-align:center;
}
 .feedback-form .feedback-form-input-content-description{
    display:none;
}
 .feedback-form .feedback-form-input-content-description.selected{
    border:1.5px solid;
    border-bottom:0px;
    border-radius:5px 5px 0px 0px;
    color:var(--color-theme);
    display:block;
    font-size:10px;
    margin-top:10px;
    padding-left:10px;
    padding-top:6px;
}
 .feedback-form .feedback-form-type-buttons{
    display:grid;
    gap:10px;
    grid-area:feedback-types;
    grid-template-columns:repeat(3,1fr);
}
 .feedback-form-type-button.selected .feedback-form-type-button-text,.feedback-form .feedback-form-type-button:hover > .feedback-form-type-button-text{
    color:var(--color-theme);
}
 .feedback-form .feedback-form-type-button-text{
    color:var(--color-gray1);
    font-size:14px;
    font-weight:normal;
    margin-top:30px;
    text-align:center;
}
 .feedback-form .feedback-form-type-button:hover,.feedback-form .feedback-form-type-button.selected{
    border:solid 1.5px var(--color-theme);
}
 .feedback-form .feedback-form-type-button{
    border:solid 1.5px var(--color-gray-light);
    border-radius:5px;
    cursor:pointer;
    padding-top:5px;
    position:relative;
    text-align:center;
}
 .feedback-form .feedback-form-type-button-icon-hover{
    content:'';
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
    height:50%;
    position:absolute;
    width:100%;
    z-index:-1;
}
 .feedback-form .feedback-form-type-button-icon{
    content:'';
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
    height:50%;
    position:absolute;
    width:100%;
    z-index:100;
}
 .feedback-form .feedback-form-type-button:hover > .feedback-form-type-button-icon,.feedback-form .feedback-form-type-button.selected .feedback-form-type-button-icon{
    z-index:-1;
}
 .feedback-form .feedback-form-type-button:hover > .feedback-form-type-button-icon-hover,.feedback-form .feedback-form-type-button.selected .feedback-form-type-button-icon-hover{
    z-index:100;
}
 .feedback-form .feedback-form-type-button-icon.bug{
    background-image:url('https://grid.com/data/template/feedback-bug-icon.png');
}
 .feedback-form .feedback-form-type-button-icon.info{
    background-image:url('https://grid.com/data/template/feedback-info-icon.png');
}
 .feedback-form .feedback-form-type-button-icon.suggestion{
    background-image:url('https://grid.com/data/template/feedback-suggestion-icon.png');
}
 .feedback-form .feedback-form-type-button-icon.page{
    background-image:url('https://grid.com/data/template/feedback-page-icon.png');
}
 .feedback-form .feedback-form-type-button-icon.message{
    background-image:url('https://grid.com/data/template/feedback-message-icon.png');
}
 .feedback-form .feedback-form-type-button-icon.compliment{
    background-image:url('https://grid.com/data/template/feedback-compliment-icon.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.bug{
    background-image:url('https://grid.com/data/template/feedback-bug-icon-color-theme.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.info{
    background-image:url('https://grid.com/data/template/feedback-info-icon-color-theme.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.suggestion{
    background-image:url('https://grid.com/data/template/feedback-suggestion-icon-color-theme.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.page{
    background-image:url('https://grid.com/data/template/feedback-page-icon-color-theme.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.message{
    background-image:url('https://grid.com/data/template/feedback-message-icon-color-theme.png');
}
 .feedback-form .feedback-form-type-button-icon-hover.compliment{
    background-image:url('https://grid.com/data/template/feedback-compliment-icon-color-theme.png');
}
 .feedback-form .feedback-form-input-message{
    grid-area:feedback-message;
}
 .feedback-form .feedback-form-input-message textarea.message-textarea.selected::placeholder{
    color:var(--color-white);
}
 .feedback-form .feedback-form-input-message textarea:focus{
    border:solid 1.5px var(--color-theme);
}
 .feedback-form .feedback-form-input-message .message-textarea{
    border:solid 1.5px var(--color-gray-light);
    border-radius:5px;
    color:var(--color-black);
    font-weight:normal;
    height:110px;
    outline:none;
    margin-top:10px;
    padding:10px;
    resize:none;
    width:100%;
}
 .feedback-form .feedback-form-input-message .message-textarea.selected{
    border-bottom:solid 1.5px var(--color-theme);
    border-radius:0px 0px 5px 5px;
    border-left:solid 1.5px var(--color-theme);
    border-right:solid 1.5px var(--color-theme);
    border-top:none;
    color:var(--color-black);
    font-weight:normal;
    height:88px;
    margin-top:0px;
    outline:none;
    padding:10px;
    padding-top:0px;
    resize:none;
    width:100%;
}
 .feedback-form .feedback-form-submit-btn{
    background-color:var(--color-theme);
    border-radius:5px;
    color:var(--color-white);
    cursor:pointer;
    font-weight:600;
    grid-area:submit;
    padding:8px;
    text-align:center;
}
 .feedback-form .feedback-form-submit-btn:hover{
    background-color:var(--color-theme-50);
    color:var(--color-white);
}
 .feedback-form .feedback-form-content.thank-you{
    grid-area:content;
    grid-template-areas:"thank-you-picture" "thank-you-title" "thank-you-description";
    grid-template-rows:150px 85px 190px;
}
 .feedback-form .feedback-thank-you-picture{
    background-image:url('https://grid.com/data/template/feedback-thank-you-bg2.png');
    background-position:center center;
    background-repeat:no-repeat;
    background-size:contain;
    content:'';
    grid-area:thank-you-picture;
    height:100%;
    width:100%;
}
 .feedback-form .feedback-thank-you-title{
    grid-area:thank-you-title;
}
 .feedback-form .feedback-thank-you-description{
    grid-area:thank-you-description;
}
 @media(min-width:480px) {
     .web-feedback-form.thank-you .feedback-form,.web-feedback-form.form .feedback-form{
        width:360px;
    }
     .feedback-form .feedback-form-content.thank-you{
        grid-template-rows:150px 55px 190px;
    }
     .feedback-form .feedback-form-content{
        grid-template-rows:50px 70px 150px 130px 40px;
    }
}
 @media (max-width:350px) {
     body .web-feedback-form{
        bottom:95%;
    }
}
 body .grid-form{
    --gap-width:8px;
    --gap-height:8px;
    background-color:#2D75FF;
    color:#FFFFFF;
    padding:20px;
}
 @media (min-width:600px) {
     .grid-form .grid-auto-75{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:calc(100% - 75px - var(--gap-width)) 75px;
    }
     .grid-form .grid-auto-75-75{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:calc(100% - 150px - var(--gap-width) - var(--gap-width)) 75px 75px;
    }
     .grid-form .grid-auto-150{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:calc(100% - 150px - var(--gap-width)) 150px;
    }
     .grid-form .grid-25-75{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:25% calc(75% - var(--gap-width));
    }
     .grid-form .grid-50-50{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:calc(50% - (var(--gap-width) / 2)) calc(50% - (var(--gap-width) / 2));
    }
     .grid-form .grid-75-auto{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:75px calc(100% - 80px);
    }
     .grid-form .grid-150-auto{
        display:grid;
        grid-gap:var(--gap-width);
        grid-template-columns:150px calc(100% - 150px - var(--gap-width));
    }
}
 .grid-form input[type="date"],.grid-form input[type="number"],.grid-form input[type="text"],.grid-form input[type="time"]{
    border:#FFFFFF solid 1px;
    border-radius:var(--gap-width);
    box-sizing:border-box;
    color:#222222;
    display:block;
    font-family:inherit;
    line-height:48px;
    margin:0px;
    padding:0px 16px 0px 16px;
    position:relative;
    width:100%;
    z-index:0;
}
 .grid-form textarea{
    border:#FFFFFF solid 1px;
    border-radius:var(--gap-width);
    box-sizing:border-box;
    color:#222222;
    font-family:inherit;
    line-height:20px;
    margin:0px;
    max-height:320px;
    min-height:80px;
    padding:9px 16px 9px 16px;
    resize:vertical;
    width:100%;
}
 .grid-form select{
    border:#FFFFFF solid 1px;
    box-sizing:border-box;
    color:#222222;
    line-height:30px;
    margin:0px;
    width:100%;
}
 .grid-form .form-button button{
    background-color:#0DBC81;
    border:#0DBC81 solid 1px;
    border-radius:24px;
    color:#FFFFFF;
    display:block;
    font-family:inherit;
    font-weight:bold;
    line-height:48px;
    width:100%;
}
 .grid-form * + .form-input,.grid-form * + .form-button,.grid-form input + input[type="date"],.grid-form input + input[type="number"],.grid-form input + input[type="text"],.grid-form input + input[type="time"]{
    margin-top:var(--gap-height);
}
 @media (min-width:600px) {
     .grid-form input + input[type="date"],.grid-form input + input[type="number"],.grid-form input + input[type="text"],.grid-form input + input[type="time"]{
        margin-top:0px;
    }
}
 body .flex-left-top{
    align-items:start;
    display:flex;
    flex-flow:column;
    justify-content:start;
}
 body .flex-center-top{
    align-items:center;
    display:flex;
    flex-flow:column;
    justify-content:start;
}
 body .flex-right-top{
    align-items:end;
    display:flex;
    flex-flow:column;
    justify-content:start;
}
 body .flex-left-center{
    align-items:start;
    display:flex;
    flex-flow:column;
    justify-content:center;
}
 body .flex-center,body .flex-center-center{
    align-items:center;
    display:flex;
    flex-flow:column;
    justify-content:center;
}
 body .flex-right-center{
    align-items:end;
    display:flex;
    flex-flow:column;
    justify-content:center;
}
 body .flex-left-bottom{
    align-items:start;
    display:flex;
    flex-flow:column;
    justify-content:end;
}
 body .flex-center-bottom{
    align-items:center;
    display:flex;
    flex-flow:column;
    justify-content:end;
}
 body .flex-right-bottom{
    align-items:end;
    display:flex;
    flex-flow:column;
    justify-content:end;
}
 body .product-selections{
    cursor:pointer;
    display:grid;
    font-family:Source Sans Pro,sans-serif;
    font-weight:700;
    grid-gap:20px;
    grid-template-columns:auto auto auto;
    margin:auto;
    margin-bottom:40px;
    max-width:750px;
}
 .product-selections .product-selections-title{
    color:var(--color-black);
    font-size:16px;
    text-align:center;
}
 .product-selections .product-selections-image img{
    height:auto;
}
 .product-selections .product-selections-image{
    height:auto;
    width:auto;
}
 .product-selections .product-selections-selection{
    display:grid;
    grid-template-rows:auto 40px;
}
 @media(min-width:620px) {
     body .product-selections{
        margin-bottom:24px;
    }
}
 @media(min-width:767px) {
     .product-selections .product-selections-title{
        font-size:24px;
    }
}
 body .product-tiles{
    font-family:Source Sans Pro,sans-serif;
}
 .product-tiles .product-tile-image{
    margin:auto;
    padding:20px;
    width:280px;
}
 .product-tiles .product-tile-content{
    display:grid;
    height:424px;
    padding:20px;
}
 .product-tiles .product-tile{
    display:none;
}
 .product-tiles .product-tile.selected{
    display:grid;
    height:730px;
    grid-template-rows:300px min-content;
}
 .product-tiles .product-tile-title{
    color:var(--color-white);
    font-size:35px;
    font-weight:700;
    line-height:100%;
    margin-bottom:15px;
    text-align:center;
}
 .product-tiles .product-tile-subtitle{
    color:var(--color-white);
    font-size:24px;
    font-weight:700;
    line-height:100%;
    margin-bottom:15px;
    text-align:center;
}
 .product-tiles .product-tile-description{
    color:var(--color-white);
    font-size:16px;
    margin-bottom:15px;
    text-align:center;
}
 .product-tiles .product-tile-highlights{
    display:block;
    margin:auto;
    margin-bottom:15px;
    text-align:center;
}
 .product-tiles .product-tile-highlights-highlight{
    display:grid;
    grid-gap:20px;
    grid-template-columns:10px 100%;
    margin:auto;
}
 .product-tiles .product-tile-highlights-highlight-checkmark::before{
    color:var(--color-white);
    content:'\f00c';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    line-height:1;
    text-align:center;
}
 .product-tiles .product-tile-highlights-highlight-text{
    color:var(--color-white);
    font-size:16px;
    text-align:start;
    width:100%;
}
 .product-tiles .product-tile-detail-button:hover{
    background:var(--color-gray-light);
}
 .product-tiles .product-tile-detail-button a{
    display:block;
    font-size:16px;
    height:100%;
    padding:12px;
    position:absolute;
    right:0px;
    top:0px;
    width:100%;
}
 .product-tiles .product-tile-detail-button{
    background:var(--color-white);
    border-radius:20px;
    cursor:pointer;
    font-weight:700;
    left:0;
    line-height:1;
    margin:auto;
    padding:20px 0px 20px 0px;
    position:relative;
    text-align:center;
    top:-1px;
    width:200px;
    z-index:100;
}
 .product-tiles .product-tile{
    background-color:var(--color-theme);
    border-radius:20px;
}
 @media(min-width:767px) {
     .product-tiles .product-tile-title{
        font-size:45px;
    }
     .product-tiles .product-tile-detail-button a{
        font-size:18px;
    }
     .product-tiles .product-tile-highlights-highlight-text{
        font-size:18px;
    }
     .product-tiles .product-tile-image{
        width:350px;
    }
}
 @media(min-width:991px) {
     .product-tiles .product-tile-description{
        font-size:18px;
    }
     .product-tiles .product-tile-image{
        width:450px;
    }
}
 @media(min-width:620px) {
     .product-tiles .product-tile.selected{
        grid-template-rows:unset;
        height:auto;
    }
     .product-tiles .product-tile{
        grid-template-areas:"content image";
        grid-template-columns:50% 50%;
        grid-template-rows:unset;
    }
     .product-tiles .product-tile-content{
        height:auto;
        display:grid;
        grid-area:content;
        padding:30px;
    }
     .product-tiles .product-tile-image{
        grid-area:image;
        padding:30px;
    }
     .product-tiles .product-tile-title,.product-tiles .product-tile-subtitle,.product-tiles .product-tile-description{
        text-align:start;
    }
     .product-tiles .product-tile-highlights{
        margin:unset;
        margin-bottom:20px;
    }
     .product-tiles .product-tile-detail-button{
        margin:unset;
    }
}
 body .element-registration-form{
    align-items:center;
    background-color:var(--color-white);
    display:flex;
    font-family:"Source Sans Pro",Arial,sans-serif;
    height:100%;
    justify-content:center;
    margin:0 auto !important;
    max-width:650px;
    padding:0px !important;
    text-align:center;
}
 .element-registration-form .registration-form-description{
    color:var(--color-gray1);
    font-size:14px;
    font-weight:normal;
    line-height:150%;
    margin-bottom:20px;
}
 .element-registration-form .registration-form-title{
    color:var(--color-dark-background);
    font-size:35px;
    font-weight:700 !important;
    line-height:100%;
    margin-bottom:10px;
}
 .element-registration-form .registration-form-subtitle{
    color:var(--color-theme);
    font-size:24px;
    font-weight:600 !important;
    line-height:100% !important;
    margin-bottom:10px;
}
 .element-registration-form .registration-form-submit-btn{
    background-color:var(--color-gray-light);
    border:none;
    border-radius:22px;
    color:var(--color-gray);
    cursor:pointer;
    display:block;
    font-size:18px;
    font-weight:bold;
    line-height:44px;
    margin:0 !important;
    padding:0px 20px 0px 20px;
    text-align:center;
    width:100% !important;
}
 .element-registration-form .formbuilder-buttons{
    padding-left:0px;
}
 .element-registration-form .registration-form-submit-btn.clickable{
    background-color:var(--color-theme);
    color:var(--color-white);
}
 .element-registration-form .registration-form-submit-btn.clickable:focus,.element-registration-form .registration-form-submit-btn.clickable:hover{
    background-color:var(--color-theme-50);
    color:var(--color-white);
}
 .element-registration-form .registration-form-submit-btn.unclickable{
    cursor:not-allowed;
    pointer-events:none;
}
 .element-registration-form .registration-thank-you-description{
    color:var(--color-gray1);
    font-size:14px;
    font-weight:normal;
}
 .element-registration-form .registration-thank-you-image{
    align-items:center;
    background-image:url('https://grid.com/data/images-not-gitignore/succes.png');
    background-repeat:no-repeat;
    background-size:contain;
    display:inline-block;
    font-size:10px;
    height:70px;
    justify-content:center;
    line-height:1;
    margin-bottom:10px;
    text-align:center;
    width:70px;
}
 .element-registration-form .registration-thank-you-title{
    color:var(--color-theme);
    font-size:35px;
    margin-bottom:30px;
}
 .element-registration-form .textfield{
    color:var(--color-gray-dark);
    font-size:18px !important;
    font-weight:normal;
    margin-bottom:15px;
    max-width:100%;
    width:100% !important;
}
 .element-registration-form .textfield.textfield-text{
    border:none;
    border-bottom:1px solid var(--color-gray);
    border-radius:0px;
    padding:20px 0px 20px 0px;
}
 .element-registration-form .textfield.textfield-numeric{
    border-color:#0DBC81;
    border-radius:3px;
    height:54px;
    text-align:center;
    padding:9px 5px;
    width:39px !important;
}
 .element-registration-form .formbuilder .input .textfield:focus{
    border:unset;
    border-bottom:1px solid #0DBC81;
}
 .element-registration-form .formbuilder .input .textfield.textfield-numeric:focus{
    border:#222222 solid 1px;
}
 body .container-10.map{
    padding:40px !important;
    width:100%;
}
 body .div-block-18{
    padding-bottom:0px !important;
}
 #registration-form-step-b .formbuilder-fields{
    align-items:center;
    display:flex;
    justify-content:center;
}
 #registration-form-step-b .formbuilder-fields .formfield-row{
    margin-right:10px;
}
 @media (min-width:768px) {
     .element-registration-form .registration-thank-you-title,.element-registration-form .registration-form-title{
        font-size:45px;
    }
     .element-registration-form .registration-thank-you-description,.element-registration-form .registration-form-description{
        font-size:16px;
    }
     .element-registration-form .registration-form-subtitle{
        font-size:28px;
    }
}
 @media (min-width:992px) {
     .element-registration-form .registration-thank-you-title,.element-registration-form .registration-form-title{
        font-size:65px;
    }
     .element-registration-form .registration-thank-you-description,.element-registration-form .registration-form-description{
        font-size:18px;
    }
     .element-registration-form .registration-form-subtitle{
        font-size:24px;
    }
}
 .element-scroll-button .scroll-button{
    background-color:var(--color-theme);
    border:none;
    border-radius:30px;
    color:var(--color-white);
    cursor:pointer;
    display:block;
    font-size:16px;
    font-weight:bold;
    margin:0 !important;
    padding:20px;
    text-align:center;
    width:100% !important;
}
 .element-scroll-button .scroll-button:hover{
    background-color:var(--color-theme-50);
}
 @media (min-width:768px) {
     .element-scroll-button .scroll-button{
        font-size:18px;
    }
}
 body .location-banner{
    display:block;
    overflow:hidden;
    position:relative;
}
 body .web-element.element-banner{
    height:100%;
}
 body .web-element.element-banner .web-banner{
    background-image:url('https://grid.com/data/banners/banner-background.jpg');
}
 body .element-banner .web-banner:before{
    background-color:rgba(0,0,0,0.3);
    content:'';
    display:block;
    height:100%;
    left:0px;
    position:absolute;
    top:0px;
    width:100%;
}
 body .location-banner .web-banner{
    background-position:center;
    background-size:cover;
    display:block;
    height:280px;
    left:0px;
    position:relative;
    top:0px;
    width:100%;
}
 body .location-banner .web-banner a{
    color:inherit;
    display:block;
    line-height:inherit;
    text-align:center;
}
 body .location-banner .web-banner .banner-logo{
    background-image:url('https://grid.com/data/banners/banner-logo.png');
    background-position:center;
    background-size:contain;
    display:block;
    height:46px;
    left:15px;
    position:absolute;
    top:15px;
    width:78px;
}
 body .location-banner .web-banner .banner-button-1{
    background-color:#0DBC81;
    border-radius:20px;
    color:#FFFFFF;
    display:block;
    height:40px;
    left:100px;
    line-height:40px;
    position:absolute;
    text-align:center;
    top:18px;
    width:148px;
}
 body .location-banner .web-banner .banner-button-2{
    background-color:#2D75FF;
    border-radius:28px;
    bottom:40px;
    color:#FFFFFF;
    display:block;
    font-size:18px;
    font-weight:600;
    height:44px;
    left:15px;
    line-height:44px;
    position:absolute;
    text-align:center;
    width:calc(100% - 30px);
}
 body .location-banner .web-banner .banner-button-3{
    bottom:15px;
    color:#FFFFFF;
    display:block;
    height:20px;
    left:15px;
    line-height:20px;
    position:absolute;
    text-align:center;
    width:calc(100% - 30px);
}
 body .location-banner .web-banner .banner-text{
    bottom:95px;
    color:#FFFFFF;
    display:block;
    font-family:Source Sans Pro,sans-serif;
    font-size:35px;
    font-weight:900;
    height:auto;
    left:15px;
    line-height:35px;
    position:absolute;
    width:calc(100% - 30px);
}
 @media (min-width:480px) {
     body .location-banner .web-banner .banner-text{
        font-size:26px;
        line-height:26px;
    }
     body .location-banner .web-banner .banner-button-1{
        display:none;
    }
}
 @media (min-width:600px) {
     body .location-banner .web-banner{
        height:100%;
        min-height:300px;
    }
     body .location-banner .web-banner .banner-button-1{
        display:block;
    }
     body .location-banner .web-banner .banner-button-2{
        width:256px;
    }
     body .location-banner .web-banner .banner-button-3{
        width:256px;
    }
     body .location-banner .web-banner .banner-text{
        font-size:35px;
        line-height:35px;
        width:256px;
    }
}
 @media (min-width:900px) {
     body .location-banner .web-banner .banner-logo{
        left:30px;
        top:30px;
    }
     body .location-banner .web-banner .banner-button-1{
        left:115px;
        top:30px;
    }
     body .location-banner .web-banner .banner-button-2{
        bottom:55px;
        left:30px;
    }
     body .location-banner .web-banner .banner-button-3{
        bottom:30px;
        left:30px;
    }
     body .location-banner .web-banner .banner-text{
        bottom:120px;
        left:30px;
    }
}
 @media (min-width:1200px) {
     body .location-banner .web-banner .banner-button-2{
        bottom:30px;
        border-radius:28px;
        height:56px;
        line-height:56px;
    }
     body .location-banner .web-banner .banner-button-3{
        height:56px;
        left:280px;
        line-height:56px;
        text-align:left;
        width:200px;
    }
}
 .element-blogs .widget-blog-slider{
    grid-row-gap:60px;
}
 .element-blogs .widget-blog-slider .slide{
    background:unset;
}
 .element-blogs .blog-tile{
    height:100%;
    min-height:500px;
    padding:5px 5px 0px 5px;
}
 .element-blogs .blog-tile a{
    color:#0DBC81;
}
 .element-blogs .blog-tile .image{
    background-color:var(--color-gray-light);
}
 .element-blogs .blog-tile .date{
    font-size:80%;
    line-height:100%;
    margin-top:20px;
    text-align:right;
}
 .element-blogs .blog-tile .title{
    font-size:125%;
    line-height:110%;
    margin-top:20px;
    text-align:left;
}
 .element-blogs .blog-tile .summary{
    /* color:var(--color-black-light);
    */
     margin-bottom:50px;
    margin-top:20px;
}
 .element-blogs .blog-tile .buttons{
    text-align:center;
    position:absolute;
    bottom:0;
}
 .element-blogs .blog-tile .buttons a::before{
    margin:0;
}
 .element-blogs .blog-title .button-arrow{
    display:inline-block;
}
 .element-blogs .blog-tile .button-arrow::before{
    background:var(--color-theme);
    border-radius:50%;
    content:'';
    color:var(--color-white);
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-size:18px;
    font-style:normal;
    font-variant:normal;
    font-weight:normal;
    height:40px;
    line-height:40px;
    margin-right:0;
    text-align:center;
    width:40px;
}
 .element-blogs .owl-carousel .owl-nav.disabled,.element-blogs .blog-tile .buttons{
    display:none;
}
 @media(min-width:620px) {
     .element-blogs .blog-tile{
        padding:20px 20px 0px 20px;
    }
}
 @media(min-width:920px) {
     .element-blogs .blog-tile{
        padding:30px 30px 0px 30px;
    }
}
 body .element-chatbox-button{
    align-items:center;
    bottom:20px;
    position:fixed;
    right:20px;
    z-index:100;
}
 .element-chatbox-button .chatbox-button{
    background-color:var(--color-theme);
    border:none;
    border-radius:50%;
    bottom:20px;
    box-shadow:0 2px 4px rgba(0,0,0,0.2);
    color:var(--color-white);
    cursor:pointer;
    font-size:20px;
    font-weight:bold;
    margin-bottom:10px;
    padding:20px;
    position:fixed;
    right:20px;
    z-index:100;
}
 .element-chatbox-button .chatbox-button:hover{
    background-color:var(--color-theme-50);
    outline:none;
}
 .element-chatbox-button .chatbox-button:before{
    content:'\f4b6';
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:30px;
    line-height:1;
    text-align:center;
}
 .element-chatbox-button .chatbox-button-text{
    background-color:var(--color-gray-light);
    border-radius:10px;
    box-shadow:0 2px 4px rgba(0,0,0,0.2);
    color:var(--color-gray-dark1);
    display:none;
    font-family:Source Sans Pro;
    font-weight:bold;
    padding:15px 70px 15px 20px;
    margin-bottom:19px;
    margin-right:90px;
}
 .scroll-up.scroll-top .element-chatbox-button .chatbox-button-text{
    display:block;
}
 body .web-element.element-citibee-form{
    margin:0 auto;
    max-width:300px;
    width:min(100%,300px);
}
 .element-citibee-form .formbuilder .input input{
    height:56px;
    width:100%;
}
 .element-citibee-form .formbuilder .input input:focus{
    border:1px solid #0DBC81 !important;
    color:#0DBC81;
}
 .element-citibee-form .input-message{
    position:relative;
}
 .element-citibee-form .formbuilder .input *::placeholder,.element-citibee-form .formbuilder .input *::-ms-input-placeholder,.element-citibee-form .formbuilder .input .textfield::placeholder,.element-citibee-form .formbuilder .input .textfield::-ms-input-placeholder{
    color:var(--color-black);
    opacity:1;
}
 .element-citibee-form .formbuilder-buttons{
    display:flex;
    flex-direction:column;
    grid-gap:10px;
    padding:0;
}
 .element-citibee-form .web-button{
    background-color:var(--color-gray);
    border:none;
    border-radius:1000px;
    box-sizing:border-box;
    color:var(--color-white);
    cursor:pointer;
    display:inline-block;
    font-family:inherit;
    font-size:inherit;
    font-weight:600;
    line-height:40px;
    padding:0 20px;
    text-align:center;
    width:auto;
}
 .element-citibee-form .web-button.background-black,.element-citibee-form .web-button.web-button-black{
    background-color:var(--color-dark-background);
}
 .element-citibee-form .web-button.background-theme,.element-citibee-form .web-button.web-button-theme{
    background-color:var(--color-theme);
}
 .element-citibee-form .web-button.background-theme-dark,.element-citibee-form .web-button.web-button-theme-dark{
    background-color:var(--color-theme2);
}
 .element-citibee-form .web-button.background-white,.element-citibee-form .web-button.web-button-white{
    background-color:var(--color-white);
    color:var(--color-dark-background);
}
 .element-header .web-button,.element-footer .web-button{
    background-color:var(--color-gray);
    border:none;
    border-radius:1000px;
    box-sizing:border-box;
    color:var(--color-white);
    cursor:pointer;
    display:inline-block;
    font-family:poppins;
    font-size:inherit;
    font-weight:600;
    line-height:40px;
    padding:0 20px;
    text-align:center;
    width:auto;
}
 .element-header .web-button.web-button-black,.element-footer .web-button.web-button-black{
    background-color:var(--color-dark-background);
}
 .element-header .web-button.web-button-theme,.element-footer .web-button.web-button-theme{
    background-color:var(--color-theme);
}
 .element-header .web-button.web-button-theme-dark,.element-footer .web-button.web-button-theme-dark{
    background-color:var(--color-theme2);
}
 .element-header .web-button.web-button-white,.element-footer .web-button.web-button-white{
    background-color:var(--color-white);
    color:var(--color-dark-background);
}
 body .element-cookie-banner{
    position:relative;
    z-index:1000000;
}
 .element-cookie-banner .element-cookie-banner-container{
    align-items:baseline;
    -webkit-backdrop-filter:blur(2px);
    backdrop-filter:blur(2px);
    background:rgba(63,63,63,0.7);
    bottom:0;
    display:flex;
    justify-content:center;
    left:0;
    position:fixed;
    right:0;
    top:0;
}
 .element-cookie-banner .element-cookie-banner-wrapper{
    background:var(--color-white);
    border-radius:24px;
    margin-top:30px;
    max-height:80vh;
    overflow:auto;
    padding:30px 20px 20px;
    position:relative;
}
 .element-cookie-banner .element-cookie-banner-wrapper form{
    display:flex;
    max-height:calc(80vh - 50px);
    width:100%;
}
 .element-cookie-banner .element-cookie-banner-content{
    display:flex;
    flex-direction:column;
    justify-content:center;
}
 .element-cookie-banner .element-cookie-banner-title{
    flex-shrink:0;
    font-size:150%;
    font-weight:700;
    line-height:40px;
    padding-right:40px;
}
 .element-cookie-banner .element-cookie-banner-text-collapse{
    display:none;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-text-collapse{
    color:var(--color-gray);
    cursor:pointer;
    display:inline-block;
    font-size:75%;
    margin-left:10px;
    text-align:center;
    width:30px;
}
 .element-cookie-banner .element-cookie-banner-text-collapse::before{
    content:'\f078';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
}
 .element-cookie-banner .element-cookie-banner-text{
    flex-grow:1;
    margin:10px 0;
    overflow-y:auto;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-text{
    flex-grow:0;
    height:0;
    margin:0;
    overflow:hidden;
}
 .element-cookie-banner .element-cookie-banner-text a{
    font-weight:600;
}
 .element-cookie-banner .element-cookie-banner-texts-wrapper{
    height:0;
    overflow:hidden;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-texts-wrapper{
    flex-grow:1;
    height:auto;
    margin:10px 0;
    overflow-y:auto;
}
 .element-cookie-banner .element-cookie-banner-texts{
    overflow:hidden;
}
 .element-cookie-banner .element-cookie-banner-texts-title{
    line-height:200%;
    min-height:36px;
    padding-left:36px;
    position:relative;
}
 .element-cookie-banner .element-cookie-banner-texts-collapse{
    color:var(--color-gray);
    cursor:pointer;
    display:inline-block;
    font-size:75%;
    text-align:center;
    width:30px;
}
 .element-cookie-banner .element-cookie-banner-texts-collapse::before{
    content:'\f078';
    display:inline-block;
    font-family:'Font Awesome 5 Free';
}
 .element-cookie-banner .element-cookie-banner-texts-more .element-cookie-banner-texts-collapse::before{
    content:'\f077';
}
 .element-cookie-banner .element-cookie-banner-texts-toggle{
    box-sizing:border-box;
    display:inline-block;
    height:24px;
    left:0;
    position:absolute;
    overflow:hidden;
    top:6px;
    width:24px;
}
 .element-cookie-banner .element-cookie-banner-texts-toggle input.hidden{
    display:none;
}
 .element-cookie-banner .element-cookie-banner-texts-toggle label{
    background:var(--color-white);
    border:1px solid var(--color-gray-light);
    border-radius:4px;
    bottom:0;
    cursor:pointer;
    left:0;
    margin:0;
    position:absolute;
    right:0;
    top:0;
    transition:background 0.2s;
}
 .element-cookie-banner .element-cookie-banner-texts-toggle input:checked + label{
    background:var(--color-blue);
    border:1px solid var(--color-blue);
}
 .element-cookie-banner .element-cookie-banner-texts-toggle input:checked:disabled + label{
    background:var(--color-theme);
    border:1px solid var(--color-theme);
    cursor:not-allowed;
}
 .element-cookie-banner .element-cookie-banner-texts-toggle input:checked + label::after{
    color:var(--color-white);
    content:'\f00c';
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:9px;
    height:22px;
    line-height:22px;
    position:absolute;
    right:0;
    text-align:center;
    top:0;
    width:22px;
}
 .element-cookie-banner .element-cookie-banner-texts-text{
    border-bottom:1px solid var(--color-gray-light);
    color:var(--color-gray1);
    height:0;
    overflow:hidden;
    overflow-wrap:break-word;
}
 .element-cookie-banner .element-cookie-banner-texts-more .element-cookie-banner-texts-text{
    height:auto;
}
 .element-cookie-banner .element-cookie-banner-buttons{
    align-items:center;
    display:flex;
    justify-content:space-between;
}
 .element-cookie-banner .element-cookie-banner-buttons > *{
    font-weight:600;
}
 .element-cookie-banner .element-cookie-banner-button{
    align-items:center;
    background:transparent;
    border-radius:20px;
    display:flex;
    line-height:100%;
    min-height:40px;
    padding:0 20px;
    transition:background 0.2s,color 0.2s;
}
 .element-cookie-banner .element-cookie-banner-accept{
    background:var(--color-theme);
    color:var(--color-white);
}
 .element-cookie-banner .element-cookie-banner-button:hover{
    background:var(--color-dark-background);
    color:var(--color-white);
}
 .element-cookie-banner .element-cookie-banner-buttons .element-cookie-banner-allow{
    display:none;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-buttons .element-cookie-banner-more{
    display:none;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-buttons .element-cookie-banner-allow{
    display:flex;
}
 .element-cookie-banner .element-cookie-banner-link{
    font-size:75%;
    margin-top:10px;
    opacity:0;
    pointer-events:none;
}
 .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-link{
    opacity:1;
    pointer-events:unset;
}
 @media (min-width:620px) {
     .element-cookie-banner .element-cookie-banner-container{
        align-items:center;
    }
     .element-cookie-banner .element-cookie-banner-wrapper{
        margin-top:0;
        max-height:50vh;
        max-width:520px;
        padding:30px 30px 20px;
    }
     .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-wrapper{
        min-height:375px;
    }
     .element-cookie-banner .element-cookie-banner-wrapper form{
        max-height:calc(50vh - 50px);
    }
     .element-cookie-banner .element-cookie-banner-container.element-cookie-banner-container-more .element-cookie-banner-wrapper form{
        min-height:315px;
    }
}
 @media (min-width:920px) {
     body .element-cookie-banner{
        font-size:18px;
    }
     .element-cookie-banner .element-cookie-banner-wrapper{
        max-width:620px;
    }
}
 body .element-cookies{
    bottom:0px;
    display:none;
    height:72px;
    left:0px;
    padding:0px;
    pointer-events:none;
    position:fixed;
    text-align:center;
    width:100%;
    z-index:10000000;
}
 body .element-cookies.show{
    display:block;
}
 .element-cookies .footer-cookies{
    background-color:var(--color-white);
    border:1px solid var(--color-theme);
    border-radius:6px;
    color:var(--color-black-dark);
    display:none;
    height:62px;
    max-width:360px;
    padding:10px 90px 10px 10px;
    pointer-events:auto;
    position:relative;
    text-align:left;
    width:calc(100% - 20px);
}
 .element-cookies .footer-cookies.cookie-notification-show{
    display:inline-block;
}
 .element-cookies .cookie-text{
    font-size:18px;
}
 .element-cookies .footer-cookies .cookie-close,.element-cookies .footer-cookies .cookie-info{
    border-radius:50%;
    height:40px;
    line-height:40px;
    position:absolute;
    right:10px;
    text-align:center;
    top:10px;
    width:40px;
}
 .element-cookies .footer-cookies .cookie-info{
    right:50px;
}
 .element-cookies .footer-cookies .cookie-close::before,.element-cookies .footer-cookies .cookie-info::before{
    color:inherit;
    content:'\f00d';
    cursor:pointer;
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:18px;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    height:40px;
    left:0px;
    line-height:40px;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:0px;
    width:40px;
}
 .element-cookies .footer-cookies .cookie-info::before{
    content:'\f128';
}
 @media (min-width:340px) {
     .element-cookies .cookie-text{
        line-height:40px;
    }
}
 .element-feedback-button .feedback-button-text,.web-feedback-form .feedback-button-text{
    color:var(--color-black);
    font-size:16px;
    text-align:center;
}
 .element-feedback-button .feedback-button,.web-feedback-form .feedback-button{
    background-color:var(--color-white);
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    top:288px;
    box-shadow:0px 0px 2px 0px rgba(0,0,0,0.2);
    cursor:pointer;
    padding:2px 5px 2px 5px;
    position:fixed;
    transition:right 0.3s ease-in-out;
    transform:rotate(-90deg);
    display:block;
    transform-origin:100% 0%;
    height:34px;
    right:34px;
}
 .web-feedback-form.open .feedback-button{
    right:344px;
}
 @media (max-width:350px) {
     .element-feedback-button .feedback-button.open,.web-feedback-form .feedback-button{
        display:none;
    }
}
 @media (min-width:480px) {
     .web-feedback-form.open .feedback-button{
        right:394px;
    }
}
 .element-footer .element-footer-wrapper{
    display:flex;
    flex-direction:column;
    grid-gap:32px;
    margin:0 auto;
    max-width:100%;
    padding:0 20px;
}
 .element-footer .element-footer-top{
    align-items:baseline;
    border-bottom:2px solid var(--color-white-light);
    display:flex;
    grid-gap:16px;
    justify-content:space-between;
    min-height:58px;
}
 .element-footer .element-footer-logo{
    height:19px;
    width:54px;
    position: relative;
    top: 85px;
}
 .element-footer .element-footer-logo a{
    background-image:url('../../public/Logo.png');
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
    display:inline-block;
    height:200px;
    width:200px;
    margin-left: 50%;
}
 .element-footer .element-footer-nav{
    flex-grow:1;
    margin-right: 5%;
}
 .element-footer ul.element-footer-nav-list{
    margin:0;
    /* RESET CSS COMPONENTS */
     padding:0;
    /* RESET CSS COMPONENTS */
}
 .element-footer .element-footer-nav-list{
    align-items:baseline;
    display:flex;
    grid-gap:16px;
    justify-content:right;
}
 .element-footer ul.element-footer-nav-list > li{
    margin:0;
    /* RESET CSS COMPONENTS */
     padding:0;
    /* RESET CSS COMPONENTS */
}
 .element-footer .element-footer-nav-item{
    font-size:16px;
    font-weight:600;
    list-style:none;
    min-height:40px;
}
 .element-footer .element-footer-bottom{
    align-items:center;
    display:flex;
    flex-direction:column-reverse;
    grid-gap:16px;
    justify-content:space-between;
    text-align:center;
}
 .element-footer .element-footer-reserved{
    color:#989898;
    font-size:14px;
    font-weight:400;
    font-family: 'Poppins';
    margin-left: 1%;
}
 .element-footer ul.element-footer-legal-links{
    margin:0;
    padding:0;
}
 .element-footer .element-footer-legal-links{
    display:flex;
    flex-direction:column;
    grid-gap:16px;
}
 .element-footer ul.element-footer-legal-links > li{
    margin:0;
    padding:0;
}
 .element-footer .element-footer-legal-links-item{
    font-size:16px;
    list-style:none;
}
 .element-footer .language-selector-wrapper{
    align-items:center;
    display:flex;
    grid-column-gap:8px;
    grid-row-gap:16px;
    justify-content:flex-start;
    position:relative;
}
 .element-footer .language-selector-option,.element-footer .language-selector-option a{
    color:rgba(50,52,58,0.5);
    text-decoration:none;
    transition:color .2s;
}
 .element-footer .language-selector-option{
    border:none;
    border-bottom:2px solid transparent;
}
 .element-footer .language-selector-option-active,.element-footer .language-selector-option-active a{
    color:rgba(50,52,58,1);
}
 .element-footer .language-selector-option-active{
    border-bottom:2px solid var(--color-theme);
}
 .element-footer .language-selector-option:hover,.element-footer .language-selector-option:hover a{
    color:var(--color-theme);
}
 @media (min-width:620px) {
     .element-footer .element-footer-nav-list{
        grid-column-gap:calc(16px + ((100vw - 620px) / 5));
    }
     .element-footer .element-footer-nav-item{
        font-size:24px;
    }
}
 @media (min-width:920px) {
     .element-footer .element-footer-top{
        min-height:70px;
    }
     .element-footer .element-footer-bottom{
        flex-direction:initial;
        text-align:left;
    }
     .element-footer .element-footer-legal-links{
        align-items:center;
        flex-direction:initial;
    }
     .element-chatbox-button .chatbox-button{
        transition:bottom 0.6s;
    }
     body.scroll-bottom .element-chatbox-button .chatbox-button{
        bottom:214px;
    }
     body.app-download .element-chatbox-button .chatbox-button{
        bottom:25px;
    }
}
 @media (min-width:1220px) {
     .element-footer .element-footer-nav-list{
        grid-column-gap:136px;
    }
}
 body .element-contact-form.grid-energy{
    font-family:unset;
    max-width:768px;
    width:100%;
}
 .element-contact-form.grid-energy .formbuilder .label{
    text-transform:uppercase;
}
 .element-contact-form.grid-energy .label > em{
    color:var(--color-theme);
}
 .element-contact-form.grid-energy .formbuilder input,.element-contact-form.grid-energy .formbuilder textarea{
    background:#000000;
    border:1px solid #727272;
    border-radius:8px;
    height:56px;
    padding:16px 12px;
    width:100%;
}
 .element-contact-form.grid-energy .formbuilder .input-message.input-message-error{
    color:#920000;
    padding:10px;
}
 .element-contact-form.grid-energy .formbuilder input:focus,.element-contact-form.grid-energy .formbuilder textarea:focus,.element-contact-form.grid-energy .formbuilder input:valid:not(:placeholder-shown),.element-contact-form.grid-energy .formbuilder textarea:valid:not(:placeholder-shown) {
     border:1px solid #FFFFFF;
}
 .element-contact-form.grid-energy .formbuilder textarea{
    height:auto;
    width:100%;
}
 .element-contact-form.grid-energy .formbuilder .formfield-html{
    text-align:center;
}
 .element-contact-form.grid-energy .formbuilder input::placeholder{
    color:#cacaca;
    font-size:14px;
}
 .element-contact-form.grid-energy .formbuilder .input .size-small + *{
    margin:0;
}
 .element-contact-form.grid-energy .formbuilder .formfield-row{
    margin-bottom:unset;
}
 .element-contact-form.grid-energy .formbuilder .formbuilder-buttons{
    align-items:center;
    display:flex;
    justify-content:center;
}
 .element-contact-form.grid-energy .formbuilder button{
    background:var(--color-theme);
    color:#FFFFFF;
    border-radius:42px;
    font-weight:bold;
    margin-top:25px;
    padding:10px 20px;
}
 body .element-contact-form.grid-energy .captcha-image > img{
    height:60px;
    margin-bottom:10px;
    width:94px;
}
 @media(min-width:520px) {
     .element-contact-form.grid-energy .formbuilder .formfield-columns{
        grid-template-columns:100%;
    }
     .element-contact-form.grid-energy .formbuilder .formbuilder-buttons{
        padding-left:unset;
    }
}
 @media(min-width:770px) {
     .element-contact-form.grid-energy .formbuilder .formbuilder-fields{
        column-gap:24px;
        display:grid;
        grid-template-areas:"title title" "content content" "hr hr" "name name" "company website" "email phone" "message message";
        grid-template-columns:50% 50%;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:first-child{
        grid-area:title;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(2) {
         grid-area:content;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(3) {
         grid-area:hr;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(4) {
         grid-area:name;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(5) {
         grid-area:company;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(6) {
         grid-area:website;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(7) {
         grid-area:email;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(8) {
         grid-area:phone;
    }
     .element-contact-form.grid-energy .formbuilder .formfield-row:nth-child(9) {
         grid-area:message;
    }
}
 body .element-quotation-form{
    border:1px solid #0DBC81;
    border-radius:30px;
}
 .element-quotation-form .form-order-chargestations{
    border-color:#0DBC81;
    border-radius:30px;
    padding:20px 20px;
}
 .form-order-chargestations .title{
    color:#000000;
    font-size:32px;
    font-weight:700;
    line-height:20px;
    margin-bottom:20px;
    text-align:center;
}
 .form-order-chargestations .text{
    font-weight:400;
}
 .form-order-chargestations .description{
    color:#7C7C7C;
    font-weight:normal;
    height:100px;
    text-align:center;
    width:auto;
}
 .form-order-chargestations form .label,.form-order-chargestations form .form-subtitle{
    color:#0DBC81;
    font-size:18px;
    font-weight:700;
}
 .element-quotation-form .form-order-chargestations .input .size-auto{
    width:100%;
}
 .form-order-chargestations .formbuilder-buttons{
    text-align:right;
}
 .form-order-chargestations button{
    align-self:center;
    background-color:#0dbc81;
    border-radius:42px;
    color:#fff;
    cursor:pointer;
    display:inline-block;
    font-family:Source Sans Pro,sans-serif;
    font-size:16px;
    font-weight:600;
    height:45px;
    letter-spacing:0;
    line-height:20px;
    margin-bottom:0;
    padding:10px 20px;
    text-decoration:none;
    text-transform:lowercase;
    transition:all .2s;
    width:100%;
}
 .form-order-chargestations .textfield::placeholder{
    color:#7C7C7C;
}
 .form-order-chargestations .formfield-row.message textarea{
    height:105px !important;
}
 .form-order-chargestations .formbuilder-buttons{
    grid-area:button;
}
 .form-order-chargestations .input input:focus{
    border:1px solid #0DBC81 !important;
    color:#0DBC81;
}
 .form-order-chargestations .formbuilder .input-message{
    color:red;
    position:absolute;
}
 .form-order-chargestations .formbuilder input.invalid{
    border-color:red;
}
 .form-order-chargestations .formbuilder .input .size-auto{
    border-color:#F0F0F0;
}
 .form-order-chargestations .request-buttons{
    text-align:center;
}
 .form-order-chargestations .request-buttons .request-title{
    color:#0DBC81;
    font-weight:bold;
    margin-bottom:10px;
}
 .form-order-chargestations .form-start.quotation,.form-order-chargestations .form-start.contact{
    display:none;
}
 .form-order-chargestations .form-start.quotation.active,.form-order-chargestations .form-start.contact.active{
    display:block;
}
 .form-order-chargestations .button-switch-contact,.form-order-chargestations .button-switch-quotation{
    align-self:center;
    border:1px solid #F0F0F0;
    border-radius:42px;
    color:#7C7C7C;
    cursor:pointer;
    display:inline-block;
    font-family:Source Sans Pro,sans-serif;
    font-size:16px;
    font-weight:400;
    letter-spacing:0;
    margin-bottom:15px;
    padding:5px 20px;
    text-decoration:none;
    width:auto;
}
 .form-order-chargestations .button-switch-contact.active,.form-order-chargestations .button-switch-quotation.active{
    background-color:rgba(13,188,129,.06);
    border:1px solid #0DBC81;
    border-radius:20px;
    color:#0DBC81;
    font-weight:600;
    padding:5px 20px;
}
 .form-order-chargestations .button-switch-contact{
    margin-right:5px;
}
 .form-order-chargestations > #chargestation-quotation .formfield-row.street,.form-order-chargestations > #chargestation-quotation .formfield-row.number,.form-order-chargestations > #chargestation-quotation .formfield-row.unit,.form-order-chargestations > #chargestation-quotation .formfield-row.postalcode,.form-order-chargestations > #chargestation-quotation .formfield-row.city,.form-order-chargestations > #chargestation-quotation .formfield-row.country{
    display:none;
}
 .form-order-chargestations > #chargestation-quotation.quotation .formfield-row.street,.form-order-chargestations > #chargestation-quotation.quotation .formfield-row.number,.form-order-chargestations > #chargestation-quotation.quotation .formfield-row.unit,.form-order-chargestations > #chargestation-quotation.quotation .formfield-row.postalcode,.form-order-chargestations > #chargestation-quotation.quotation .formfield-row.city,.form-order-chargestations > #chargestation-quotation.quotation .formfield-row.country{
    display:block;
}
 @media(min-width:1120px) {
     .element-quotation-form form{
        padding:30px;
        position:relative;
    }
     .form-order-chargestations .description{
        height:48px;
    }
     .form-order-chargestations > #chargestation-quotation .formbuilder-fields{
        display:grid;
        grid-template-columns:repeat(8,100px);
        grid-template-rows:repeat(7,auto);
        grid-gap:20px;
        grid-template-areas:'general-text general-text general-text general-text general-text general-text general-text general-text' 'contact-info contact-info contact-info contact-info . . . .' 'firstname firstname lastname lastname . . . .' 'company company company company . . . .' 'wishes wishes wishes wishes . . . .' 'message message message message . . . .';
        position:relative;
    }
     .form-order-chargestations > #chargestation-quotation.quotation .formbuilder-fields{
        display:grid;
        grid-template-columns:repeat(8,100px);
        grid-template-rows:repeat(7,auto);
        grid-gap:20px;
        grid-template-areas:'general-text general-text general-text general-text general-text general-text general-text general-text' 'contact-info contact-info contact-info contact-info . . . .' 'firstname firstname lastname lastname . . . .' 'company company company company . . . .' 'street street number unit . . . .' 'postalcode city city city . . . .' 'country country country country . . . .';
        position:relative;
    }
     .form-order-chargestations .formbuilder .input .size-auto{
        border-radius:8px;
        height:56px;
        padding-left:20px;
        width:100%;
    }
     .form-order-chargestations .formfield-row.general-text{
        grid-area:general-text;
    }
     .form-order-chargestations .formfield-row.contact-info{
        grid-area:contact-info;
    }
     .form-order-chargestations .formfield-row.firstname{
        grid-area:firstname;
    }
     .form-order-chargestations .formfield-row.lastname{
        grid-area:lastname;
    }
     .form-order-chargestations .formfield-row.company{
        grid-area:company;
    }
     .form-order-chargestations .formfield-row.street{
        grid-area:street;
    }
     .form-order-chargestations .formfield-row.number{
        grid-area:number;
    }
     .form-order-chargestations .formfield-row.unit{
        grid-area:unit;
    }
     .form-order-chargestations .formfield-row.postalcode{
        grid-area:postalcode;
    }
     .form-order-chargestations .formfield-row.city{
        grid-area:city;
    }
     .form-order-chargestations .formfield-row.country{
        grid-area:country;
    }
     .form-order-chargestations .formfield-row.wishes{
        grid-area:wishes;
    }
     .form-order-chargestations .formfield-row.message{
        grid-area:message;
    }
     .form-order-chargestations .formfield-row.extra-contact-info{
        display:block;
        height:24px;
        position:absolute;
        right:0px;
        top:229px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations .formfield-row.email{
        display:block;
        height:56px;
        position:absolute;
        right:0px;
        top:288px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations .formfield-row.phone{
        display:block;
        position:absolute;
        right:0px;
        top:375px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations .formfield-row.wishes.quotation{
        display:block;
        position:absolute;
        right:0px;
        top:458px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations .formfield-row.message.quotation{
        display:block;
        position:absolute;
        right:0px;
        top:497px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations > #chargestation-quotation .formfield-row.button-send{
        display:block;
        height:56px;
        position:absolute;
        right:0px;
        top:575px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations > #chargestation-quotation.quotation .formfield-row.button-send{
        display:block;
        height:56px;
        position:absolute;
        right:0px;
        top:630px;
        width:calc((100% - 20px) / 2);
    }
     .form-order-chargestations .formfield-row.button-request{
        display:block;
        height:56px;
        position:absolute;
        left:0px;
        top:0px;
        width:auto;
    }
     .form-order-chargestations .button-switch-contact.active,.form-order-chargestations .button-switch-quotation.active{
        margin-bottom:0px;
    }
}
 body .element-header{
    background-color:var(--color-white);
}
 .element-header .element-header-wrapper{
    align-items:center;
    display:flex;
    grid-gap:168px;
    justify-content:space-between;
    max-width:93%;
    margin:0 auto;
    min-height:120px;
    padding:0 20px;
}
 .element-header .element-header-logo{
    height:50px;
    max-width:200px;
    width:200px;
    position: relative;
    /* left: -60px; */
}
 .element-header .element-header-logo a{
    background-image:url('../../public/Logo.png');
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
    display:inline-block;
    height:550px;
    width:125%;
    position: relative;
    top: -150%;
    left: 100px;
}
 .element-header .element-header-nav{
    display:flex;
    flex-grow:1;
    grid-gap:36px;
    justify-content:right;
}
 .element-header .element-header-nav-container{
    display:block;
    flex-grow:1;
    max-height:0;
    left:0;
    overflow:hidden;
    padding:0;
    position:absolute;
    right:0;
    top:60px;
    transition:max-height 400ms;
    z-index:1000000;
}
 .element-header .element-header-nav.element-header-nav-active .element-header-nav-container{
    max-height:100%;
    padding-top:20px;
}
 .element-header .element-header-nav-list-container{
    background-color:var(--color-white);
    border-radius:20px;
    padding:30px;
    transform:translateY(-100%);
    transition:transform 400ms;
}
 .element-header .element-header-nav.element-header-nav-active .element-header-nav-list-container{
    transform:translateY(0);
}
 .element-header .element-header-nav-list-wrapper{
    max-height:calc(100vh - 160px);
    overflow:auto;
}
 .element-header ul.element-header-nav-list{
    margin:0;
    /* RESET CSS COMPONENTS */
     padding:0;
    /* RESET CSS COMPONENTS */
}
 .element-header .element-header-nav-list{
    align-items:center;
    display:flex;
    flex-direction:column-reverse;
    grid-gap:16px;
    justify-content:center;
}
 .element-header ul.element-header-nav-list > li{
    margin:0;
    /* RESET CSS COMPONENTS */
     padding:0;
    /* RESET CSS COMPONENTS */
}
 .element-header .element-header-nav-item{
    font-size:16px;
    min-height:40px;
    list-style:none;
}
 .element-header .element-header-nav-button{
    cursor:pointer;
    font-size:16px;
    flex-shrink:0;
    float:right;
    height:40px;
    line-height:40px;
    position:relative;
    width:40px;
}
.element-header .element-header-nav-button {
  display: inline-flex;  /* Use flexbox for centering text */
  justify-content: center; /* Center the text horizontally */
  align-items: center;    /* Center the text vertically */
  padding: 10px 20px;     /* Control padding for size */
  border-radius: 50%; /* Use rounded-md for rounded corners */
  text-decoration: none;  /* Remove underline from the link */
  text-align: center;     /* Ensure text is centered */
  width: 200px;           /* Set a fixed width (adjust this value) */
  max-width: 100%;        /* Ensure it doesn't overflow the container */
}

.element-header .element-header-nav-button::before {
  content: 'Go to Console';  /* The text content */
  display: inline-block;     /* Make sure the text displays in one line */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-style: normal;
  font-size: 16px;  /* Adjust font size as needed */
  font-variant: normal;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;  /* Ensure it stretches across the entire button */
  line-height: 1.5;  /* Adjust to make the text vertically centered */
  text-align: center;
  text-rendering: auto;
  white-space: nowrap;  /* Prevent text from breaking onto a new line */
  padding: 5px;  /* Add padding around the text */
  width: 100%;   /* Ensure the text spans the button */
}
.element-header .element-header-nav-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50%;  /* Fully rounded corners (circle if width=height) */
  text-decoration: none;
  text-align: center;
  width: 200px;
  max-width: 100%;
}



.element-header .element-header-nav-button {
  display: inline-block;  /* Keeps the button inline */
  padding: 10px 20px;     /* Control padding for size */
  border-radius: 5px;     /* Optional: If you want slightly rounded corners */
  text-decoration: none;  /* Remove underline from the link */
  text-align: center;     /* Ensure text is centered */
  width: 100px;           /* Set a fixed width (adjust this value) */
  max-width: 100%;        /* Ensure it doesn't overflow the container */

}



 .element-header .element-header-nav.element-header-nav-active .element-header-nav-button{
    background-color:var(--color-gray);
    color:var(--color-white);
}
 @media (min-width:620px) {
     .element-header .element-header-nav-item{
        font-size:25px;
    }
}
 @media (min-width:920px) {
     .element-header .element-header-nav-container,.element-header .element-header-nav.element-header-nav-active .element-header-nav-container{
        display:block;
        max-height:unset;
        left:unset;
        right:unset;
        padding-top:unset;
        position:relative;
        top:unset;
        transition:unset;
        z-index:unset;
    }
     .element-header .element-header-nav.element-header-nav-active .element-header-nav-container{
        overflow:unset;
    }
     .element-header .element-header-nav-list-container,.element-header .element-header-nav.element-header-nav-active .element-header-nav-list-container{
        background-color:unset;
        border-radius:unset;
        padding:unset;
        transform:unset;
        transition:unset;
    }
     .element-header .element-header-nav-list-wrapper{
        max-height:unset;
        overflow:unset;
    }
     .element-header .element-header-nav-list{
        align-items:baseline;
        flex-direction:row;
        flex-wrap:wrap;
        grid-column-gap:calc(16px + ((100vw - 620px) / 5));
        justify-content:right;
        max-height:40px;
    }
     .element-header .element-header-nav-button{
        display:none;
    }
}
 @media (min-width:1220px) {
     .element-header .element-header-nav-list{
        grid-column-gap:136px;
    }
}
 .element-language-selector .language-selector-wrapper{
    align-items:center;
    display:flex;
    grid-column-gap:.5rem;
    grid-row-gap:.5rem;
    justify-content:flex-start;
    position:relative;
}
 .element-language-selector .language-selector-flag{
    white-space:nowrap;
}
 .element-language-selector .language-selector-flag::before{
    background-image:var(--background-image);
    background-position:center;
    background-repeat:no-repeat;
    background-size:20px 20px;
    border-radius:50%;
    content:'';
    display:block;
    height:20px;
    left:14px;
    position:absolute;
    top:13.5px;
    width:20px;
}
 .element-language-selector .language-selector-label{
    color:var(--color-dark-background);
    display:none;
}
 .element-language-selector .language-selector-button{
    border:1px solid var(--color-white-light);
    border-radius:24px;
    color:var(--color-gray1);
    cursor:pointer;
    padding:10px 14px 10px 48px;
    position:relative;
}
 .element-language-selector .language-selector-button:hover{
    border:1px solid var(--color-theme);
}
 .element-language-selector .language-selector-popup{
    align-items:center;
    -webkit-backdrop-filter:blur(2px);
    backdrop-filter:blur(2px);
    background:rgba(63,63,63,0.7);
    bottom:0;
    display:none;
    justify-content:center;
    left:0;
    position:fixed;
    right:0;
    top:0;
    z-index:10000;
}
 .element-language-selector .language-selector-popup.language-selector-popup-active{
    display:flex;
}
 .element-language-selector .language-selector-popup-wrapper{
    background:var(--color-white);
    border-radius:20px;
    display:flex;
    flex-direction:column;
    max-width:440px;
    max-height:65vh;
    padding:64px 32px 32px;
    position:relative;
}
 .element-language-selector .language-selector-popup-close{
    background:var(--color-white-light);
    border-radius:50%;
    cursor:pointer;
    height:32px;
    position:absolute;
    right:24px;
    top:16px;
    width:32px;
}
 .element-language-selector .language-selector-popup-close::before{
    align-items:center;
    color:var(--color-dark-background);
    content:'\f00d';
    display:flex;
    font-family:'Font Awesome 5 Free';
    font-weight:900;
    font-style:normal;
    font-size:inherit;
    font-variant:normal;
    height:100%;
    justify-content:center;
    text-align:center;
    vertical-align:middle;
    width:100%;
}
 .element-language-selector .language-selector-popup-title{
    color:var(--color-dark-background);
    font-size:24px;
    font-weight:700;
    line-height:150%;
}
 .element-language-selector .language-selector-popup-description{
    color:var(--color-gray1);
    margin-bottom:24px;
}
 .element-language-selector .language-selector-popup-current{
    color:var(--color-theme);
    font-weight:700;
}
 .element-language-selector .language-selector-popup-list{
    color:var(--color-gray1);
    display:block;
    grid-gap:13px 30px;
    overflow:auto;
}
 .element-language-selector .language-selector-popup-option{
    border:1px solid var(--color-white-light);
    border-radius:24px;
    color:var(--color-gray1);
    cursor:pointer;
    display:inline-block;
    font-weight:600;
    height:42px;
    margin-bottom:13px;
    padding:10px 14px 10px 34px;
    position:relative;
    overflow:hidden;
    text-align:center;
    width:100%;
    width:-webkit-fill-available;
    width:-moz-available;
}
 .element-language-selector .language-selector-popup-option.language-selector-flag::before{
    top:9.5px;
}
 .element-language-selector .language-selector-popup-option.language-selector-popup-option-active{
    border:1px solid var(--color-theme);
}
 .element-language-selector .language-selector-popup-list:hover .language-selector-popup-option.language-selector-popup-option-active{
    border:1px solid var(--color-white-light);
}
 .element-language-selector .language-selector-popup-list:hover .language-selector-popup-option:hover{
    border:1px solid var(--color-theme);
}
 .element-language-selector .language-selector-popup-option a{
    bottom:0;
    color:var(--color-gray1);
    display:block;
    left:0;
    line-height:42px;
    position:absolute;
    padding-left:20px;
    right:0;
    top:0;
}
 @media (min-width:420px) {
     .element-language-selector .language-selector-popup-list{
        column-count:2;
    }
}
 @media (min-width:620px) {
     .element-language-selector .language-selector-label{
        display:block;
    }
     .element-language-selector .language-selector-popup-title{
        font-size:24px;
    }
}
 body .location-banner-form{
    display:block;
    margin-top:40px;
    padding:0px 10px 0px 10px;
}
 body .element-banner-form{
    background-color:#CCCCCC;
    background-image:url('https://grid.com/data/banners/banner-form-background.jpg');
    background-position:center;
    background-size:cover;
    border-radius:20px;
    display:block;
    margin:0px auto 0px auto;
    max-width:1130px;
    overflow:hidden;
    padding:20px;
    position:relative;
}
 body .element-banner-form:before{
    background-color:rgba(0,0,0,0.6);
    content:'';
    display:block;
    height:100%;
    left:0px;
    position:absolute;
    top:0px;
    width:100%;
}
 body .web-banner-form{
    display:grid;
    grid-template-columns:auto;
    grid-gap:20px;
}
 body .web-banner-form .banner-form-left{
    display:block;
    min-height:100px;
    position:relative;
}
 body .web-banner-form .banner-form-right{
    display:block;
    min-height:100px;
    position:relative;
}
 body .web-banner-form .banner-form-logo{
    background-image:url('https://grid.com/data/banners/banner-logo.png');
    background-position:center;
    background-size:contain;
    display:block;
    height:46px;
    left:0px;
    position:absolute;
    top:0px;
    width:78px;
}
 body .web-banner-form .banner-form-button-1{
    background-color:#0DBC81;
    border-radius:20px;
    color:#FFFFFF;
    display:block;
    height:40px;
    left:85px;
    line-height:40px;
    position:absolute;
    text-align:center;
    top:3px;
    width:148px;
}
 body .web-banner-form .banner-form-button-2{
    background-color:#0DBC81;
    border-radius:20px;
    color:#FFFFFF;
    display:block;
    line-height:20px;
    max-width:333px;
    padding:10px 16px 10px 16px;
    position:relative;
    text-align:left;
    width:90%;
}
 body .web-banner-form .group-step-2-row-2 .banner-form-button-2{
    width:280px;
}
 body .web-banner-form .banner-form-button-3{
    background-color:#0DBC81;
    border-radius:28px;
    color:#FFFFFF;
    display:block;
    height:56px;
    line-height:56px;
    position:relative;
    text-align:center;
    width:100%;
}
 body .web-banner-form .banner-form-text{
    color:#FFFFFF;
    display:block;
    font-family:Source Sans Pro,sans-serif;
    font-size:16px;
    font-weight:600;
    height:auto;
    line-height:16px;
    padding:0px 0px 0px 0px;
    position:relative;
    text-align:left;
}
 body .web-banner-form .banner-form-left .banner-form-text{
    padding:45px 0px 0px 0px;
}
 body .web-banner-form .banner-form-text .title{
    color:inherit;
    font-size:45px;
    line-height:45px;
}
 body .web-banner-form .banner-form-text .text{
    color:inherit;
    font-size:16px;
    line-height:20px;
    padding:0px 0px 0px 0px;
}
 body .web-banner-form .banner-form-text .prerequisites{
    color:inherit;
    font-size:16px;
    font-style:italic;
    line-height:20px;
    padding:30px 0px 0px 0px;
}
 body .web-banner-form .banner-form-text .prerequisites ul{
    color:inherit;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 24px;
}
 body .web-banner-form .banner-form-text .prerequisites li{
    color:inherit;
    font-size:16px;
    line-height:24px;
}
 body .web-banner-form form{
    background-color:#2D75FF;
    border-radius:20px;
    display:block;
    padding:20px 20px 20px 20px;
}
 body .web-banner-form form input[type="text"],body .web-banner-form form select.select{
    border-radius:10px;
    box-sizing:border-box;
    display:block;
    font-size:16px;
    height:40px;
    line-height:40px;
    width:100% !important;
}
 body .web-banner-form .group-step-1-row-1 .title{
    margin-top:0px;
}
 @media (min-width:600px) {
     body .location-banner-form{
        padding:0px 20px 0px 20px;
    }
     body .element-banner-form{
        padding:40px;
    }
     body .web-banner-form{
        grid-gap:40px;
        grid-template-columns:calc((100% - 40px) / 2) calc((100% - 40px) / 2);
    }
}
 @media (min-width:900px) {
     body .element-banner-form{
        padding:60px;
    }
     body .group-step-1-row-3,body .group-step-1-row-4,body .group-step-1-row-6{
        display:grid;
        grid-template-columns:calc((100% - 10px) / 2) calc((100% - 10px) / 2);
        grid-gap:10px;
    }
     body .group-step-1-row-5{
        display:grid;
        grid-template-columns:calc(100% - 140px) 60px 60px;
        grid-gap:10px;
    }
     body .web-banner-form{
        grid-gap:60px;
        grid-template-columns:calc((100% - 60px) / 2) calc((100% - 60px) / 2);
    }
}
 .element-news-items .widget-newsitem-slider{
    grid-row-gap:60px;
}
 .element-news-items .newsitem-tile{
    height:100%;
}
 .element-news-items .newsitem-tile .image{
    background-color:var(--color-gray-light);
}
 .element-news-items .newsitem-tile .date{
    font-size:80%;
    line-height:100%;
    margin-top:20px;
    text-align:right;
}
 .element-news-items .newsitem-tile .title{
    font-size:125%;
    line-height:110%;
    margin-top:20px;
    text-align:left;
}
 .element-news-items .newsitem-tile .summary{
    color:var(--color-black-light);
    margin-bottom:50px;
    margin-top:20px;
}
 .element-news-items .newsitem-tile .buttons{
    text-align:center;
    position:absolute;
    bottom:0;
}
 .element-news-items .newsitem-tile .buttons a::before{
    margin:0;
}
 .element-news-items .newsitem-title .button-arrow{
    display:inline-block;
}
 .element-news-items .newsitem-tile .button-arrow::before{
    background:var(--color-theme);
    border-radius:50%;
    content:'';
    color:var(--color-white);
    display:inline-block;
    font-family:'Font Awesome 5 Free';
    font-size:18px;
    font-style:normal;
    font-variant:normal;
    font-weight:normal;
    height:40px;
    line-height:40px;
    margin-right:0;
    text-align:center;
    width:40px;
}
 .element-releases .web-cell{
    border-bottom:1px solid #F0F0F0;
    margin-bottom:30px;
}
 .element-releases .web-cell:last-child{
    border-bottom:none;
}
 .element-releases .release-tile{
    margin-bottom:50px;
}
 .release-tile.release-image .image{
    margin-bottom:10px;
}
 .release-tile .date{
    color:#7C7C7C;
    font-size:18px;
    font-weight:400;
    margin-bottom:10px;
}
 .release-tile .title{
    color:#000000;
    font-size:24px;
    font-weight:700;
    margin-top:unset;
}
 .release-tile .text{
    color:#7C7C7C;
    font-size:18px;
    font-weight:400;
    line-height:22px;
    margin-bottom:10px;
}
 .release-tile .tags{
    align-items:center;
    display:flex;
    gap:10px;
    justify-content:left;
    overflow-y:scroll;
}
 .release-tile .tags .tag{
    background:rgba(13,188,129,0.06);
    border:1px solid #0DBC81;
    border-radius:20px;
    color:#0DBC81;
    height:36px;
    line-height:20px;
    padding:8px 22px;
}
 @media(min-width:400px) {
     .release-tile.release-image .image{
        height:258px;
    }
}
 @media(min-width:920px) {
     .element-releases .release-tile.release-image{
        display:grid;
        grid-template-columns:180px auto;
        grid-template-rows:repeat(4,auto);
        grid-gap:40px;
        grid-template-areas:'date image' 'date title' 'date text' 'date tags';
    }
     .element-releases .release-tile{
        display:grid;
        grid-template-columns:180px auto;
        grid-template-rows:repeat(3,auto);
        grid-gap:40px;
        grid-template-areas:'date title' 'date text' 'date tags';
    }
     .release-tile .date{
        grid-area:date;
        margin-bottom:unset;
    }
     .release-tile .title{
        grid-area:title;
        margin-bottom:unset;
    }
     .release-tile .text{
        grid-area:text;
        margin-bottom:unset;
    }
     .release-tile .tags{
        grid-area:tags;
    }
}
 .element-reviews .web-review{
    background-color:var(--color-theme);
    border:var(--color-gray-light) solid 1px;
    border-radius:10px;
    height:230px;
    padding:10px;
    width:calc(100% - 1px);
}
 .element-reviews .review-author,.element-reviews .review-score{
    padding-left:58px;
}
 .element-reviews .review-author{
    height:45px;
}
 .element-reviews .review-author-image{
    background-color:var(--color-theme2);
    border-radius:50%;
    height:48px;
    left:10px;
    line-height:48px;
    position:absolute;
    text-align:center;
    top:10px;
    width:48px;
}
 .element-reviews .font-title{
    color:var(--color-white);
}
 .element-reviews .review-content{
    height:100px;
}
 .element-reviews .review-google-logo{
    background-image:url(https://grid.com/data/template/header-review-icon.png);
    background-repeat:no-repeat;
    background-size:30px 30px;
    bottom:15px;
    height:30px;
    line-height:30px;
    position:fixed;
    right:15px;
    width:30px;
}
 .element-reviews .review-description{
    color:var(--color-white);
    padding-left:28px;
}
 .element-reviews .review-link a{
    bottom:15px;
    color:var(--color-white);
    left:15px;
    position:fixed;
    width:100px;
}
 .element-reviews .review-rating{
    margin-right:10px;
}
 .element-reviews .review-rating::after,.element-reviews .review-rating::before{
    color:var(--color-theme2);
    content:'\f005';
    cursor:pointer;
    display:inline;
    font-family:'Font Awesome 5 Free';
    font-size:inherit;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    line-height:inherit;
    text-rendering:auto;
}
 .element-reviews .rating-2 .review-rating::before{
    content:'\f005 \f005';
}
 .element-reviews .rating-3 .review-rating::before{
    content:'\f005 \f005 \f005';
}
 .element-reviews .rating-4 .review-rating::before{
    content:'\f005 \f005 \f005 \f005';
}
 .element-reviews .rating-5 .review-rating::before{
    content:'\f005 \f005 \f005 \f005 \f005';
}
 .element-reviews .review-rating::after{
    color:var(--color-black-light);
    content:'\f005 \f005 \f005 \f005';
}
 .element-reviews .rating-2 .review-rating::after{
    content:'\f005 \f005 \f005';
}
 .element-reviews .rating-3 .review-rating::after{
    content:'\f005 \f005';
}
 .element-reviews .rating-4 .review-rating::after{
    content:'\f005';
}
 .element-reviews .rating-5 .review-rating::after{
    content:'';
}
 .element-reviews .review-score{
    color:var(--color-white);
    font-size:12px
}
 .element-reviews .owl-carousel.owl-dots .owl-dots{
    left:calc(25%);
    width:calc(50%);
}
 @media (min-width:1220px) {
     .element-reviews .owl-carousel.owl-nav .owl-nav{
        bottom:-50px;
        left:50px;
        right:50px;
        width:93%;
    }
}
 body .element-search-bar-fuel{
    box-sizing:border-box;
    display:block;
    height:48px;
    margin:0px;
    overflow:visible;
    padding:0px 10px 0px 10px;
    position:relative;
    width:100%;
}
 .element-search-bar-fuel .search-bar{
    display:block;
    height:48px;
    padding:0px 0px 0px 0px;
    position:relative;
    width:100%;
}
 .element-search-bar-fuel .search-bar-grid{
    background:#FFFFFF;
    box-shadow:0 2px 5px rgba(0,0,0,.2);
    border-radius:24px;
    color:#989898;
    display:block;
    height:48px;
    min-width:80px;
    padding:0px;
    position:relative;
    width:100%;
}
 .element-search-bar-fuel .input-destination{
    background:transparent;
    appearance:none;
    border:none !important;
    border-radius:24px 0px 0px 24px;
    font-weight:normal;
    height:48px;
    line-height:48px;
    outline:none;
    padding:0px 0px 0px 20px;
    width:100%;
}
.element-search-bar-fuel .filter-buttons .input-button {
    background-color: var(--color-theme);
    background-image: url('https://grid.com/data/images/search.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;  /* Use a percentage to scale image inside the button */
    border-radius: 50%;
    height: 34px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 34px;
}

@media (max-width: 623px) {
    .element-search-bar-fuel .filter-buttons .input-button {
        background-size: 5px;  /* Make the background image smaller for smaller screens */
        height: 28px;           /* Optionally adjust button size for smaller screens */
        width: 28px;            /* Adjust width to match */
    }
}

@media (max-width: 480px) {
    .element-search-bar-fuel .filter-buttons .input-button {
        background-size: 18px;  /* Further adjust size for very small screens */
        height: 24px;           /* Adjust the button size */
        width: 24px;            /* Adjust the width to fit the new size */
    }
}

 @media(min-width:480px) {
     body .element-search-bar-fuel{
        padding:0px 20px 0px 20px;
    }
}
 @media(min-width:768px) {
     body .element-search-bar-fuel{
        padding:0px 0px 0px 0px;
    }
}
 body .element-search-bar{
    box-sizing:border-box;
    display:block;
    height:48px;
    margin:0px;
    overflow:visible;
    padding:0px 10px 0px 10px;
    position:relative;
    width:100%;
}
 .element-search-bar .search-bar{
    display:block;
    height:48px;
    padding:0px 0px 0px 0px;
    position:relative;
    width:125%;
}
 /* Default styling for search bar grid */
.element-search-bar .search-bar-grid {
    background: #FFFFFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    border-radius: 24px;
    color: #989898;
    display: flex;
    height: 70px;
    min-width: 80px;
    padding: 0px;
    position: relative;
    width: 100%;
    flex-wrap: nowrap;  /* Prevent wrapping */
    justify-content: space-between;  /* Space out items */
    box-sizing: border-box;
    overflow: hidden; /* Prevent overflow */
    margin-left: -10%;
}
@media (max-width: 1439px) {
    .element-search-bar .search-bar-grid {
        /* margin-left: 0%;
        width: 80%; */
    }
}


/* Responsive adjustments for screen width below 627px */
@media (max-width: 626px) {
    .element-search-bar .search-bar-grid {
        padding: 0px 10px;  /* Add some small padding for smaller screens */
        width: 100%;   /* Ensure container stays within the viewport */
        flex-wrap: nowrap;  /* Keep items in a row */
    }

    /* Shrink the input field and dropdowns */
    .input-destination,
    .input-engineer,
    .input-available {
        padding: 8px 10px;  /* Reduce padding */
        font-size: 12px;     /* Shrink font size */
        height: 32px;        /* Reduce height */
        width: 100%;         /* Ensure inputs are 100% wide */
        box-sizing: border-box;  /* Include padding in width calculation */
    }

    /* Adjust the dropdown's font size and height */
    .input-engineer {
        font-size: 12px;
        height: 32px;
    }

    /* Adjust the checkbox switch */
    .input-available-switch {
        font-size: 12px;
    }

    /* Reduce the size of the search button */
    .input-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;  /* Shrink size */
        height: 28px; /* Shrink height */
        font-size: 14px; /* Shrink font size */
    }

    /* Make borders thinner */
    .border-one,
    .border-two {
        height: 60px; /* Adjust height of borders */
    }

    /* Reduce margins and paddings for grid elements */
    .filter-destination,
    .filter-engineer,
    .filter-available,
    .filter-buttons {
        padding: 0px 4px;  /* Reduce padding for smaller screens */
    }

    /* Allow elements to shrink and adjust based on available space */
    .element-search-bar .search-bar-grid > * {
        flex: 1 1 auto;  /* Allow items to shrink/grow based on space */
        max-width: 100%; /* Prevent overflow */
        display: flex;   /* Flexbox behavior */
        justify-content: center; /* Align horizontally */
        align-items: center; /* Align vertically */
        padding: 0px 4px;  /* Reduced padding */
    }
}

/* Further adjustments for smaller screens (below 442px) */
@media (max-width: 442px) {
    .input-destination,
    .input-engineer,
    .input-available {
        font-size: 9px;  /* Further shrink font size */
        height: 26px;     /* Reduce height further */
        padding: 4px 6px; /* Adjust padding */
    }

    .input-button {
        width: 20px;  /* Further shrink size */
        height: 20px; /* Further shrink height */
        font-size: 10px; /* Shrink font size for icon */
    }

    .element-search-bar .search-bar-grid {
        padding: 0px 4px;  /* Further reduce padding */
    }

    .border-one,
    .border-two {
        height: 26px; /* Reduce border height further */
    }

    /* Allow elements to shrink down further to fit in the grid */
    .element-search-bar .search-bar-grid > * {
        font-size: 9px;  /* Further reduce font size */
        transform: scale(0.8); /* Shrink elements proportionally */
        padding: 0px 4px;  /* Reduced padding */
    }
}


 .element-search-bar .search-bar-grid > *{
    position:relative;
    padding-top: 1%;
}
 .element-search-bar .search-bar-grid > .border-one,.element-search-bar .search-bar-grid > .filter-chargingspeed,.element-search-bar .search-bar-grid > .border-two,.element-search-bar .search-bar-grid > .filter-available{
    display:none;
}
 .element-search-bar .filter-destination{
    flex:1 0 234px;
    padding-top: 1%;
}
 .element-search-bar .input-destination{
    background:transparent;
    appearance:none;
    border:none !important;
    border-radius:24px 0px 0px 24px;
    font-weight:normal;
    height:48px;
    line-height:48px;
    outline:none;
    padding:0px 0px 0px 20px;
    width:100%;
}
 .element-search-bar .search-bar-grid > .border-one,.element-search-bar .search-bar-grid > .border-two{
    margin:0 20px;
}
 .element-search-bar .border-one::after,.element-search-bar .border-two::after{
    background:#CCCCCC;
    display:block;
    content:'';
    height:65px;
    left:0px;
    position:absolute;
    top:8px;
    width:1px;
}
 .element-search-bar .label-chargingspeed{
    cursor:pointer;
    height:20px;
    margin:0px 0px 0px 0px;
    text-align:left;
}
 .element-search-bar .input-chargingspeed{
    appearance:none;
    background:transparent;
    border:none !important;
    border-radius:0px;
    cursor:pointer;
    font-weight:normal;
    height:20px;
    line-height:20px;
    outline:none;
    text-align:left;
    width:100%;
}
 .element-search-bar .filter-chargingspeed-popup{
    background:#FFFFFF;
    border-radius:24px;
    color:inherit;
    display:none;
    height:128px;
    font-size:16px;
    padding:20px 20px 20px 20px;
    position:absolute;
    top:58px;
    width:440px;
}
 .element-search-bar .active > .filter-chargingspeed-popup{
    box-shadow:0 2px 5px rgba(0,0,0,.2);
    display:block;
}
 .element-search-bar .range-slider{
    height:30px;
    margin-top:5px;
    position:relative;
    width:100%;
}
 .element-search-bar .power-values{
    font-weight:normal;
}
 .element-search-bar .range-slider input[type="range"]{
    background:var(--color-theme);
    background:linear-gradient(90deg,#F5F5F5 0%,var(--color-theme) 0%,var(--color-theme) 100%,#F5F5F5 100%);
    border:none;
    border-radius:15px;
    height:30px;
    left:0px;
    margin:0;
    outline:none;
    overflow:hidden;
    padding:0px 0px 0px 0px;
    pointer-events:none;
    position:absolute;
    top:0px;
    -webkit-appearance:none;
    width:400px;
}
 .element-search-bar .range-slider input[type="range"] + input[type="range"]{
    background:transparent;
}
 .element-search-bar .range-slider input[type="range"]::-moz-range-thumb{
    appearance:none;
    background-image:url('https://grid.com/data/images/slider-left.png');
    border:10px;
    box-sizing:border-box;
    cursor:pointer;
    height:30px;
    left:0px;
    -ms-appearance:none;
    -moz-appearance:none;
    pointer-events:all;
    outline:none;
    position:relative;
    -webkit-appearance:none;
    width:30px;
    z-index:10;
}
 .element-search-bar .range-slider input[type="range"] + input[type="range"]::-moz-range-thumb{
    background-image:url('https://grid.com/data/images/slider-right.png');
}
 .element-search-bar .range-slider input[type="range"]::-ms-thumb{
    appearance:none;
    background-image:url('https://grid.com/data/images/slider-left.png');
    border:10px;
    box-sizing:border-box;
    cursor:pointer;
    height:30px;
    left:0px;
    -ms-appearance:none;
    -moz-appearance:none;
    pointer-events:all;
    outline:none;
    position:relative;
    -webkit-appearance:none;
    width:30px;
    z-index:10;
}
 .element-search-bar .range-slider input[type="range"] + input[type="range"]::-ms-thumb{
    background-image:url('https://grid.com/data/images/slider-right.png');
}
 .element-search-bar .range-slider input[type="range"]::-webkit-slider-thumb{
    appearance:none;
    background-image:url('https://grid.com/data/images/slider-left.png');
    border:10px;
    box-sizing:border-box;
    cursor:pointer;
    height:30px;
    left:0px;
    -ms-appearance:none;
    -moz-appearance:none;
    pointer-events:all;
    outline:none;
    position:relative;
    -webkit-appearance:none;
    width:30px;
    z-index:10;
}
 .element-search-bar .range-slider input[type="range"] + input[type="range"]::-webkit-slider-thumb{
    background-image:url('https://grid.com/data/images/slider-right.png');
}
 .element-search-bar .label-available{
    height:20px;
    margin:0px 0px 0px 0px;
    text-align:left;
}
 .element-search-bar .input-available{
    display:flex;
    height:20px;
    margin:0px 0px 0px 0px;
    position:relative;
    text-align:left;
}
 .element-search-bar .input-available input{
    appearance:none;
    height:0 !important;
    opacity:0;
    width:0;
}
 .element-search-bar .input-available input + .input-available-switch{
    background-image:url('https://grid.com/data/images/toggle-off.png');
    background-position:left center;
    background-repeat:no-repeat;
    cursor:pointer;
    display:inline-block;
    font-weight:normal;
    height:27px;
    padding:0px 0px 0px 48px;
    width:35px;
}
 .element-search-bar .input-available input:checked + .input-available-switch{
    background-image:url('https://grid.com/data/images/toggle-on.png');
}
 .element-search-bar .input-available input + .input-available-switch::after{
    content:attr(data-off);
}
 .element-search-bar .input-available input:checked + .input-available-switch::after{
    content:attr(data-on);
}
 .element-search-bar .filter-buttons{
    flex:0 0 50px;
}
.element-search-bar .filter-buttons .input-button {
    background: url('https://grid.com/data/images/search.png') center / 20px no-repeat, 
                linear-gradient(to bottom left, #f59e0b, #f97316, #ef4444); /* Gradient colors */
    background-position: center;
    background-size: 20px;
    border-radius: 50%;
    height: 34px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 18px;
    width: 34px;
}

 @media(min-width:480px) {
     body .element-search-bar{
        padding:0px 20px 0px 20px;
    }
}
 @media(min-width:800px) {
     body .element-search-bar{
        padding:0px 0px 0px 0px;
    }
     .element-search-bar .search-bar-grid > .border-one,.element-search-bar .search-bar-grid > .filter-chargingspeed,.element-search-bar .search-bar-grid > .border-two,.element-search-bar .search-bar-grid > .filter-available{
        display:block;
    }
}
 .web-element.element-share .web-share::after{
    clear:both;
    content:'';
    display:block;
    float:none;
}
 .element-share .share{
    border-radius:5px;
    clear:none;
    display:inline-block;
    float:left;
    height:40px;
    line-height:40px;
    margin-right:5px;
    overflow:hidden;
    position:relative;
    width:40px;
}
 .element-share .share::before{
    color:inherit;
    content:'\f001';
    cursor:pointer;
    display:block;
    font-family:'Font Awesome 5 Free';
    font-size:24px;
    font-style:normal;
    font-variant:normal;
    font-weight:900;
    height:40px;
    left:0px;
    line-height:40px;
    position:absolute;
    text-align:center;
    text-rendering:auto;
    top:0px;
    width:40px;
}
 .element-share .share-contact::before{
    background-color:#E34133;
    color:#FFFFFF;
    content:'\f879';
}
 .element-share .share-facebook::before{
    background-color:#166FE5;
    color:#FFFFFF;
    content:'\f39e';
    font-family:'Font Awesome 5 Brands';
}
 .element-share .share-mail::before{
    background-color:#E34133;
    color:#FFFFFF;
    content:'\f0e0';
}
 .element-share .share-phone::before{
    background-color:#E34133;
    color:#FFFFFF;
    content:'\f879';
}
 .element-share .share-tumblr::before{
    background-color:#1C3562;
    color:#FFFFFF;
    content:'\f173';
    font-family:'Font Awesome 5 Brands';
}
 .element-share .share-twitter::before{
    background-color:#1D9BF0;
    color:#FFFFFF;
    content:'\f099';
    font-family:'Font Awesome 5 Brands';
}
 .element-share .share-whatsapp::before{
    background-color:#00A884;
    color:#FFFFFF;
    content:'\f232';
    font-family:'Font Awesome 5 Brands';
}
 body .element-ticket-dashboard{
    padding:22px;
}
 .element-ticket-dashboard .chatbox.active{
    height:500px;
    position:relative;
    width:100%;
}
 .element-ticket-dashboard .dashboard-title{
    border:none;
    color:var(--color-theme);
    font-size:30px;
    font-weight:bold;
    margin-bottom:20px;
    margin-top:8px;
}
 .element-ticket-dashboard .chatbox-body{
    display:flex;
    flex-direction:column-reverse;
    overflow:auto;
}
 @media (min-width:991px) {
     .element-ticket-dashboard .chatbox.active{
        width:75%;
    }
}
 body .location-banner-form{
    display:block;
    margin-top:40px;
    padding:0px 10px 0px 10px;
}
 body .element-banner-form{
    background-color:#CCCCCC;
    background-image:url('https://grid.com/data/banners/banner-form-background.jpg');
    background-position:center;
    background-size:cover;
    border-radius:20px;
    display:block;
    margin:0px auto 0px auto;
    max-width:1130px;
    overflow:hidden;
    padding:10px;
    position:relative;
}
 body .element-banner-form:before{
    background-color:rgba(0,0,0,0.6);
    content:'';
    display:block;
    height:100%;
    left:0px;
    position:absolute;
    top:0px;
    width:100%;
}
 body .element-banner-form .banner-form{
    display:grid;
    grid-template-columns:auto;
}
 body .element-banner-form .banner-form{
    grid-template-areas:"header" "body" "footer";
    grid-template-columns:100%;
    grid-gap:10px;
    text-align:left;
}
 body .element-banner-form .banner-form .banner-form-header{
    color:#FFFFFF;
    grid-area:header;
}
 body .element-banner-form .banner-form a,body .element-banner-form .banner-form ol,body .element-banner-form .banner-form li{
    color:inherit;
}
 body .element-banner-form .banner-form .banner-form-body{
    background-color:#256BFF;
    border-radius:20px;
    color:#FFFFFF;
    grid-area:body;
    padding:10px;
}
 body .element-banner-form .banner-form .banner-form-footer{
    color:#FFFFFF;
    grid-area:footer;
}
 body .element-banner-form .banner-form .grid-auto-50-50{
    display:grid;
    grid-gap:10px;
    grid-template-columns:calc(100% - 120px) 50px 50px;
}
 body .element-banner-form .banner-form .grid-auto-50-75{
    display:grid;
    grid-gap:10px;
    grid-template-columns:calc(100% - 145px) 50px 75px;
}
 body .element-banner-form .banner-form .grid-auto-50-100{
    display:grid;
    grid-gap:10px;
    grid-template-columns:calc(100% - 170px) 50px 100px;
}
 body .element-banner-form .banner-form .grid-auto-50-150{
    display:grid;
    grid-gap:10px;
    grid-template-columns:calc(100% - 220px) 50px 150px;
}
 body .element-banner-form .banner-form .grid-150-auto{
    display:grid;
    grid-gap:10px;
    grid-template-columns:150px calc(100% - 160px);
}
 body .element-banner-form .banner-form-text + .banner-form-input,body .element-banner-form .banner-form-input + .banner-form-input,body .element-banner-form .banner-form-input + .banner-form-button,body .element-banner-form .banner-form-text + .banner-form-textarea,body .element-banner-form .banner-form-textarea + .banner-form-textarea,body .element-banner-form .banner-form-textarea + .banner-form-button{
    margin-top:10px;
}
 body .element-banner-form .banner-form button{
    background-color:#0DBC81;
    border:#0DBC81 solid 1px;
    border-radius:5px;
    box-sizing:border-box;
    color:#FFFFFF;
    display:block;
    line-height:30px;
    padding:0px 3px 0px 3px;
    width:100%;
}
 body .element-banner-form .banner-form input{
    background-color:#FFFFFF;
    border:#FFFFFF solid 1px;
    border-radius:5px;
    box-sizing:border-box;
    color:#000000;
    display:block;
    line-height:30px;
    padding:0px 8px 0px 8px;
    width:100%;
}
 body .element-banner-form .banner-form textarea{
    background-color:#FFFFFF;
    border:#FFFFFF solid 1px;
    border-radius:5px;
    box-sizing:border-box;
    color:#000000;
    display:block;
    height:80px;
    line-height:30px;
    padding:0px 8px 0px 8px;
    resize:vertical;
    width:100%;
}
 @media (min-width:600px) {
     body .element-banner-form,body .element-banner-form .banner-form .banner-form-body{
        padding:20px;
    }
}
 @media (min-width:900px) {
     body .element-banner-form,body .element-banner-form .banner-form .banner-form-body{
        padding:30px;
    }
     body .element-banner-form .banner-form{
        grid-template-areas:"header body" "footer body";
        grid-template-columns:50% 50%;
    }
}
 body .wysiwyg-locationpages h2{
    color:#0DBC81;
    font-size:32px;
    font-weight:700;
    line-height:36px;
    margin-bottom:0;
    margin-top:20px;
    padding-bottom:20px;
    /* padding-left:40px;
    */
     padding-right:40px;
}
 body .wysiwyg-locationpages,body .wysiwyg-locationpage{
    max-width:1130px;
    font-family:'Inter';
}
 body .wysiwyg-locationpages h3{
    color:#0DBC81;
    font-size:24px;
    font-weight:600;
    line-height:30px;
    margin-bottom:10px;
    margin-top:40px;
    /* padding-left:40px;
    */
     padding-right:40px;
}
 body .wysiwyg-locationpages p{
    align-items:flex-start;
    color:#7C7C7C;
    display:block;
    flex-direction:column;
    font-size:20px;
    font-weight:400;
    justify-content:flex-start;
    line-height:150%;
    max-width:100%;
    margin-bottom:0;
    margin-top:0;
    padding-bottom:20px;
    padding-left:0;
    padding-right:40px;
    text-align:left;
}
 body .text-block-9.color-black > p,body .text-block-7.content-summary > p{
    color:#1a1b1f !important;
    max-height:197px;
    overflow:hidden;
}
 @media screen and (max-width:479px) {
     body .wysiwyg-locationpages h2,body .wysiwyg-locationpages h3{
        padding-left:10px;
        padding-right:10px
    }
     body .wysiwyg-locationpages p{
        font-size:16px;
        padding-left:10px;
        padding-right:10px;
    }
}
 @media screen and (max-width:767px) {
     .paragraph-7.white.h{
        font-size:14px;
        max-width:100%;
    }
}
 @media screen and (max-width:991px) {
     body .wysiwyg-locationpages h2,body .wysiwyg-locationpages h3{
        padding-right:20px;
        padding-left:20px;
    }
     body .wysiwyg-locationpages p{
        text-align:left;
        padding-left:20px;
        padding-right:20px;
        font-size:16px;
    }
}
 body .blog-content-image-container{
    margin:15px 0px;
}
 body .blog-content-image-container.image-container-300{
    max-width:var(--max-width-300);
    max-height:var(--max-height-300);
}
 body .blog-content-image{
    background-size:contain;
}
 @media (min-width:620px) {
     body .web-blog .page-header.background-image-600{
        background-image:var(--background-image-600);
    }
     body .blog-content-image-container.image-container-600{
        max-width:var(--max-width-600);
        max-height:var(--max-height-600);
    }
}
 @media (min-width:920px) {
     body .web-blog .page-header.background-image-900{
        background-image:var(--background-image-900);
    }
     body .blog-content-image-container.image-container-900{
        max-width:var(--max-width-900);
        max-height:var(--max-height-900);
    }
}
 @media (min-width:1220px) {
     body .web-blog .page-header.background-image-1200{
        background-image:var(--background-image-1200);
    }
     body .blog-content-image-container.image-container-1200{
        max-width:var(--max-width-1200);
        max-height:var(--max-height-1200);
    }
}
 .web-energydashboardpage .web-energydashboardpage-image{
    bottom:0;
    display:none;
    grid-template-areas:"link-1 link-4 link-5 link-6 link-13 link-14 link-15" "link-2 link-7 link-7 link-7 link-13 link-14 link-15" "link-2 link-7 link-7 link-7 link-16 link-17 link-18" "link-2 link-8 link-9 link-10 link-19 link-20 link-21" "link-3 link-11 link-11 link-12 link-22 link-23 link-23" ". . . . . link-24 link-24";
    grid-template-columns:25% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    grid-template-rows:13.4114583% 9.765625% 23.177% 23.3724% 25.45573% 4.8177%;
    left:0;
    padding-top:20px;
    position:absolute;
    right:0;
    top:0;
}
 .web-energydashboardpage .web-energydashboardpage-image > *{
    display:none;
}
 .web-energydashboardpage .energydashboardpage-image-link-1{
    display:block;
    grid-area:link-1;
}
 .web-energydashboardpage .energydashboardpage-image-link-2{
    display:block;
    grid-area:link-2;
}
 .web-energydashboardpage .energydashboardpage-image-link-3{
    display:block;
    grid-area:link-3;
}
 .web-energydashboardpage .energydashboardpage-image-link-4{
    display:block;
    grid-area:link-4;
}
 .web-energydashboardpage .energydashboardpage-image-link-5{
    display:block;
    grid-area:link-5;
}
 .web-energydashboardpage .energydashboardpage-image-link-6{
    display:block;
    grid-area:link-6;
}
 .web-energydashboardpage .energydashboardpage-image-link-7{
    display:block;
    grid-area:link-7;
}
 .web-energydashboardpage .energydashboardpage-image-link-8{
    display:block;
    grid-area:link-8;
}
 .web-energydashboardpage .energydashboardpage-image-link-9{
    display:block;
    grid-area:link-9;
}
 .web-energydashboardpage .energydashboardpage-image-link-10{
    display:block;
    grid-area:link-10;
}
 .web-energydashboardpage .energydashboardpage-image-link-11{
    display:block;
    grid-area:link-11;
}
 .web-energydashboardpage .energydashboardpage-image-link-12{
    display:block;
    grid-area:link-12;
}
 .web-energydashboardpage .energydashboardpage-image-link-13{
    display:block;
    grid-area:link-13;
}
 .web-energydashboardpage .energydashboardpage-image-link-14{
    display:block;
    grid-area:link-14;
}
 .web-energydashboardpage .energydashboardpage-image-link-15{
    display:block;
    grid-area:link-15;
}
 .web-energydashboardpage .energydashboardpage-image-link-16{
    display:block;
    grid-area:link-16;
}
 .web-energydashboardpage .energydashboardpage-image-link-17{
    display:block;
    grid-area:link-17;
}
 .web-energydashboardpage .energydashboardpage-image-link-18{
    display:block;
    grid-area:link-18;
}
 .web-energydashboardpage .energydashboardpage-image-link-19{
    display:block;
    grid-area:link-19;
}
 .web-energydashboardpage .energydashboardpage-image-link-20{
    display:block;
    grid-area:link-20;
}
 .web-energydashboardpage .energydashboardpage-image-link-21{
    display:block;
    grid-area:link-21;
}
 .web-energydashboardpage .energydashboardpage-image-link-22{
    display:block;
    grid-area:link-22;
}
 .web-energydashboardpage .energydashboardpage-image-link-23{
    display:block;
    grid-area:link-23;
}
 .web-energydashboardpage .energydashboardpage-image-link-24{
    display:block;
    grid-area:link-24;
}
 @keyframes energydashboardpage-image-link-animation{
    0%{
        box-shadow:inset 0 0 10px -2px transparent,0 0 10px -2px transparent;
    }
     33%{
        box-shadow:inset 0 0 10px -2px transparent,0 0 10px -2px transparent;
    }
     50%{
        box-shadow:inset 0 0 10px -2px var(--color-theme),0 0 10px -2px var(--color-theme);
    }
     67%{
        box-shadow:inset 0 0 10px -2px transparent,0 0 10px -2px transparent;
    }
     100%{
        box-shadow:inset 0 0 10px -2px transparent,0 0 10px -2px transparent;
    }
}
 .web-energydashboardpage .energydashboardpage-image-link::before,.web-energydashboardpage .energydashboardpage-image-link-active::before{
    animation-duration:6s;
    animation-iteration-count:infinite;
    animation-name:energydashboardpage-image-link-animation;
    animation-timing-function:ease-in-out;
    border-radius:4px;
    content:'';
    display:none;
    height:100%;
    width:100%;
}
 .web-energydashboardpage .energydashboardpage-image-link-active::before{
    display:block;
}
 .web-energydashboardpage .web-energydashboardpage-image:hover .energydashboardpage-image-link-active::before{
    opacity:0;
}
 .web-energydashboardpage .web-energydashboardpage-image:hover .energydashboardpage-image-link:hover::before{
    animation:none;
    box-shadow:0 0 10px -2px var(--color-theme),inset 0 0 10px -2px var(--color-theme);
    display:block;
    opacity:1;
}
 .web-energydashboardpage .web-energydashboardpage-list{
    margin:-4rem auto 30px;
    max-height:50vh;
    max-width:1440px;
    overflow:auto;
    padding:0px 20px;
}
 .web-energydashboardpage-list .energydashboardpage-list-ul{
    display:flex;
    grid-gap:10px 30px;
    flex-direction:column;
    margin:0;
    padding:0;
}
 .web-energydashboardpage-list .energydashboardpage-list-li{
    list-style:none;
    margin:0;
    padding:0;
}
 .web-energydashboardpage .web-energydashboardpage-section{
    display:grid;
    grid-gap:30px;
    grid-template-areas:"area-nav" "area-image" "area-content";
    margin:30px auto 30px;
    max-width:1440px;
    padding:0px 20px;
}
 .web-energydashboardpage-section .energydashboardpage-nav{
    display:grid;
    grid-area:area-nav;
    grid-template-areas:"area-previous area-next";
    grid-template-columns:1fr 1fr;
    height:40px;
}
 .web-energydashboardpage-section .energydashboardpage-previous{
    grid-area:area-previous;
    height:40px;
    text-align:left;
}
 .web-energydashboardpage-section .energydashboardpage-next{
    grid-area:area-next;
    height:40px;
    text-align:right;
}
 .web-energydashboardpage-section .energydashboardpage-previous a,.web-energydashboardpage-section .energydashboardpage-next a{
    color:transparent;
    display:inline-block;
    height:40px;
    line-height:40px;
    overflow:hidden;
    position:relative;
    width:40px;
}
 .web-energydashboardpage-section .energydashboardpage-next a::after,.web-energydashboardpage-section .energydashboardpage-previous a::before{
    background-color:var(--color-theme);
    border-radius:20px;
    color:var(--color-white);
    content:'\f177';
    display:block;
    font-family:'Font Awesome 5 Free';
    height:40px;
    line-height:40px;
    text-align:center;
    top:calc(50%);
    transform:translateY(-50%);
    position:absolute;
    width:40px;
}
 .web-energydashboardpage-section .energydashboardpage-next a::after{
    content:'\f178';
    right:0;
}
 .web-energydashboardpage-section .energydashboardpage-previous a::before{
    left:0;
}
 .web-energydashboardpage-section .energydashboardpage-image{
    grid-area:area-image;
}
 .web-energydashboardpage-section .energydashboardpage-image .content-image{
    background-color:#000;
    background-size:contain;
    height:100%;
    max-height:var(--background-image-height);
    max-width:var(--background-image-width);
    width:100%;
}
 .web-energydashboardpage-section .energydashboardpage-content{
    grid-area:area-content;
}
 @media (min-width:920px) {
     .web-energydashboardpage .web-energydashboardpage-image{
        display:grid;
    }
     .web-energydashboardpage .web-energydashboardpage-list{
        display:none;
        margin-top:-8rem;
        padding:0 calc(2.5rem + 20px);
    }
     .web-energydashboardpage-list .energydashboardpage-list-ul{
        flex-wrap:none;
        max-height:190px;
    }
     .web-energydashboardpage .web-energydashboardpage-section{
        display:flex;
        flex-wrap:wrap;
        padding:0px 40px;
    }
     .web-energydashboardpage-section .energydashboardpage-nav{
        flex:0 0 100%;
    }
     .web-energydashboardpage-section .energydashboardpage-previous a,.web-energydashboardpage-section .energydashboardpage-next a{
        color:var(--color-white);
        width:unset;
    }
     .web-energydashboardpage-section .energydashboardpage-previous a{
        padding-left:50px;
    }
     .web-energydashboardpage-section .energydashboardpage-next a{
        padding-right:50px;
    }
     .web-energydashboardpage-section .energydashboardpage-image{
        flex:0 0 min(calc(50% - 15px),var(--background-image-width));
    }
     .web-energydashboardpage-section .energydashboardpage-content{
        flex:1 0 calc(50% - 15px);
    }
}
