@media screen and (max-width: 478px) {
  img.image-16.arrow-image {
    left: 40%;
    top: -130px;
  }
}

@media screen and (max-width: 478px) {
  div#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adce6 {
    margin-top: 3%;
  }
}


/* Global styles - For screens up to 795px */
.image-16 {
    align-self: center;
    top: -50px;
    left: -75px;
    position: relative;
}

/* Styles for screens above 795px */
@media screen and (min-width: 796px) {
    .image-16 {
        /* Adjust the values for larger screens */
        align-self: center; /* Keep the alignment if needed */
        top: -26px;           /* Modify the 'top' value as needed */
        left: 0px;          /* Modify the 'left' value as needed */
        position: relative; /* Keep the relative positioning or change if needed */
    }
}
  /* Styles for screens less than 792px */
@media screen and (max-width: 791px) {
    .image-16 {
        /* Adjust the values for smaller screens */
        align-self: center; /* Keep the alignment if needed */
        top: -50px;          /* Modify the 'top' value as needed */
        left: -50px;         /* Modify the 'left' value as needed */
        position: relative;  /* Keep the relative positioning */
    }
}
@media screen and (max-width: 757px) {
    .image-16 {
        /* Adjust the values for smaller screens */
        align-self: center; /* Keep the alignment if needed */
        top: -50px;          /* Modify the 'top' value as needed */
        left: -10px;         /* Modify the 'left' value as needed */
        position: relative;  /* Keep the relative positioning */
    }
}

.div-block-16.image.zoek {
  background: url('../../public/desk_assure_laptop.gif') center center no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  max-width: 545px;
  max-height: 550px;
  padding-bottom: 0;
  background-position: 50%;
  background-size: 65%;
  background-color: #C1F11D;
}


.unique-div-voorkeur {
  background: url('../../public/image_lap_4.gif') center / 751px 563px repeat-x;
  background-position: center; /* Center the background */
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 563px;
  max-height: 751px;
  background-position: 50%;
  background-size: 65%;
  background-color: #C1F11D;
  /* background-size: 751px 563px; Size of the background image */
  height: 105.5%; /* Increased height of the div */
  border-radius: 15px; /* Adjust the value for more or less rounding */
  overflow: hidden; /* Prevents overflow if the content exceeds the rounded corners */
}


/* .div-block-16.image.app {
  background: url('https://grid.com/web/images/--_EN.webp') center center / cover no-repeat, 
              linear-gradient(to bottom left, #f59e0b, #f97316, #ef4444);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 700px;
  margin-bottom: 5px;
  padding-bottom: 0;
  display: block;
  height: 105%;
} */
.unique-div-background {
    background:#C1F11D; /* Gradient background */
    background-repeat: no-repeat; /* Prevents background from repeating */
    background-position: center; /* Centers the background image */
    background-size: cover; /* Ensures the image covers the div */
    max-height: 700px; /* Maximum height of the div */
    margin-bottom: 5px; /* Margin below the div */
    padding-bottom: 0; /* No padding at the bottom */
    display: block; /* Ensure it's a block element */
    height: 105%; /* Height of the div */
    border-radius: 15px; /* Rounded corners */
    overflow: hidden; /* Prevents overflow if the image exceeds the rounded corners */
    position: relative; /* Allows positioning of the child elements if needed */
}

.overlay-image {
    position: absolute; /* Positioning to overlay the image */
    top: 20px; /* Adjust position as needed */
    left: 50%; /* Center the image horizontally */
    transform: translateX(-50%); /* Adjust position to center */
    /* width: auto; Adjust width as necessary */
    height: auto; /* Maintain aspect ratio */
    border-radius: 15px; /* Match rounded corners */
    height: 90%;
}



:root {
  --0dbc81: #1a1b1f;
  --medium-sea-green: #0dbc81;
  --white-smoke: #f0f0f0;
  --grey: #989898;
  --royal-blue: #2573ff;
  --medium-aquamarine: #86ddbf;
  --medium-sea-green-2: #e9faf4;
  --green: #32343a;
  --card-background: #f0f0f0;
}


.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-form-formradioinput--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.w-embed-youtubevideo {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
  background-position: 50%;
  background-size: cover;
  width: 100%;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  font-family: Source Sans Pro, sans-serif;
  font-size: 1rem;
  line-height: 2rem;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

p {
  color: #858383;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 150%;
}

a {
  color: var(--0dbc81);
  border: 1px #000;
  text-decoration: none;
}

li {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
}

strong {
  color: var(--0dbc81);
  padding-left: 0;
  font-weight: 700;
}

.section-grid {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  display: flex;
}

.container-grid-7 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 0;
  padding: 10px 57px 57px;
  display: flex;
}

.image {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  border-radius: 20px;
  max-width: 1130%;
  max-height: 500px;
}

.div-block {
  background-image: url('https://grid.com/web/images/AdobeStock_568284106_Preview.jpeg');
  background-position: 50% 25%;
  background-size: cover;
  border-radius: 13px;
  width: 100vw;
  min-width: 100%;
  max-width: 1130px;
  height: 300px;
}

.grid {
  flex-direction: row;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  max-width: 950px;
  padding-bottom: 0;
  display: flex;
}

.container-2 {
  justify-content: center;
  max-width: 1130px;
  margin-top: -150px;
  display: flex;
}

.div-block-2 {
  opacity: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 33px;
  box-shadow: 1px 3px 12px -5px #00000061;
}

.heading {
  font-size: 28px;
}

.h3 {
  border-bottom: 0px none var(--medium-sea-green);
  color: #1e1e1e;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 28px;
  font-weight: 700;
}

.h3.on-green {
  color: var(--0dbc81);
  font-size: 24px;
  font-weight: 900;
  line-height: 150%;
}

.h3.green {
  color: var(--medium-sea-green);
}

.section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.container {
  object-fit: contain;
  max-width: 1130px;
  margin-top: 0;
  margin-bottom: 49px;
  padding-left: 20px;
}

.container-3 {
  flex-direction: column;
  justify-content: center;
  max-width: 1130px;
  display: flex;
}

.grid-2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  place-items: stretch center;
  max-width: 1130px;
  margin: 0 auto auto;
  display: grid;
}

.grid-2.left-block.padding {
  border: 1.5px solid var(--medium-sea-green);
  background-color: #0000;
  border-radius: 20px;
  padding: 40px;
}

.grid-2.left-block.padding.grey {
  background-color: #0000;
  border-color: #0000;
}

.grid-3 {
  grid-column-gap: 70px;
  background-color: #0000;
  border-radius: 20px;
  justify-content: center;
  place-items: center;
  max-width: 1130px;
  padding-left: 20px;
}

.grid-3.green {
  background-color: var(--medium-sea-green);
  margin-top: 73px;
  padding: 37px 71px 71px;
}

.grid-3.black {
  background-color: #0000;
}

.section-2 {
  min-height: 40vh;
  padding: 60px;
}

.section-2.black {
  background-color: #0000;
  background-image: url('https://grid.com/web/images/GRID-map3test2.webp');
  background-size: cover;
  min-height: 50vh;
  padding-top: 40px;
}

.section-2.black.clear {
  background-image: none;
  min-height: 37vh;
  padding-bottom: 0px;
  font-family: Source Sans Pro, sans-serif;
}
h2.title.zwart.Download {
    /* background: linear-gradient(to right, #5271ff, #cb6ce6); Gradient from dark teal to cyan-blue and back to dark teal */
    /* -webkit-background-clip: text; For text color gradient */
    color: #49527b; /* Make the text color transparent so the gradient shows */
}


h3.heading-5.red.left.solutions {
    color: #49527b;
}

.section-2.green {
  padding-top: 60px;
}

.section-2.blog {
  background-image: url('https://grid.com/web/images/AdobeStock_274243150-kopie.jpeg');
  background-position: 50%;
  background-size: cover;
  min-height: 50vh;
  padding-top: 40px;
}

.section-2.no-space {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}

.div-block-3 {
  align-self: center;
  min-width: 0;
  padding: 2px;
}

.div-block-3.green {
  background-color: var(--medium-sea-green);
  border-radius: 20px;
  padding: 31px;
}

.div-block-3.phone {
  min-width: 50%;
}

.image-2 {
  border-radius: 20px;
  min-width: 450px;
}

.navbar {
  background-color: #fff;
}

.brand {
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  display: flex;
}

.nav-menu {
  justify-content: center;
  max-width: 1130px;
  display: flex;
}

.navbar-logo-left {
  background-color: #fff;
  align-items: center;
  display: block;
  box-shadow: 0 0 12px #0003;
}

.navbar-logo-left-container {
  z-index: 5;
  background-color: #0000;
  width: 1030px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1140px;
  margin-bottom: 0;
  padding: 10px 20px;
}

.container-4 {
  width: 100%;
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1130px;
  display: flex;
}

.nav-menu-two {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-link {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: #1a1b1fbf;
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: #1a1b1fbf;
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-divider {
  background-color: #e4ebf3;
  width: 1px;
  height: 22px;
  margin-left: 15px;
  margin-right: 15px;
}

.nav-link-accent {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: #1a1b1fbf;
}

.mobile-margin-top-10 {
  flex: 0 auto;
  align-self: center;
  align-items: center;
  display: flex;
}

.button-primary {
  color: #fff;
  letter-spacing: 0;
  text-transform: lowercase;
  background-color: #0dbc81;
  border-radius: 40px;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 12px 25px;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 200;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #0bac76;
}

.button-primary:active {
  background-color: #43464d;
}

.button-primary.on-green {
  color: var(--0dbc81);
  letter-spacing: 0;
  text-transform: lowercase;
  background-color: #fff;
  /* margin-top: 9px; */
}

.button-primary.on-green:hover {
  background-color: var(--white-smoke);
}

.button-primary.on-green.left {
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-block;
}

.button-primary.menu {
  text-transform: none;
  align-self: center;
  margin-top: 0;
}

.button-primary.centre {
  align-self: center;
}

.image-3 {
  max-width: 150px;
}

.paragraph-usp {
  color: #7c7c7c;
  text-align: center;
  align-self: center;
  margin-bottom: 40px;
  padding-right: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.paragraph-usp.on-blue {
  color: #eeeaea;
}

.paragraph-2 {
  color: var(--grey);
  font-family: Source Sans Pro, sans-serif;
}

.paragraph-3 {
  color: var(--grey);
}

.paragraph-4 {
  color: #989898;
  font-family: Source Sans Pro, sans-serif;
}

.paragraph-5 {
  color: #989898;
}

.footer-subscribe {
  border-bottom: 1px solid #e4ebf3;
  padding: 50px 30px 40px;
  position: relative;
}

.footer-form-two {
  background-color: #f5f7fa;
  margin-bottom: 40px;
  padding: 64px;
}

.footer-form-container-two {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-form-title {
  font-size: 24px;
  line-height: 32px;
}

.footer-form-block-two {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.footer-form-input {
  border: 1px #000;
  width: 460px;
  height: 48px;
  margin-bottom: 0;
  padding: 12px 180px 12px 20px;
}

.footer-form-input::placeholder {
  color: #1a1b1fcc;
}

.button-primary-2 {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary-2:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary-2:active {
  background-color: #43464d;
}

.button-primary-2.footer-form-button {
  padding: 8px 18px;
  position: absolute;
  right: 8px;
}

.footer-wrapper-three {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-block-three {
  justify-content: flex-start;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
}

.footer-link-three {
  color: #1a1b1f;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
}

.footer-link-three:hover {
  color: #1a1b1fbf;
}

.footer-social-block-three {
  justify-content: flex-start;
  align-items: center;
  margin-left: -32px;
  display: flex;
}

.footer-social-link-three {
  margin-left: 32px;
}

.footer-divider-two {
  background-color: #e4ebf3;
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-bottom {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-copyright {
  color: #3a4554;
  justify-content: center;
  display: flex;
}

.footer-legal-block {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.footer-legal-link {
  color: #3a4554;
  margin-left: 24px;
}

.footer-legal-link:hover {
  color: #3a4554;
}

.button {
  background-color: var(--medium-sea-green);
}

.navbar-2 {
  background-color: #fff;
}

.nav-menu-2 {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.brand-2 {
  align-items: flex-end;
  display: flex;
}

.container-5 {
  text-transform: uppercase;
  flex-wrap: wrap-reverse;
  place-content: space-between;
  align-items: center;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 600;
  display: flex;
}

.nav-link-2 {
  color: var(--0dbc81);
  text-transform: uppercase;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 600;
}

.nav-link-3 {
  color: #fff;
  text-transform: uppercase;
  flex: 0 auto;
  margin-left: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 600;
  display: block;
}

.hero-heading-left {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: #0000;
  background-image: none;
  background-position: 0 0;
  border-bottom: 0 solid #e4ebf3;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 40vh;
  margin-left: 0;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.hero-heading-left.white {
  background-color: #fff;
  background-image: linear-gradient(#0000001c, #0000001c);
  min-height: 0;
}

.container-6 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  width: 100%;
  max-width: 1130px;
  min-height: 40vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.container-6.no-gap {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 0;
}

.cont {
  grid-column-gap: 0vh;
  grid-row-gap: 0vh;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 100%;
  max-width: 1130px;
  display: flex;
}

.hero-split-long {
  background-color: var(--0dbc81);
  border-radius: 20px;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: center;
  align-items: flex-start;
  min-width: 450px;
  max-width: 50%;
  min-height: 40vh;
  margin-right: 30px;
  padding: 0 40px 10px;
  display: flex;
}

.hero-split-long.map {
  border: 0px solid var(--medium-sea-green);
  background-color: #0000;
  background-image: url('https://grid.com/web/images/small-wallpaper.png');
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  justify-content: flex-end;
  min-width: 450px;
  max-width: 50%;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0 2px 12px -6px #0003;
}

.hero-split-long.map._2 {
  background-image: url('https://grid.com/web/images/wallpaper.png');
  min-width: 1130px;
}

.hero-split-long.white {
  background-color: var(--medium-sea-green);
}

.hero-split-long.tablet {
  background-color: var(--0dbc81);
  margin-right: 0;
  padding-bottom: 20px;
}

.hero-split-long.tablet.green {
  background-color: var(--medium-sea-green);
}

.hero-split-long.phone {
  margin-right: 0;
}

.margin-bottom-24px {
  margin-bottom: 24px;
}

.button-primary-3 {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary-3:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary-3:active {
  background-color: #43464d;
}

.shadow-two {
  box-shadow: 0 4px 24px #96a3b514;
}

.heading-2 {
  color: #fff;
  -webkit-text-stroke-color: #fff;
  align-items: flex-end;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 7vh;
  line-height: 110%;
  display: flex;
}

.heading-2.hero {
  font-size: 60px;
}

.heading-2.tablet.phone {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-bottom: 30px;
}

.div-block-4 {
  flex: 1;
  align-self: stretch;
}

.image-4 {
  margin-bottom: -16px;
  padding-right: 0;
}

.div-block-5 {
  flex-direction: row;
  flex: 0 auto;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  min-width: 300px;
  display: flex;
}

.text-block {
  color: var(--medium-sea-green);
  font-family: Source Sans Pro, sans-serif;
  font-size: 28px;
  font-weight: 700;
}

.image-5 {
  max-width: 15%;
}

.image-5._2 {
  max-width: 8%;
}

.image-5.phone {
  object-fit: scale-down;
  max-width: 15%;
  padding-top: 0;
  display: block;
}

.div-block-6 {
  justify-content: space-around;
  align-items: flex-start;
  min-height: 0;
  max-height: 100px;
  margin-bottom: 10px;
  display: flex;
}

.div-block-6._2 {
  justify-content: space-between;
  min-width: 1000px;
}

.div-block-6.phone {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  max-height: 100%;
  margin-bottom: 25px;
  padding-top: 3px;
}

.div-block-7 {
  padding-bottom: 0;
}

.container-7 {
  max-width: 1130px;
}

.container-7.banner {
  background-color: var(--royal-blue);
  border-radius: 20px;
  padding: 131px 90px;
}

.button-secondary {
  color: var(--0dbc81);
  text-transform: uppercase;
  margin-top: 10px;
  padding-right: 39px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 50px;
  font-weight: 900;
}

.h2 {
  color: var(--0dbc81);
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
}

.h2.on-green {
  color: #fff;
  min-width: 0%;
  padding-bottom: 20px;
}

.h2.black, .h2.white {
  color: #fff;
}

.div-block-8 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 62px;
  padding-left: 85px;
  padding-right: 0;
  display: flex;
}

.slide {
  background-color: var(--medium-sea-green);
  border-radius: 20px;
  min-height: 400px;
}

.slider {
  border-radius: 20px;
  min-height: 400px;
}

.mask {
  min-height: 450px;
}

.div-block-9 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.image-6 {
  object-fit: fill;
  object-position: 50% 50%;
  max-width: 100%;
  margin: -239px 0 -101px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.image-7 {
  max-width: 150px;
}

.image-8 {
  max-width: 140px;
}

.div-block-10 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.paragraph-6 {
  color: var(--grey);
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  line-height: 150%;
}

.heading-3 {
  color: var(--medium-sea-green);
  align-items: flex-end;
  padding-bottom: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 40px;
  display: flex;
}

.heading-3.on-green {
  color: var(--0dbc81);
}

.features-metrics {
  background-color: #fff;
  border-bottom: 1px solid #e4ebf3;
  padding: 60px 30px;
  position: relative;
}

.container-8 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.features-wrapper {
  justify-content: space-around;
  align-items: center;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  display: flex;
}

.features-block {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.features-title {
  margin-bottom: 8px;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.features-title-small {
  font-size: 24px;
  line-height: 32px;
}

.column {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: var(--0dbc81);
  border-radius: 20px;
  flex-flow: column wrap;
  align-content: center;
  align-items: center;
  margin-top: -64px;
  padding: 16px 40px 0;
  display: flex;
}

.usp {
  color: var(--medium-sea-green);
  text-align: center;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 0;
  padding-bottom: 11px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 110%;
  display: flex;
}

.columns {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 20px;
  display: flex;
}

.paragraph {
  color: var(--0dbc81);
  -webkit-text-stroke-color: var(--grey);
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  line-height: 150%;
}

.paragraph.on-blue {
  color: #fff;
  max-width: 80%;
}

.paragraph.white {
  color: #fff;
}

.section-3 {
  background-color: var(--0dbc81);
}

.intro {
  text-align: center;
  font-family: Source Sans Pro, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
}

.image-9 {
  max-width: 100px;
}

.h2-on-green {
  color: #fff;
}

.slider-2 {
  width: 100%;
  min-height: 40px;
}

.slide-2 {
  background-color: var(--medium-sea-green);
  border-radius: 20px;
  width: 100%;
  max-width: 1130px;
  min-height: 40vh;
  padding-bottom: 0;
  padding-right: 0;
}

.div-block-11 {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.div-block-12 {
  display: flex;
}

.mask-2 {
  min-height: 40vh;
}

.container-9 {
  max-width: 1130px;
  min-height: 40vh;
  padding-top: 20px;
  padding-bottom: 20px;
}

.slide-3 {
  background-color: var(--medium-sea-green);
  border-radius: 20px;
  min-height: 40vh;
  padding: 0 100px;
}

.slide-3._2 {
  background-color: #d1f1e5;
}

.slide-3._3 {
  background-color: #6ed8b4;
}

.slider-3 {
  background-color: #0000;
  min-height: 40vh;
}

.div-block-13 {
  width: 100%;
  margin-top: 14px;
  display: flex;
}

.div-block-13.phone {
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
}

.image-10 {
  object-fit: contain;
  max-width: 100%;
}

.div-block-14 {
  flex: 0 auto;
  justify-content: flex-end;
  max-width: 100%;
  padding-left: 0;
  display: flex;
}

.div-block-15 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  display: flex;
}

.image-11 {
  padding-left: 0;
}

.grid-4 {
  grid-column-gap: 40px;
  grid-row-gap: 60px;
  grid-template-rows: auto auto auto;
  grid-template-areas: "Area Area-2"
                       "Area-3 Area-4"
                       "Area-5 Area-6";
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 0;
  padding-left: 45px;
  padding-right: 48px;
}

.div-block-16 {
  /* background-color: var(--medium-sea-green); */
  border-radius: 20px;
  min-height: 40vh;
}

.div-block-16._2 {
  background-color: #0000;
  border: 0 #0000;
}

.div-block-16.wit {
  padding-bottom: 0;
}

.div-block-16._3 {
  background-color: #0000;
}

.div-block-16.image {
  background-image: url('');
  background-position: 0 0;
  background-size: auto;
}

.div-block-16.image.auto {
  background-color: var(--white-smoke);
  background-image: url('https://grid.com/web/images/MacBook-Air-2022-car.png');
  background-position: 50%;
  background-size: contain;
}

/* Default (for all screen sizes, applies to the global styles) */
.div-block-16.image.detail {
  background-image: url('../../public/desk_assure_2.gif');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--white-smoke);
  width: 100%;
  height: 100vh;
}

/* For smaller screens, e.g., phones */
@media (max-width: 767px) {
  .div-block-16.image.detail {
    background-size: contain;  /* Use contain for smaller screens to prevent image cut-off */
     /* Reduce height on mobile to save space */
  }
}

/* For tablets and small devices (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .div-block-16.image.detail {
    background-size: cover;  /* Keep cover for tablet devices */
    /* height: 9vh;  Adjust height for tablets */
  }
  
}

/* For larger screens (desktop, etc.) */
@media (min-width: 1025px) {
  .div-block-16.image.detail {
    background-size: cover;  /* Keep cover for larger screens */
    height: 100vh;  /* Full height on desktop */
  }
}



.div-block-16.image.rit {
  background-color: #0000;
  background-image: url('https://grid.com/web/images/route-groter.png');
  background-position: 0 0;
  background-size: cover;
}







.div-block-16.image.koffie {
  background-color: #0000;
  background-image: url('https://grid.com/web/images/handheldsmall-EN.webp');
  background-position: 50%;
  background-size: cover;
  max-height: 100%;
  line-height: 100%;
}



.div-block-16.image.lader-polestar {
  background-image: url('https://grid.com/web/images/charger.png');
  background-position: 0 0;
  background-size: auto;
}

.div-block-16.image.lader-3 {
  background-color: #0000;
  background-image: url('https://grid.com/web/images/charger-3.png');
  background-position: 50%;
  background-size: auto;
}

.div-block-16.white {
  border: 1px none var(--medium-sea-green);
  background-color: var(--medium-sea-green);
}

.div-block-16.white.charger {
  background-image: url('https://grid.com/web/images/gridcharger.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 70%;
  padding-top: 20px;
  padding-bottom: 100px;
}

.div-block-16.no-background {
  background-color: #0000;
  background-image: url('../../public/desk_assure_2.gif');
  background-position: 0 0;
  background-size: cover;
  width: 100%;
  display: flex;
}

.div-block-16.no-background.food {
  background-image: url('https://grid.com/web/images/banner-ad-road.webp');
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.grid-5 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto auto auto auto;
  max-width: 1130px;
  margin: auto;
}

.grid-5._4-blocks {
  grid-row-gap: 40px;
  grid-template-rows: auto auto auto;
}

.container-10 {
  background-color: #C1F11D;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1130px;
  padding: 40px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 900;
  display: block;
  overflow: visible;
  height: 106%;
}

.container-10.header {
  outline-color: #C1F11D;
  outline-offset: 0px;
  color: var(--green);
  background-color: #0000;
  border: 0 #000;
  outline-width: 1.5px;
  outline-style: solid;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  display: flex;
}

.container-10.header.centre {
  outline-color: #C1F11D;
  outline-offset: 0px;
  outline-width: 1.5px;
  outline-style: none;
  min-height: 30vh;
  margin-bottom: 40px;
}

.container-10.header.green {
  background-color: #C1F11D;
}

.container-10._2 {
  background-color: var(--medium-aquamarine);
}

.container-10.zwart {
  background-color: var(--0dbc81);
  background-image: linear-gradient(#000, #000000d6 66%, #0000), url('https://grid.com/web/images/icons.png');
  background-position: 0 0, 50% 100%;
  background-repeat: repeat, repeat-x;
  background-size: auto, auto;
  min-height: 40vh;
  margin-top: 40px;
}

.container-10.map {
  outline-color: #C1F11D;
  outline-offset: 0px;
  text-align: center;
  background-color: #0000;
  outline-width: 1.5px;
  outline-style: solid;
  flex-direction: column;
  align-items: center;
  padding: 43px;
  display: flex;
}

.container-10.map.nog-border {
  outline-color: var(--medium-sea-green);
  outline-offset: 0px;
  background-color: #0000;
  outline-width: 1.5px;
  outline-style: none;
  justify-content: flex-start;
  min-height: 40vh;
  display: block;
}

.container-10.groen {
  background-color: #C1F11D;
}

.container-10._5 {
  background-color: #C1F11D;
}

.container-10.black {
  background-color: var(--0dbc81);
}

.container-10.black.charger {
  background-image: url('https://grid.com/web/images/grid-charger-2.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 60%;
}

.container-10.centre {
  text-align: center;
}

.container-10.green {
  background-color: var(--medium-sea-green);
  height: 105%;
}

.container-10.blog {
  border: 1px solid var(--medium-sea-green);
  background-color: #fff;
  align-items: flex-start;
  display: flex;
}

.container-10.white {
  background-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.container-10.white.no-space {
  padding: 0;
}

.container-10.light-green {
  background-color: var(--medium-aquamarine);
}

.container-10.soft-green {
  background-color: var(--medium-sea-green-2);
}

.container-10.soft-grey {
  background-color: var(--card-background);
}

.heading-4 {
  text-transform: uppercase;
  font-size: 50px;
}

.paragraph-7 {
  color: white;
  max-width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  font-family: 'Poppins';
  padding-bottom: 10px;
}

.paragraph-7.white {
  color: #7c7c7c;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 100%;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 40px;
  font-size: 20px;
  display: flex;
}

.paragraph-7.white.links, .paragraph-7.white.left {
  text-align: left;
}

.paragraph-7.white.map {
  text-align: center;
  align-items: flex-start;
  padding-left: 0;
  font-size: 18px;
}
a.button-primary.w-button.bg-gradient-to-bl.from-yellow-500.via-orange-500.to-red-500.raise.button {
    /* align-items: center; */
    /* display: flex; */
    align-self: center;
        text-transform: none;
}
h2.title.zwart.now {
    color: white;
}
strong.text-white.ideal {
    text-transform: none;
}
.section-2.black.clear.qaz {
    margin-top: 5px;
}
div#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adce6 {
    margin-top: 40px;
    margin-bottom: 20px;
}
.paragraph-7.white.map.centre {
  text-align: center;
  padding-left: 40px;
  font-family: 'Poppins';
  color: #000;
}

.paragraph-7.white.h {
  align-items: flex-start;
  padding-bottom: 20px;
}

.paragraph-7.white.home {
  padding-left: 0;
  font-size: 18px;
}

.paragraph-7.white.home.centre {
  text-align: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}

.paragraph-7.white.inleiding {
  align-items: flex-start;
  padding-bottom: 40px;
  padding-left: 0;
}

.paragraph-7.wit {
  color: #fff;
}

.paragraph-7.left {
  text-align: left;
}

.paragraph-7.link {
  align-self: center;
}

.paragraph-7.black {
  color: #49527b;;
  
}

.heading-5 {
  color: var(--0dbc81);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -1px;
}

.heading-5.green {
  color: var(--medium-sea-green);
  margin-bottom: 10px;
}

.heading-5.green.centre {
  color: #C1F11D;
}

.heading-5.green.links {
  margin-bottom: 10px;
}

.heading-5.green.left {
  text-transform: none;
}

.heading-5.white {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}

.heading-5.left {
  text-align: left;
}

.title {
  color: #fff;
  -webkit-text-stroke-color: var(--0dbc81);
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-size: 65px;
  font-weight: 400;
  line-height: 90%;
}

.title.heading {
  text-transform: uppercase;
  font-family: Source Sans Pro, sans-serif;
  font-size: 100px;
  font-weight: 900;
}

.title.heading.blue {
  color: var(--royal-blue);
}

.title.heading.green {
  color: var(--0dbc81);
  font-size: 80px;
}

.title.zwart {
  color: var(--0dbc81);
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 60px;
}

.title.header {
  color: var(--0dbc81);
  margin-top: 20px;
  margin-bottom: 10px;
}

.title.dark {
  color: var(--0dbc81);
  text-align: center;
  margin-bottom: 40px;
  font-family: Source Sans Pro, sans-serif;
}

.title.dark.left {
  text-align: left;
}

.title.black {
  color: var(--0dbc81);
  justify-content: flex-start;
  max-width: 1130px;
  margin-bottom: 20px;
  display: flex;
}

.title.black.centre {
  justify-content: center;
  margin-bottom: 20px;
}

.title.black.block {
  margin-bottom: 40px;
}

.title.greencolor {
  color: var(--medium-sea-green);
}

.container-11 {
  background-color: var(--0dbc81);
  background-image: url('https://grid.com/web/images/AdobeStock_563047842.jpeg');
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  align-items: flex-end;
  max-width: 1130px;
  min-height: 30vh;
  padding: 38px;
  display: flex;
}

.container-11.map {
  background-color: var(--medium-sea-green);
  background-image: linear-gradient(to bottom, transparent, var(--white-smoke)), url('https://grid.com/web/images/image-3.png');
  background-position: 0 0, 100%;
  background-size: auto, auto;
  box-shadow: 0 2px 5px #0003;
}

.section-4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-13 {
  border-radius: 20px;
}

.image-14 {
  display: block;
}

.div-block-17 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-5 {
  outline-offset: 0px;
  border: 0 #0000;
  outline: 3px #333;
  align-items: center;
  margin-top: -30vh;
  padding-top: 0;
  display: flex;
}

.div-block-18 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.div-block-18.blog {
  align-items: flex-start;
}

.div-block-19 {
  grid-column-gap: 20px;
  display: flex;
}

.frame-153917 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.web-searchbar {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1130px;
  display: flex;
  box-shadow: 0 2px 16px #00000014;
}

.searchbar {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #fff;
  border-radius: 48px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 752px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.frame-232 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 40px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.search {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.frame-175 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 48px;
  padding: 20px 8px;
  display: flex;
}

.vectors-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 32px;
  display: flex;
}

.charge-speed {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  padding: 6px 16px;
  display: flex;
}

.frame-153918 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text {
  color: #7c7c7c;
  font-family: Source Sans Pro, sans-serif;
  font-size: Infinityrem;
  letter-spacing: -.24px;
  font-weight: 600;
  line-height: 16px;
}

.text-2 {
  color: #212121;
  font-family: Inter, sans-serif;
  font-size: Infinityrem;
  letter-spacing: -.24px;
  font-weight: 400;
  line-height: 16px;
}

.web-beschikbaar {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.vectors-wrapper-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 8px;
  display: flex;
}

.frame-153919 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 48px;
  display: flex;
}

.frame-15362 {
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  background-color: #0dbc81;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 12px 16px;
  display: flex;
}

.vectors-wrapper-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.web-filters {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #fff;
  border-radius: 40px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 22px;
  display: flex;
}

.vectors-wrapper-4 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.text-3 {
  color: #212121;
  font-family: Source Sans Pro, sans-serif;
  font-size: Infinityrem;
  letter-spacing: -.24px;
  font-weight: 700;
  line-height: 16px;
}

.placeholdertextsmall-2 {
  color: #0dbc81;
  font-family: Inter, sans-serif;
  font-size: Infinityrem;
  font-weight: 400;
  line-height: 24px;
}

.placeholdertextsmall-3 {
  color: #7c7c7c;
  font-family: Inter, sans-serif;
  font-size: Infinityrem;
  font-weight: 400;
  line-height: 24px;
}

.grid-6 {
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr;
}

.text-block-2 {
  color: var(--0dbc81);
  text-align: left;
  font-weight: 400;
}

.text-block-2.bold {
  color: var(--grey);
  font-weight: 600;
}

.text-block-2.leeg {
  color: var(--grey);
  text-transform: none;
}

.div-block-20 {
  grid-column-gap: 5px;
  border-right: 1px solid var(--white-smoke);
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: 30%;
  min-width: 100px;
  min-height: 48px;
  padding-left: 20px;
  text-decoration: none;
  display: flex;
}

.text-blok-1 {
  color: var(--medium-sea-green);
  font-weight: 400;
}

.image-15 {
  flex: 1;
  min-width: 0;
  max-width: 32px;
  min-height: 0;
  padding-left: 0;
}

.div-block-21 {
  grid-column-gap: 30px;
  background-color: #fff;
  border-radius: 40px;
  flex-wrap: wrap;
  place-content: center space-between;
  align-self: stretch;
  align-items: flex-start;
  min-width: 80px;
  max-width: 1000px;
  max-height: 48px;
  margin-right: 0;
  padding-right: 6px;
  display: flex;
  box-shadow: 0 2px 5px #0003;
}

.div-block-22 {
  border-right: 1px solid var(--white-smoke);
  flex-direction: column;
  justify-content: center;
  align-self: center;
  min-height: 48px;
  padding-left: 10px;
  padding-right: 52px;
  display: flex;
}

.div-block-23 {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  min-height: 48px;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
}

.div-block-24 {
  object-fit: fill;
  max-height: 48px;
  padding-right: 132px;
}

.footer-light {
  border-bottom: 1px solid #e4ebf3;
  padding: 80px 30px 40px;
  position: relative;
}

.container-12 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.footer-wrapper-two {
  justify-content: space-between;
  align-items: flex-start;
  width: auto;
  max-width: 1130px;
  display: flex;
}

.footer-block-two {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.footer-title {
  color: var(--medium-sea-green);
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 32px;
}

.footer-link-two {
  color: #1a1b1f;
  margin-top: 20px;
  font-family: Source Sans Pro, sans-serif;
  text-decoration: none;
}

.footer-link-two:hover {
  color: #1a1b1fbf;
}

.footer-form {
  width: 315px;
  max-width: 100%;
  margin-bottom: 0;
}

.footer-form-block {
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.footer-form-field {
  border: 1px solid #a6b1bf;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 48px;
  margin-bottom: 0;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 22px;
  transition: all .2s;
}

.footer-form-field:hover, .footer-form-field:focus {
  border-color: #76879d;
}

.footer-form-field::placeholder {
  color: #1a1b1fcc;
  font-size: 14px;
  line-height: 22px;
}

.footer-form-submit {
  background-color: var(--medium-sea-green);
  background-image: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 50px;
  height: 48px;
  transition: all .2s;
}

.footer-form-submit:hover {
  background-color: #3a4554;
}

.footer-divider-two-2 {
  background-color: #e4ebf3;
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-bottom-2 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-copyright-2 {
  color: #3a4554;
}

.footer-social-block-two {
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  margin-left: -12px;
  display: flex;
}

.footer-social-link {
  margin-left: 12px;
}

.container-13 {
  max-width: 1130px;
}

.footer-header {
  color: var(--0dbc81);
  text-transform: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.footer-link {
  color: var(--green);
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.footer-link.bottom {
  font-size: 14px;
}

.footer-link.header {
  font-size: 21px;
  font-weight: 600;
}

.columns-2 {
  flex-direction: row;
  align-items: center;
  padding-bottom: 40px;
  display: flex;
}

.columns-3 {
  border-top: 2px solid var(--white-smoke);
  border-radius: 0;
  padding-top: 20px;
}

.div-block-25 {
  font-size: 10px;
}

.image-16 {
  align-self: center;
}

.container-tekst {
  max-width: 1130px;
  font-family: Source Sans Pro, sans-serif;
}

.container-tekst.tekstpagina {
  padding-top: 60px;
  padding-bottom: 100px;
}

.container-tekst.blog {
  padding: 40px 40px 140px;
}

.heading-6 {
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.heading-6.blog {
  margin-top: 0;
}

.heading-7 {
  color: var(--medium-sea-green);
  padding-left: 40px;
  padding-right: 40px;
}

.section-6 {
  padding-top: 40px;
}

.container-14 {
  max-width: 1130px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.home-hero_count-item {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
  display: flex;
}

.home-hero_count_number-wrapper {
  color: #0dbc81;
  background-color: #ecfaf5;
  border-radius: 10rem;
  justify-content: space-around;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.home-hero_count_number {
  font-weight: 600;
}

.paragraph-8 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.heading-8 {
  font-size: 18px;
}

.categorie {
  color: var(--medium-sea-green);
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.categorie.wit {
  color: var(--0dbc81);
}

.div-block-26, .column-2 {
  text-align: right;
}

.column-3 {
  flex: 0 auto;
  justify-content: flex-end;
  display: flex;
}

.div-block-27 {
  justify-content: flex-end;
  display: flex;
}

.link-block, .link-block-2, .link-block-3, .link-block-4, .link-block-5, .link-block-6 {
  text-decoration: none;
}

.text-block-3 {
  color: var(--medium-sea-green);
  text-transform: uppercase;
  font-weight: 700;
}

.image-18 {
  max-width: 1130%;
}

.image-19 {
  object-fit: cover;
  max-width: 1130%;
  display: block;
}

.div-block-28 {
  align-items: center;
  display: flex;
}

.text-block-4 {
  padding-left: 10px;
}

.link-back {
  color: var(--medium-sea-green);
  text-transform: none;
  align-self: center;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.heading-9, .heading-10 {
  color: var(--medium-sea-green);
}

.blog-text {
  padding-bottom: 140px;
}

.section-7 {
  margin-top: -250px;
}

.rich-text-block {
  color: var(--medium-sea-green);
  font-size: 20px;
  line-height: 150%;
}

.utility-page-wrap {
  background-image: url('https://grid.com/web/images/Stekker_Grid_404.webp');
  background-position: 50%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 80vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.column-4 {
  flex: 0 auto;
  justify-content: flex-end;
  display: flex;
}

.div-block-29, .div-block-30 {
  justify-content: flex-end;
  display: flex;
}

.text-block-5 {
  font-weight: 400;
}

.link-block-7 {
  order: 0;
  text-decoration: none;
}

.title-2 {
  color: #fff;
  text-transform: none;
  font-size: 65px;
  font-weight: 900;
  line-height: 100%;
}

.title-2.zwart {
  color: #1a1b1f;
  font-size: 65px;
}

.container-15 {
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1130px;
  padding: 40px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 900;
  display: block;
  overflow: visible;
}

.container-15._2 {
  background-color: #86ddbf;
}

.container-15.groen {
  background-color: #0dbc81;
}

.button-primary-4 {
  color: #fff;
  letter-spacing: 0;
  text-transform: lowercase;
  background-color: #0dbc81;
  border-radius: 40px;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 12px 25px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  transition: all .2s;
}

.button-primary-4:hover {
  color: #fff;
  background-color: #0bac76;
}

.button-primary-4:active {
  background-color: #43464d;
}

.button-primary-4.on-green {
  color: #1a1b1f;
  letter-spacing: 0;
  text-transform: lowercase;
  background-color: #fff;
  margin-top: 9px;
}

.button-primary-4.on-green:hover {
  background-color: #f0f0f0;
}

.section-8 {
  outline-offset: 0px;
  border: 0 #0000;
  outline: 3px #333;
  align-items: center;
  margin-top: -250px;
  padding-top: 0;
  display: flex;
}

.heading-11 {
  color: #0dbc81;
  padding-left: 40px;
  padding-right: 40px;
}

.grid-7 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto auto auto auto;
  max-width: 1130px;
  margin: auto;
}

.paragraph-9 {
  color: #32343a;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

.paragraph-9.white {
  color: #7c7c7c;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 100%;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 40px;
  font-size: 20px;
  display: flex;
}

.paragraph-9.white.h {
  align-items: flex-start;
  padding-bottom: 20px;
}

.paragraph-9.black {
  color: #32343a;
}

.heading-12 {
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.div-block-31 {
  background-color: #0dbc81;
  border-radius: 20px;
  min-height: 40vh;
}

.div-block-31.image {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
}

.div-block-31.image.voorkeur {
  background-color: #f0f0f0;
  background-image: url('https://grid.com/web/images/macbook-car-small.png');
  background-position: 50%;
  background-repeat: repeat-x;
  background-size: contain;
}

.div-block-31.image.detail {
  background-color: #f0f0f0;
  background-image: url('https://grid.com/web/images/car.jpg');
  background-position: 50% 100%;
  background-repeat: repeat;
  background-size: auto;
}

.div-block-31.image.koffie {
  background-image: url('https://grid.com/web/images/handheldsmall.jpg');
  background-position: 50% 75%;
  background-repeat: repeat;
  background-size: cover;
}

.div-block-31.image.zoek {
  background-color: #86ddbf;
  background-image: url("https://cdn.prod.website-files.com/647736f7d2f9a885ef171f5a/647736f7d2f9a885ef171faf_macbook%20vito's%20klein.png");
  background-position: 50%;
  background-repeat: repeat-x;
  background-size: 90%;
  padding-bottom: 0;
}

.div-block-31._2 {
  background-color: #0000;
  border: 0 #0000;
}

.div-block-31.wit {
  padding-bottom: 0;
}

.heading-13 {
  color: #1a1b1f;
  line-height: 120%;
}

.heading-13.white {
  color: #fff;
  margin-top: 0;
}

.link-block-8 {
  text-decoration: none;
}

.homepage-link {
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.link-block-9 {
  text-decoration: none;
}

.columns-4 {
  flex: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.text-block-6 {
  min-height: auto;
  margin-bottom: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 60px;
  font-weight: 600;
  display: block;
}

.heading-14, .heading-15 {
  font-family: Source Sans Pro, sans-serif;
  font-size: 90px;
}

.heading-16 {
  color: var(--medium-sea-green);
  margin-bottom: 48px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 90px;
}

.link-block-10 {
  direction: ltr;
  text-transform: none;
  border-style: none;
  text-decoration: none;
}

.link-block-11, .link-block-12 {
  text-decoration: none;
}

.text-block-7 {
  font-weight: 400;
}

.wg-code {
  display: none;
}

.footer-header-2 {
  color: #1a1b1f;
  text-transform: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.wg-element-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.wg-element-wrapper.sw1 {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  justify-content: flex-start;
  margin-bottom: -10px;
}

.columns-5 {
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

.div-block-32 {
  justify-content: flex-end;
  display: flex;
}

.container-16 {
  max-width: 1130px;
}

.wg-element {
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-end;
  width: 50%;
  height: auto;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.wg-element:hover {
  background-color: #0000;
}

.div-block-33 {
  justify-content: flex-end;
  display: flex;
}

.columns-6 {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  display: flex;
}

.flex-block {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.footer-link-2 {
  color: #32343a;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.footer-link-2.bottom {
  font-size: 14px;
}

.wg-selector-highlight {
  background-color: #4049ff;
  width: 100%;
  height: 100%;
}

.wg-selector-highlight.h1 {
  background-color: #0dbc81;
}

.wg-selector-highlight.h2 {
  background-color: #0dbc81;
  transform: translate(-100%);
}

.div-block-34 {
  font-size: 10px;
}

.text-block-10 {
  color: #32343a;
  font-family: Source Sans Pro, sans-serif;
}

.text-block-10:hover {
  color: #0dbc81;
}

.div-block-35 {
  justify-content: flex-end;
  display: flex;
}

.wg-selector-highlight-wrapper {
  width: 100%;
  height: 2px;
  margin-top: .25rem;
  overflow: hidden;
}

.wg-selector-highlight-wrapper:hover {
  color: #0dbc81;
}

.wg-selector-1 {
  color: #000;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  transition: color .2s;
  display: flex;
  position: relative;
}

.wg-selector-1:hover {
  color: #4049ff;
}

.wg-selector-1.w--current {
  cursor: default;
}

.wg-selector-1.s2 {
  opacity: .5;
  padding-right: 0;
  font-family: Source Sans Pro, sans-serif;
}

.wg-selector-1.s2:hover {
  color: #0dbc81;
  -webkit-text-stroke-color: #0dbc81;
}

.wg-selector-1.s1 {
  align-self: center;
}

.wg-selector-1.s1:hover {
  color: #0dbc81;
}

.screenreader-only {
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.cookie-modal_styles {
  display: none;
}

.wg-selector-1-2 {
  color: #000;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: color .2s;
  display: flex;
  position: relative;
}

.wg-selector-1-2:hover {
  color: #4049ff;
}

.wg-selector-1-2.w--current {
  cursor: default;
}

.wg-selector-1-2.s2 {
  opacity: .5;
  padding-right: 0;
  font-family: Source Sans Pro, sans-serif;
}

.wg-selector-1-2.s2:hover {
  color: #0dbc81;
  -webkit-text-stroke-color: #0dbc81;
}

.wg-selector-1-2.s1:hover {
  color: #0dbc81;
}

.cookie-modal_closebutton {
  z-index: 5;
  cursor: pointer;
  background-color: #13d191;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1.25rem 1.25rem -2.5rem auto;
  transition: background-color .2s cubic-bezier(.25, .46, .45, .94);
  display: flex;
  position: absolute;
  inset: 0% 0% auto auto;
}

.cookie-modal_radio_button {
  float: left;
  border: 1.75px solid #747577;
  border-radius: .375rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0;
  margin-left: -1.75rem;
  transition: background-color .2s cubic-bezier(.25, .46, .45, .94);
}

.cookie-modal_radio_button.w--redirected-checked {
  background-color: #2573ff;
  background-image: url('https://grid.com/web/images/radio__checkmark.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: .625rem;
  border: 1px solid #515750;
}

.cookie-modal_radio_button.w--redirected-focus {
  box-shadow: none;
}

.cookie-modal_radio_button.is-always-active {
  cursor: not-allowed;
  background-color: #0dbc81;
  background-image: url('https://grid.com/web/images/radio__checkmark.svg');
  background-position: 45%;
  background-repeat: no-repeat;
  background-size: .625rem;
  border-color: #515750;
}

.footer_links {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  font-family: Source Sans Pro, sans-serif;
  display: flex;
}

.footer_bottom-w {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .75fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.padding-global {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.cookie-modal_radio {
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-left: 1.75rem;
  transition: color .15s cubic-bezier(.25, .46, .45, .94);
}

.cookie-modal_radio.is--not-allowed {
  cursor: not-allowed;
}

.cookie-modal_form-wrap {
  margin-bottom: 0;
}

.cookie-modal_link {
  transition: color .15s cubic-bezier(.25, .46, .45, .94);
}

.cookie-preference_wrapper {
  color: #000;
  width: 100%;
  max-height: 100%;
  padding: 2rem;
  position: relative;
  overflow: auto;
}

.footer-link-3 {
  color: #32343a;
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.footer-link-3.bottom {
  font-size: 14px;
}

.cookie-modal_closebutton_line-2 {
  background-color: #515750;
  width: 1rem;
  height: 2px;
  position: absolute;
}

.cookie-modal_closebutton_line-2.is-right {
  transform: rotate(-45deg);
}

.cookie-modal_closebutton_line-2.is-left {
  transform: rotate(45deg);
}

.cookie-modal_radio_label {
  cursor: pointer;
  margin-bottom: .25rem;
  font-size: .9375rem;
  font-weight: 600;
}

.cookie-modal_closebutton-3 {
  z-index: 5;
  cursor: pointer;
  background-color: #f3f7f2;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1.25rem 1.25rem -2.5rem auto;
  transition: background-color .2s cubic-bezier(.25, .46, .45, .94);
  display: flex;
  position: relative;
}

.cookie-modal_closebutton-3.is-sticky {
  background-color: #f2f5f7;
  position: sticky;
  top: -1rem;
}

.cookie-modal_content-wrap {
  padding: 1.75rem 2rem;
}

.cookie-modal_content-wrap.is-small {
  padding: 1.5rem;
}

.cookie-modal_description-3 {
  color: #3a3a3a;
  margin-top: 1rem;
  font-size: 1rem;
}

.cookie-popup_component-2 {
  z-index: 9500;
  color: #000;
  max-width: 25rem;
  display: none;
  position: fixed;
  inset: auto auto 1.25rem 1.25rem;
}

.cookie-modal_radio_description {
  cursor: pointer;
  font-size: .875rem;
  font-weight: 400;
}

.cookies {
  display: block;
}

.footer {
  padding-bottom: 4rem;
}
a {
    color: var(--0dbc81);
    border: 1px #000;
    text-decoration: none;
    font-family: 'Poppins';
    letter-spacing: -1px;
}

.cookie-preference_component {
  z-index: 9525;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  inset: 0%;
}

.container-17 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.cookie-modal_description-2 {
  font-size: 1rem;
  line-height: 1.5;
}

.cookie-preference_background {
  opacity: 1;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  cursor: pointer;
  background-color: #1d1e1fb3;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.cookie-modal_button-wrap {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #f3f7f2;
  border-radius: 0 0 .25rem .25rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.cookie-modal_button-wrap.in-pref-manger {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 2rem -2rem -2.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.cookie-modal_button-wrap.is-small {
  background-color: #eceef1;
  justify-content: flex-start;
  padding: 1.5rem;
}

.cookie-modal_button-3 {
  color: #fff;
  text-align: center;
  background-color: #0dbc81;
  border-radius: 100rem;
  padding: 1em 1.5em 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: color .2s cubic-bezier(.25, .46, .45, .94), background-color .2s cubic-bezier(.25, .46, .45, .94);
}

.cookie-modal_button-3:hover {
  background-color: #262926;
}

.cookie-modal_button-3.is-secondary {
  color: #515750;
  background-color: #0000;
  padding-left: 0;
  padding-right: 0;
  font-size: .875rem;
  font-weight: 700;
}

.cookie-modal_button-3.is-secondary:hover {
  text-decoration: underline;
}

.wg-element-2 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: flex-end;
  height: auto;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.wg-element-2:hover {
  background-color: #0000;
}

.cookie-modal_title {
  margin-bottom: .1875em;
  font-size: 1.5rem;
  font-weight: 700;
}

.cookie-modal_title.is-small {
  font-size: 1.5rem;
  display: inline;
}

.text-block-11 {
  color: #32343a;
  font-family: Source Sans Pro, sans-serif;
}

.text-block-11:hover {
  color: #0dbc81;
}

.cookie-modal_closebutton_line-3 {
  background-color: #fff;
  width: 1rem;
  height: 2px;
  position: absolute;
}

.cookie-modal_closebutton_line-3.is-left {
  transform: rotate(45deg);
}

.cookie-modal_closebutton_line-3.is-right {
  transform: rotate(-45deg);
}

.footer_link-w {
  text-decoration: none;
}

.footer_link-text {
  text-align: right;
  font-size: 1.5rem;
  font-weight: 600;
}

.footer_top {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr .5fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.cookie-modal_form {
  padding-top: 1.25rem;
  padding-bottom: .5rem;
}

.footer_divider {
  background-color: #f0f0f0;
  width: 100%;
  height: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cookie-modal_component {
  background-color: #fff;
  border-radius: .25rem;
  flex-direction: column;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
  font-size: .9375rem;
  line-height: 1.4;
  display: flex;
  position: relative;
  box-shadow: 0 4px 1rem #2629260d;
}

.text-block-12 {
  max-width: 100vw;
}

.container-18 {
  max-width: 100%;
}

.text-block-13, .text-block-14 {
  font-weight: 400;
}

.heading-17 {
  color: var(--0dbc81);
  margin-top: 10px;
}

.heading-17.white {
  color: #fff;
  margin-top: 10px;
}

.paragraph-10 {
  font-family: Source Sans Pro, sans-serif;
}

.grid-8 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 20px;
}

.heading-18 {
  color: var(--medium-sea-green);
  margin-top: 10px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
}

.div-block-36 {
  text-align: center;
  padding: 20px;
}

.paragraph-11 {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
}

.paragraph-11.black {
  color: var(--0dbc81);
}

.paragraph-11.centre {
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
}

.container-19 {
  background-color: var(--medium-sea-green-2);
  text-align: center;
  border-radius: 20px;
  max-width: 1130px;
  padding: 60px;
  font-family: Open Sans, sans-serif;
}

.image-21 {
  border-radius: 20px;
}

.heading-19 {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
}

.container-20 {
  padding: 20px;
}

.image-22 {
  border-radius: 20px;
  max-width: 100%;
  max-height: 600px;
}

.container-21 {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  max-width: 800px;
}

.image-23 {
  background-color: #0000;
  min-width: 40px;
  min-height: 40px;
}

.div-block-37 {
  background-color: var(--medium-sea-green);
  background-image: url('https://grid.com/web/images/Banner-Ad-EN.webp');
  background-position: 50%;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 20px;
  width: 100%;
}

.image-24 {
  border-radius: 20px;
}

.div-block-38 {
  background-image: url('https://grid.com/web/images/banner-listview.webp');
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  width: 100%;
}

.grid-9 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--medium-sea-green-2);
  border-radius: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: 1130px;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.div-block-39 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-15 {
  color: var(--green);
  justify-content: center;
  padding-top: 20px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.image-25, .image-26 {
  max-width: 90px;
}

.image-27 {
  text-align: center;
  display: block;
}

.image-28 {
  border-radius: 20px;
}

.hero-heading-left-2 {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4ebf3;
  padding: 80px 30px;
  position: relative;
}

.container-22 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.hero-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hero-split {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 46%;
  display: flex;
}

.margin-bottom-24px-2 {
  margin-bottom: 24px;
}

.button-primary-5 {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary-5:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary-5:active {
  background-color: #43464d;
}

.shadow-two-2 {
  box-shadow: 0 4px 24px #96a3b514;
}

.text-block-16, .text-block-17, .text-block-18 {
  text-align: center;
}

.image-29 {
  max-width: 150px;
}

.div-block-40 {
  display: block;
}

.section-wide {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-image: url('https://grid.com/web/images/Header.webp');
  background-position: 50%;
  background-size: cover;
  flex-wrap: nowrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  place-content: center flex-start;
  min-height: 50vh;
  padding-top: 70px;
  padding-bottom: 70px;
  display: block;
  position: static;
}

.section-wide.black {
  background-color: #0000;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  min-height: 50vh;
  padding-top: 40px;
}

.section-wide.black.clear {
  background-image: none;
  min-height: auto;
  padding-bottom: 40px;
  font-family: Source Sans Pro, sans-serif;
}

.section-wide.releases {
  background-image: linear-gradient(63deg, #2d75ff, #012169);
  min-height: auto;
  padding-bottom: 180px;
}

.container-main-centered {
  background-color: #0000;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1130px;
  min-height: 55px;
  padding: 40px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 900;
  display: block;
  overflow: visible;
}

.container-main-centered.bottom {
  background-color: #fff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 200px;
  margin-top: -180px;
  margin-left: auto;
  margin-right: auto;
}

.heading-31 {
  color: #fff;
  font-size: 4rem;
  line-height: 4rem;
}

.subheading {
  color: #0dbc81;
  margin-top: 10px;
  font-weight: 700;
}

.paragraph-12 {
  color: #fff;
  font-weight: 400;
}

.heading-32 {
  color: #fff;
  font-size: 4rem;
  line-height: 4rem;
}

.paragraph-13 {
  color: #fff;
  font-weight: 400;
}

.nav_menu_link {
  color: #fff;
  padding: 1rem;
}

.margin-xhuge {
  margin: 8rem;
}

.text-color-secondary {
  color: #222;
}

.padding-huge {
  padding: 6rem;
}

.padding-medium {
  padding: 2rem;
}

.image-43 {
  height: .7rem;
  margin-left: .25rem;
  transition: all .2s;
}

.text-align-center {
  text-align: center;
}

.text-weight-xbold {
  font-weight: 800;
}

.padding-xhuge {
  padding: 8rem;
}

.margin-large {
  margin: 3rem;
}

.success-state-lottie {
  max-width: 25%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.global-styles {
  display: block;
  position: fixed;
  inset: 0% auto auto 0%;
}

.fs-styleguide_label {
  color: #fff;
  background-color: #2d62ff;
  border-radius: .25rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  padding: .25rem .75rem .3rem;
  font-weight: 600;
  display: flex;
}

.fs-styleguide_label.is-tag {
  background-color: #dd23bb;
}

.form_input {
  background-color: #0000;
  border: 1px solid #eee;
  min-height: 3rem;
  margin-bottom: .75rem;
  padding: .5rem 1rem;
}

.form_input::placeholder {
  color: #222;
}

.form_input.is-text-area {
  min-height: 8rem;
  padding-top: .75rem;
}

.form_input.is-select-input {
  color: #222;
}

.text-align-left {
  text-align: left;
}

.margin-bottom, .margin-bottom.margin-huge, .margin-bottom.margin-medium, .margin-bottom.margin-small {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.fs-styleguide_spacing-all {
  display: none;
}

.padding-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.fs-styleguide_empty-box {
  z-index: -1;
  background-color: #2d40ea0d;
  border: 1px dashed #2d40ea;
  min-width: 3rem;
  height: 3rem;
  position: relative;
}

.form_checkbox-icon {
  border-radius: .125rem;
  width: .875rem;
  height: .875rem;
  margin: 0 .5rem 0 0;
}

.form_checkbox-icon.w--redirected-checked {
  background-size: 90%;
  border-radius: .125rem;
  width: .875rem;
  height: .875rem;
  margin: 0 .5rem 0 0;
}

.form_checkbox-icon.w--redirected-focus {
  border-radius: .125rem;
  width: .875rem;
  height: .875rem;
  margin: 0 .5rem 0 0;
  box-shadow: 0 0 .25rem 0 #3898ec;
}

.nav_component {
  background-color: #000;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  inset: 0% 0% auto;
}

.margin-xxlarge {
  margin: 5rem;
}

.padding-left {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.button-2 {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #2d75ff;
  border-radius: 100rem;
  padding: .65rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.button-2:hover {
  background-color: #0dbc81;
}

.button-2.is-small {
  padding: .5rem 1.25rem;
}

.button-2.is-secondary {
  color: #000;
  background-color: #0000;
  border: 1px solid #222;
}

.button-2.is-text {
  color: #000;
  background-color: #0000;
  border: 2px solid #0000;
}

.button-2.is-icon {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.button-2.is-large {
  padding: 1rem 2rem;
}

.button-2.green {
  color: #fff;
  background-color: #0dbc81;
}

.margin-0 {
  margin: 0;
}

.padding-xxhuge {
  padding: 12rem;
}

.margin-horizontal {
  margin-top: 0;
  margin-bottom: 0;
}

.heading-style-h6 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.icon-height-small {
  height: 1rem;
}

.margin-custom2 {
  margin: 2.5rem;
}

.fs-styleguide_background-space {
  width: 1px;
  height: 1px;
  margin: 5rem;
}

.padding-xxsmall {
  padding: .25rem;
}

.margin-top {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.form_radio-icon {
  width: .875rem;
  height: .875rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: .5rem;
}

.form_radio-icon.w--redirected-checked {
  border-width: .25rem;
  width: .875rem;
  height: .875rem;
}

.form_radio-icon.w--redirected-focus {
  width: .875rem;
  height: .875rem;
  box-shadow: 0 0 .25rem 0 #3898ec;
}

.fs-styleguide_item {
  grid-column-gap: 1.125rem;
  grid-row-gap: 1.125rem;
  border-bottom: 1px solid #0000001a;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  place-content: start;
  place-items: start;
  padding-bottom: 3rem;
  display: block;
  position: relative;
}

.fs-styleguide_item.is-stretch {
  justify-items: stretch;
}

.text-style-allcaps {
  text-transform: uppercase;
}

.margin-right {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.margin-vertical {
  margin-left: 0;
  margin-right: 0;
}

.container-small {
  width: 100%;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.margin-tiny {
  margin: .125rem;
}

.fs-styleguide_row {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.margin-small {
  margin: 1rem;
}

.padding-custom3 {
  padding: 3.5rem;
}

.text-style-quote {
  border-left: .25rem solid #e2e2e2;
  margin-bottom: 0;
  padding: 0 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.text-weight-normal {
  font-weight: 400;
}

.form_message-success {
  color: #114e0b;
  background-color: #0000000d;
  padding: 3rem;
}

.icon-1x1-medium {
  width: 2rem;
  height: 2rem;
}

.icon-1x1-medium.smaller {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-1x1-medium._4rem {
  width: 3.5rem;
  height: 3.5rem;
}

.fs-styleguide_section {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  place-items: start;
  display: grid;
}

.fs-styleguide_section.is-vertical {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-columns: 1fr;
}

.margin-medium {
  margin: 2rem;
}

.fs-styleguide_2-col {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.fs-styleguide_2-col.is-align-start {
  align-items: start;
}

.margin-left {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.fs-styleguide_message {
  color: #5e5515;
  background-color: #fcf8d8;
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.padding-custom2 {
  padding: 2.5rem;
}

.text-weight-semibold {
  font-weight: 600;
}

.text-weight-semibold.text-color-green {
  flex: none;
}

.margin-custom1 {
  margin: 1.5rem;
}

.padding-xsmall {
  padding: .5rem;
}

.padding-xlarge {
  padding: 4rem;
}

.padding-large {
  padding: 3rem;
}

.form_checkbox {
  flex-direction: row;
  align-items: center;
  margin-bottom: .5rem;
  padding-left: 0;
  display: flex;
}

.fs-styleguide_spacing {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  background-image: linear-gradient(to top, #2d40ea1a, #fff0);
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  place-content: start;
  place-items: start stretch;
  display: grid;
  position: relative;
}

.margin-huge {
  margin: 6rem;
}

.icon-height-large {
  height: 3rem;
}

.text-color-white {
  color: #fff;
}

.text-color-white.optin_cta {
  background-color: #ffffff1a;
  border-radius: 1rem;
  padding: 1rem;
}

.form_message-error {
  color: #3b0b0b;
  background-color: #f8e4e4;
  margin-top: .75rem;
  padding: .75rem;
}

.form_radio {
  flex-direction: row;
  align-items: center;
  margin-bottom: .5rem;
  padding-left: 0;
  display: flex;
}

.padding-small {
  padding: 1rem;
}

.padding-vertical {
  padding-left: 0;
  padding-right: 0;
}

.fs-styleguide_item-header {
  border-bottom: 1px solid #0000001a;
  width: 100%;
  padding-bottom: 2rem;
}

.text-size-large {
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.c--m7-footer {
  background-color: #2e2e2e;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.fs-styleguide_classes {
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.background-color-black {
  color: #fff;
  background-color: #2d75ff;
}

.icon-1x1-small {
  flex: none;
  width: 1rem;
  height: 1rem;
  margin-bottom: 0;
}

.padding-horizontal {
  padding-top: 0;
  padding-bottom: 0;
}

.fs-styleguide_section-header {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-bottom: 1px solid #eee;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  padding-bottom: 3rem;
  line-height: 1.4;
  display: grid;
}

.max-width-20 {
  width: 100%;
  max-width: 20%;
}

.container-medium {
  width: 100%;
  max-width: 70.625rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: relative;
}

.container-medium.rel_z1 {
  z-index: 1;
}

.container-medium.flex_bottom_space_ib {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.margin-xxsmall {
  margin: .25rem;
}

.heading-style-h2 {
  color: #1a1b1f;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4rem;
}

.heading-style-h2.text-color-green {
  color: #0dbc81;
}

.heading-style-h2.text-color-white {
  color: #fff;
}

.heading-style-h2.smaller {
  font-size: 3rem;
}

.text-size-tiny {
  font-size: .75rem;
}

.text-size-regular {
  font-size: 1rem;
}

.padding-top {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.text-color-black-2 {
  color: #000;
}

.text-style-link {
  color: #2d62ff;
  text-decoration: underline;
}

.heading-style-h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.heading-style-h4.text-color-green {
  color: #0dbc81;
}

.heading-style-h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.825rem;
}

.heading-style-h3._2-5rem {
  font-size: 2.5rem;
  line-height: 3rem;
}

.heading-style-h3._2-5rem.bold {
  font-weight: 700;
}

.heading-style-h3.text-color-green {
  color: #0dbc81;
}

.heading-style-h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 900;
  line-height: 4.625rem;
}

.heading-style-h1.bigger {
  color: #fff;
  font-size: 8rem;
  line-height: 10rem;
}

.heading-style-h1.text-color-green {
  color: #0dbc81;
}

.heading-style-h1.text-color-green.image_padding {
  margin-left: 1rem;
}

.heading-style-h1.bigger {
  font-size: 8rem;
  line-height: 10rem;
}

.text-weight-medium {
  font-weight: 500;
}

.text-weight-light {
  color: #fff;
  font-weight: 300;
}

.fs-styleguide_1-col {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.background-color-secondary {
  background-color: #e7f9f3;
}

.text-align-right {
  text-align: right;
}

.fs-styleguide_item-wrapper {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.margin-xsmall {
  margin: .5rem;
}

.padding-bottom {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.icon-1x1-large {
  width: 2.5rem;
  height: 2.5rem;
}

.icon-1x1-large.yt_button {
  width: 8rem;
  height: 8rem;
}

.margin-xxhuge {
  margin: 12rem;
}

.padding-custom1 {
  padding: 1.5rem;
}

.icon-height-medium {
  height: 2rem;
}

.max-width-50 {
  width: 100%;
  max-width: 50%;
}

.fs-styleguide_3-col {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  width: 100%;
  display: grid;
}

.fs-styleguide_3-col.is-align-start {
  align-items: start;
}

.max-width-25 {
  width: 100%;
  max-width: 25%;
}

.nav_logo {
  width: 10rem;
}

.max-width-60 {
  width: 100%;
  max-width: 60%;
}

.text-weight-bold {
  font-weight: 700;
}

.c--designed-by-m7 {
  z-index: 3;
  flex: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.fs-styleguide_heading-medium {
  font-size: 4rem;
}

.footer-text {
  color: #fff;
  flex: none;
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.footer-text.footersmall {
  font-size: .7rem;
  font-weight: 300;
}

.form_component {
  margin-bottom: 0;
}

.max-width-80 {
  width: 100%;
  max-width: 80%;
}

.nav_button {
  padding: 1rem;
}

.padding-xxlarge {
  padding: 5rem;
}

.text-style-nowrap {
  white-space: nowrap;
}

.margin-xlarge {
  margin: 4rem;
}

.heading-style-h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.margin-custom3 {
  margin: 3.5rem;
}

.padding-0 {
  padding: 0;
}

.fs-styleguide_background {
  border: 1px solid #0000001a;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.c--m7-footer-overlay {
  z-index: 2;
  transform-origin: 0%;
  background-color: #363636;
  position: absolute;
  inset: 0%;
}

.text-style-italic {
  font-style: italic;
}

.max-width-40 {
  width: 100%;
  max-width: 40%;
}

.padding-tiny {
  padding: .125rem;
}

.text-size-medium {
  font-size: 1.25rem;
  line-height: 2.2rem;
}

.text-size-medium.padding_lr_6rem {
  padding-left: 6rem;
  padding-right: 6rem;
}

.text-size-medium.padding_lr_6rem.text-weight-light {
  color: #1a1b1f;
}

.text-size-medium._1-1 {
  font-size: 1.1rem;
  line-height: 2rem;
}

.text-size-small {
  font-size: .9rem;
  line-height: 1.7rem;
}

.nav_container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.paragraph-14 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.background-color-white {
  background-color: #0dbc81;
}

.text-style-muted {
  opacity: .6;
}

.padding-global-2 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.body {
  font-size: 1rem;
  line-height: 2rem;
}

.nav_app {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  color: #1a1b1f;
  border-style: solid;
  border-color: #0dbc8140;
  border-radius: .75rem;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 1rem;
  transition: all .2s;
  display: none;
}

.nav_app:hover {
  background-color: #0dbc8159;
}

.nav_app.show_sidemenu {
  display: flex;
}

.app_cta_button {
  z-index: 1;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  color: #fff;
  background-color: #ffffff26;
  border-radius: .5rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 20rem;
  padding: 1rem;
  display: flex;
  position: relative;
}

.app_cta_button.green {
  background-color: #0dbc81bf;
}

.span_blue {
  color: #2d75ff;
}

.lottie-animation-2 {
  border-radius: 5555rem;
  width: 4rem;
  transition: all .2s;
}

.nav_component-3 {
  z-index: 10;
  color: #000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  position: fixed;
}

.nav_menu-button {
  padding: 1.25rem;
}

.side_nav_link {
  opacity: .7;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s;
}

.side_nav_link:hover {
  opacity: 1;
  color: #0dbc81;
  padding-left: 1rem;
}

.side_nav_link.major {
  opacity: 1;
  font-weight: 700;
}

.span_green {
  color: #0dbc81;
  font-weight: 700;
}

.bold-text-3 {
  color: #e7f9f3;
}

.div-block-82 {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 100%;
  display: flex;
  position: sticky;
  top: 10rem;
}

.text-span {
  font-weight: 700;
}

.bg_shape_hero {
  background-image: url('https://grid.com/web/images/hero_bgshape.svg');
  background-position: 50% -6%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 100%;
  height: 20rem;
  position: absolute;
  inset: auto 0% 0%;
}

._3_block_grid_wrapper {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.navbar-logo-left-2 {
  -webkit-text-fill-color: inherit;
  background-color: #fff;
  background-clip: border-box;
  align-items: center;
  display: block;
  box-shadow: 0 0 12px #0003;
}

.video_modal_wrapper {
  z-index: 10;
  -webkit-backdrop-filter: blur(1.5rem);
  backdrop-filter: blur(1.5rem);
  background-color: #02308380;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10rem;
  display: none;
  position: fixed;
  inset: 0%;
}

.grid_ads_cta {
  z-index: 1;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  color: #fff;
  background-color: #0dbc81;
  border-radius: .5rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 26rem;
  padding: 1rem;
  display: flex;
  position: relative;
}

.grid_ads_cta.beincharge {
  max-width: 100%;
}

.laadpaal_price {
  display: none;
}

.button-3 {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #2d75ff;
  border-radius: 100rem;
  padding: .65rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.button-3:hover {
  background-color: #0dbc81;
}

.button-3.hero_btn_mobile {
  display: none;
}

.button-3.green {
  color: #fff;
  background-color: #0dbc81;
}

.button-3.price {
  background-color: #ffa02d;
  font-size: 1.3rem;
  font-weight: 700;
}

.button-3.hover_black:hover {
  background-color: #32343a;
}

.nav_link-1 {
  color: #989898;
  align-items: center;
  padding: .8rem 0;
  font-size: .9rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.nav_link-1:hover {
  color: #0dbc81;
  text-decoration: none;
}

.nav_link-1.w--current {
  color: #000;
  cursor: pointer;
  box-shadow: inset 0 -2px #ffffff0d;
}

.nav_link-1.hide_desktop, .grid_ready_mobile {
  display: none;
}

.grid_overlay {
  opacity: .5;
  background-image: url('https://grid.com/web/images/grid_pattern.svg');
  background-position: 50%;
  background-size: cover;
  position: absolute;
  inset: 0%;
}

.rich_text_list {
  color: #fff;
  padding-left: 18px;
}

.nav_line_divider {
  background-color: #0dbc81;
  width: 100%;
  height: 1px;
}

.nav_menu-3 {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.image_installatie_service {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 40rem;
  margin-top: 7rem;
  display: flex;
}

.tabs-menu-3 {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  border: .2rem solid #0dbc81;
  border-radius: 2rem 2rem 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  display: flex;
}

.tabs-menu-3.center {
  justify-content: center;
  align-items: center;
}

.tabs-menu-3.center.grey {
  border-color: #f0f0f0;
}

.nav_close {
  cursor: pointer;
  background-color: #32343a;
  background-image: url('https://grid.com/web/images/crosss.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 5rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: all .2s;
  position: absolute;
  inset: 1rem 1rem auto auto;
}

.nav_close:hover {
  background-color: #2573ff;
}

.div-block-91 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-85 {
  z-index: 5;
  display: flex;
  position: relative;
}

.image-46 {
  border-radius: .5rem;
  max-height: 75%;
  position: relative;
  left: 3rem;
}

.image-46.reverse {
  left: -4rem;
}

.ads_span {
  font-weight: 700;
}

.div-block-93 {
  display: none;
}

.flex_wrapper {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  justify-content: space-between;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.flex_wrapper.bottom {
  justify-content: space-between;
  align-items: flex-end;
}

.cookie-modal_description-4 {
  color: #3a3a3a;
  margin-top: 1rem;
  font-size: 1rem;
}

.text-block-26 {
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.list_1rem {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.content_image_component {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  width: 100%;
  display: flex;
}

.content_image_component.gap_6rem {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.list_item_number {
  color: #fff;
  background-color: #0dbc81;
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  display: flex;
}

.section-13 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-13.grid_subtitle {
  background-color: #fff;
  padding-top: 1rem;
  padding-bottom: 0;
  position: relative;
}

.section-13.top_6rem {
  padding-top: 6rem;
}

.section-13.hero_laadpaal_avrg {
  background-color: #000;
  background-image: url('https://grid.com/web/images/grid_hero_bg.png');
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 10rem;
  padding-bottom: 1rem;
  position: relative;
}

.section-13.following {
  padding-top: 0;
}

.section-13.offer_optin {
  background-image: url('https://grid.com/web/images/ev_city-min.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  align-items: center;
  min-height: 80vh;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  position: relative;
}

.section-13.title {
  background-image: url('https://grid.com/web/images/ev_city-min.jpg');
  background-position: 0 0;
  background-size: auto;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  position: relative;
}

.section-13.green_bg {
  color: #fff;
  background-color: #0dbc81;
}

.section-13.form {
  background-image: url('https://grid.com/web/images/lady_charging-min.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  position: relative;
}

.tabs-content {
  color: #fff;
  background-color: #0dbc81;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.tabs-content.grey {
  background-color: #f0f0f0;
}

.cookie-modal_closebutton_line-4 {
  background-color: #fff;
  width: 1rem;
  height: 2px;
  position: absolute;
}

.cookie-modal_closebutton_line-4.is-left {
  transform: rotate(45deg);
}

.cookie-modal_closebutton_line-4.is-right {
  transform: rotate(-45deg);
}

.padding-global-3 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.button_wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  display: flex;
}

.ref_img_mobile {
  display: none;
}

.icon_text {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  cursor: pointer;
  border-bottom: 0 #fff0;
  justify-content: flex-start;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.button_arrow {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #fff0;
  justify-content: flex-start;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.button_arrow:hover {
  border-bottom-color: #fff;
}

.button_arrow.text-style-muted {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  color: #fff;
  cursor: pointer;
  border-bottom-width: 0;
  border-radius: 1rem;
  padding: .1rem .5rem;
  transition: all .2s;
}

.button_arrow.text-style-muted:hover {
  background-color: #fff3;
}

.green_block_usp {
  background-color: #fff3;
  border-radius: 1rem;
  padding: .5rem 1rem;
  font-size: 1.1rem;
}

.icon-1x1-small-4 {
  flex: none;
  width: 1rem;
  height: 1rem;
}

.icon-1x1-small-4.invert {
  filter: brightness(0%);
}

.sidenav_section_title_wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.footer_link-text-2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.side_nav_component {
  z-index: 999;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #fff;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 25rem;
  height: 100%;
  padding: 4rem 0 0;
  display: none;
  position: fixed;
  inset: 0% 0% 0% auto;
  box-shadow: 0 -4px 1rem 1rem #0000000d;
}

.grid_software_usp {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  cursor: pointer;
  background-color: #fff3;
  border-radius: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-size: 1.1rem;
  transition: all .2s;
  display: flex;
}

.grid_software_usp:hover {
  border-bottom-color: #fff;
}

.grid_software_usp.grey_bg {
  background-color: #7c7c7c14;
  padding: 1rem;
  font-size: 1rem;
}

.text-color-green {
  color: #0dbc81;
}

.tab_content_image_wrapper {
  justify-content: center;
  align-items: center;
  min-width: 18rem;
  display: flex;
}

.content_wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.content_wrapper.form {
  background-color: #2d75ff;
  border-radius: 2rem;
  min-height: 35rem;
}

.content_wrapper.form._100mw {
  width: 100%;
}

.content_wrapper.flex_grow {
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.content_wrapper.text-color-white {
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
}

.content_wrapper.text-color-white.margin-bottom.margin-custom-775 {
  margin-bottom: 10rem;
}

.content_wrapper.text-color-white.margin-bottom.margin-custom-775.a_slide {
  z-index: 1;
  position: relative;
}

.content_wrapper.align_center {
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.content_wrapper.align_center.mw_80 {
  width: 80%;
}

.content_wrapper.align_center.mw_80.text-color-white._1-5rem {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.content_wrapper.mw_100 {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.content_wrapper.mw_100._1-5rem {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.content_wrapper.mw_100.image_padding {
  padding-left: 1rem;
}

.nav_brand-6 {
  padding-left: 0;
}

.column_grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  border-radius: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  display: grid;
}

.column_grid._2 {
  grid-template-rows: auto;
}

.column_grid._2.image_padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.image-44 {
  object-fit: contain;
  width: 12rem;
  height: 8rem;
}

.charge_badge_wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  color: #e7f9f3;
  background-color: #2573ff;
  border-radius: .5rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 1rem;
  font-size: 1.2rem;
  display: flex;
}

.icon-1x1-small-5 {
  flex: none;
  width: 1rem;
  height: 1rem;
  margin-bottom: 0;
}

.icon-1x1-small-5.bigger {
  width: 3rem;
  height: 3rem;
}

.icon-1x1-small-5.smaller {
  width: .8rem;
  height: .8rem;
}

.nav_brand-image {
  width: 5rem;
}

.menu_icon_wrapper {
  cursor: pointer;
  background-color: #ffffff0d;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  transition: all .2s;
  display: flex;
}

.menu_icon_wrapper:hover {
  background-color: #86ddbf26;
  border-radius: 3rem;
}

.video_thumbnail {
  cursor: pointer;
  background-image: linear-gradient(#000000bf, #000000bf), url('https://grid.com/web/images/Grid_Energy_Management_System-2.gif');
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 35rem;
  transition: all .2s;
  display: flex;
}

.video_thumbnail:hover {
  transform: scale(.9);
}

.tab_content_image {
  object-fit: contain;
  height: 18rem;
}

.tab_content_image.io7 {
  height: 28rem;
}

.tab_content_image.wds {
  height: 25rem;
}

.text-block-25 {
  font-size: .9rem;
  line-height: 1.3rem;
}

.container_nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 70.625rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
  position: relative;
}

.video_embed {
  width: 75rem;
  height: 70vh;
}

.cta_optin_image {
  height: 18rem;
}

.benefits_content_wrapper {
  z-index: 2;
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  background-color: #fff;
  border-radius: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
  position: relative;
}

.number_block_float {
  color: #fff;
  background-color: #2573ff;
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  display: flex;
  position: absolute;
  inset: -.8rem auto auto -1.1rem;
}

.link-block-13 {
  z-index: 10;
  cursor: pointer;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 4rem;
  margin-left: auto;
  margin-right: auto;
  padding: .5rem 0 0;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: auto 0% 0%;
}

.link-block-13:hover {
  background-color: #0dbc8126;
}

.optin_component_list_item {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tab_content_component {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.button-4 {
  color: #fff;
  text-align: center;
  background-color: #2d75ff;
  border-radius: 100rem;
  padding: .65rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.button-4:hover {
  background-color: #0dbc81;
}

.button-4.nav {
  background-color: #0dbc81;
  padding: .4rem 1.25rem;
  font-size: 1rem;
}

.span_title {
  color: #0dbc81;
  font-weight: 700;
}

.textblock_green {
  color: #fff;
  background-color: #0dbc81;
  background-image: url('https://grid.com/web/images/Installatieservice_image.png');
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 1rem;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem 50% 1.5rem 2.5rem;
  display: flex;
}

.div-block-78 {
  opacity: .5;
  cursor: pointer;
  background-color: #ffffff1a;
  background-image: url('https://grid.com/web/images/noun_Close_1613035.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  width: 5rem;
  height: 5rem;
  transition: all .2s;
  position: absolute;
  inset: 0% 0% auto auto;
}

.div-block-78:hover {
  opacity: 1;
  width: 6rem;
  height: 6rem;
}

.cookie-modal_closebutton-4 {
  z-index: 5;
  cursor: pointer;
  background-color: #13d191;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1.25rem 1.25rem -2.5rem auto;
  transition: background-color .2s cubic-bezier(.25, .46, .45, .94);
  display: flex;
  position: absolute;
  inset: 0% 0% auto auto;
}

.text-block-27 {
  text-align: center;
}

.gird_block_mint {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #0dbc811a;
  border-radius: 1rem;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.cookie-modal_description-5 {
  font-size: 1rem;
  line-height: 1.5;
}

.tab_option {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  opacity: .35;
  background-color: #fff;
  border-radius: 2rem;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  padding: .5rem 1rem;
  transition: all .2s;
  display: flex;
}

.tab_option:hover {
  opacity: .75;
  background-color: #e7f9f3;
}

.tab_option.w--current {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  opacity: 1;
  background-color: #fff;
}

.optin_component_list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  display: flex;
}

.mockup_wrapper {
  background-color: #86ddbf;
  border-radius: 1rem;
  justify-content: flex-end;
  align-items: center;
  width: 35%;
  max-width: 25rem;
  display: flex;
}

.mockup_wrapper.blue {
  background-color: #2573ff;
  width: 24rem;
}

.side_nav_links_wrapper {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  color: #000;
  flex-flow: column;
  flex: 1;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.3rem;
  text-decoration: none;
  display: flex;
}

.list {
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.gird_soft_usp_list {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  flex-flow: column;
  display: flex;
}

.gird_soft_usp_list._2rem {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.hero_col {
  background-color: #0dbc81;
  border-radius: 1rem;
  width: 100%;
  min-height: 30rem;
  padding: 2rem;
}

.hero_col.grey {
  background-color: #f0f0f0;
}

.hero_col.grey.center_content {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero_col.flex_vertical_1rem {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  display: flex;
}

.hero_col.bg_greenbox {
  background-image: url('https://grid.com/web/images/grid-charger.webp');
  background-position: 66%;
  background-size: auto;
}

.hero_col.bg_ctpark {
  background-image: url('https://grid.com/web/images/DJI_0087-2048x1534.jpg');
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_col.glp_1 {
  background-image: url('https://grid.com/web/images/20240514_134329-min.jpg');
  background-position: 50%;
  background-size: cover;
}

.hero_col.glp_3 {
  background-image: url('https://grid.com/web/images/20240514_134609-min.jpg');
  background-position: 50%;
  background-size: cover;
}

.richtext_image {
  box-sizing: border-box;
  object-fit: cover;
  object-position: 50% 0%;
  width: 100%;
  overflow: hidden;
}

.tab_content_text {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.tab_content_text.mw_65 {
  max-width: 65%;
}

.tab_content_text.mw_65.gap2rem {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.tabs_component {
  margin-top: 2rem;
}

.rich_text {
  width: 100%;
}

.span_black {
  font-weight: 900;
}

.body-2 {
  overflow: clip;
}

.app_cta_button-2 {
  z-index: 1;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  color: #e7f9f3;
  background-color: #ffffff26;
  border-radius: .5rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 20rem;
  padding: 1rem;
  display: flex;
  position: relative;
}

.app_cta_button-2.green {
  background-color: #0dbc81bf;
}

.lottie-animation-4 {
  border-radius: 5555rem;
  width: 4rem;
  transition: all .2s;
}

.blue {
  color: #2372ff;
}

.laadpaal_specs_grid {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-block-95 {
  z-index: 5;
  display: flex;
  position: relative;
}

.button_arrow-2 {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #fff0;
  justify-content: flex-start;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.button_arrow-2:hover {
  border-bottom-color: #fff;
}

.button_arrow-2.text-style-muted {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  cursor: pointer;
  border-bottom-width: 0;
  border-radius: 1rem;
  padding: .1rem .5rem;
  transition: all .2s;
}

.button_arrow-2.text-style-muted:hover {
  background-color: #fff3;
}

.image-47 {
  object-fit: contain;
  width: 12rem;
  height: 8rem;
}

.icon-1x1-small-6 {
  flex: none;
  width: 1rem;
  height: 1rem;
  margin-bottom: 0;
}

.icon-1x1-small-6.smaller {
  width: .8rem;
  height: .8rem;
}

.link-block-14 {
  z-index: 10;
  cursor: pointer;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 4rem;
  margin-left: auto;
  margin-right: auto;
  padding: .5rem 0 0;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: auto 0% 0%;
}

.link-block-14:hover {
  background-color: #0dbc8126;
}

.specs_grid_item {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  background-color: #32343a;
  border-radius: 1rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  display: flex;
}

.heading-style-h4-3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.heading-style-h4-3.text-color-green {
  color: #0dbc81;
}

.faq_grid {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-block-96 {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 100%;
  display: flex;
  position: sticky;
  top: 10rem;
}

.image_slide_list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  display: flex;
}

.image_slide_list._1 {
  flex: 1;
  width: 200%;
}

.image_slide_list._2 {
  width: 200%;
}

.laadpaal_kopen_form_content {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.section-15 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.section-15._4rem {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.accordion-content---brix {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.about_cta_component {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  background-color: #e7f9f3;
  border-radius: 1.5rem;
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 4rem;
  padding: 2rem;
  display: flex;
}

.accordion-paragraph---brix-2 {
  color: #22222296;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.about_ticker_image {
  object-fit: cover;
  border-radius: 2rem;
  width: 45%;
  height: 25rem;
}

.section-16 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-16.offer_optin {
  background-image: url('https://grid.com/web/images/ev_city-min.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  align-items: center;
  min-height: 80vh;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  position: relative;
}

.section-16.offer_optin.hero {
  padding-top: 12rem;
}

.image_slider_component {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  flex-flow: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.img_slider_gradient_left {
  z-index: 2;
  background-image: linear-gradient(90deg, #fff, #fff0);
  width: 15rem;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.heading-style-h3-2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.825rem;
}

.heading-style-h3-2.text-color-green {
  color: #0dbc81;
}

.heading-style-h3-2.text-color-green._2rem {
  font-size: 2rem;
}

.open-close-line---brix {
  background-color: #170f49;
  border-radius: 500px;
  width: 100%;
  height: 2px;
  position: absolute;
}

.open-close-line---brix.second-line---brix {
  transform: rotate(90deg);
}

.accordion-item---brix {
  cursor: pointer;
  background-color: #fff;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  display: flex;
}

.accordion-item---brix.tabs-accordion---brix {
  color: #222;
  border-bottom: 1px solid #d9dbe9;
  border-radius: 0;
  padding: 2rem 0 2rem 0;
  transition: padding .2s;
}

.accordion-item---brix.tabs-accordion---brix:hover {
  padding-left: 0;
}

.open-close-icon-wrapper---brix {
  opacity: .5;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 1rem;
  min-height: 1rem;
  margin-left: 1rem;
  display: flex;
  position: relative;
}

.list_image {
  object-fit: cover;
  border-radius: 2rem;
  width: 20rem;
  max-width: none;
  height: 15rem;
}

.heading-style-h3-3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.825rem;
}

.heading-style-h3-3._2-5rem {
  font-size: 2.5rem;
  line-height: 3rem;
}

.heading-style-h3-3._2-5rem.bold {
  font-weight: 700;
}

.button-5 {
  color: #fff;
  text-align: center;
  background-color: #2d75ff;
  border-radius: 100rem;
  padding: .65rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.button-5:hover {
  background-color: #0dbc81;
}

.button-5.green {
  color: #fff;
  background-color: #0dbc81;
}

.link-3 {
  color: #0dbc81;
}

.image-48 {
  object-fit: contain;
  height: 100%;
  max-height: 45rem;
}

.accordion-trigger---brix {
  color: #222;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.img_slider_gradient_right {
  z-index: 2;
  background-image: linear-gradient(270deg, #fff, #fff0);
  width: 15rem;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.accordion-item-title---brix {
  color: #222;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 28px;
}

.cta_ticker {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  background-color: #e7f9f3;
  border-radius: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem;
  display: flex;
}

.content_wrapper-2 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.content_wrapper-2.form {
  background-color: #2d75ff;
  border-radius: 2rem;
  min-height: 35rem;
}

.content_wrapper-2.form._100w {
  width: 100%;
}

.content_wrapper-2.mw_100 {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.content_wrapper-2.mw_100.space_inbetween {
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.content_wrapper-2.mw_100.center {
  text-align: center;
  justify-content: flex-start;
  align-items: center;
}

.content_wrapper-2.mw_100.center.padding-horizontal.padding-huge {
  padding-top: 0;
  padding-bottom: 0;
}

.content_wrapper-2.mw_100._1-5rem {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.column_grid-2 {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  border-radius: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  display: grid;
}

@media screen and (max-width: 991px) {
  p, li {
    font-size: 16px;
  }

  .container-grid-7 {
    padding: 0;
  }

  .div-block {
    width: 95vw;
  }

  .grid {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    max-width: 90vw;
  }

  .div-block-2 {
    flex-direction: row;
    padding: 20px;
    display: block;
  }

  .h3.on-green.phone, .h3.green.phone {
    text-align: center;
  }

  .grid-2 {
    grid-template-rows: auto auto;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .grid-2.left-block {
    grid-template-columns: 1.75fr 1fr;
  }

  .grid-2.left-block.padding {
    padding: 20px;
  }

  .grid-2.right-block {
    grid-template-columns: 1fr 1.5fr;
  }

  .grid-2.right-block.centered {
    grid-template-columns: 1fr 1fr;
  }

  .grid-3 {
    grid-column-gap: 20px;
    grid-row-gap: 54px;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-auto-rows: 80px;
    grid-auto-flow: row dense;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .grid-3.green {
    grid-template-columns: 1.25fr 1fr;
  }

  .grid-3.black {
    grid-template-columns: 1fr 1fr;
  }

  .section-2 {
    padding: 20px;
  }

  .section-2.black {
    background-image: url('https://grid.com/web/images/GRID-map3test2.webp');
    background-position: 50%;
    background-size: cover;
    margin-top: 0;
    padding: 0 20px 20px;
  }

  .section-2.black.clear {
    min-height: 30vh;
    padding-bottom: 20px;
  }

  .section-2.green {
    padding-top: 50px;
  }

  .section-2.no-space {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .div-block-3 {
    padding-top: 36px;
    padding-bottom: 0;
  }

  .div-block-3.on-phone, .div-block-3.phone {
    padding-top: 36px;
  }

  .image-2 {
    object-fit: cover;
    object-position: 0% 50%;
    min-width: 100%;
    max-width: 0%;
  }

  .navbar-logo-left-container.shadow-three {
    padding: 10px 20px;
  }

  .container-4 {
    max-width: 728px;
  }

  .nav-menu-wrapper {
    background-color: #0000;
  }

  .nav-menu-two {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px #0000000d;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .button-primary {
    text-align: center;
    align-self: center;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .image-3 {
    max-width: 100px;
  }

  .footer-form-two {
    padding: 40px;
  }

  .footer-form-title {
    margin-right: 15px;
  }

  .footer-form-input {
    width: 400px;
    padding-right: 180px;
  }

  .footer-wrapper-three {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-link-three {
    color: var(--grey);
    font-family: Source Sans Pro, sans-serif;
  }

  .footer-social-block-three {
    margin-top: 40px;
  }

  .footer-copyright, .footer-legal-link {
    color: var(--grey);
    font-family: Source Sans Pro, sans-serif;
  }

  .hero-heading-left {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
  }

  .container-6 {
    flex-direction: column;
    max-width: 728px;
  }

  .cont {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -40px;
  }

  .hero-split-long {
    align-items: stretch;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    margin-bottom: 40px;
    margin-right: -3px;
    padding-bottom: 0;
  }

  .hero-split-long.map {
    background-image: url('https://grid.com/web/images/wallpaper.png');
    background-position: 50% 100%;
    background-size: auto;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .hero-split-long.map._2 {
    background-size: cover;
    min-width: 100%;
  }

  .hero-split-long.tablet {
    background-color: #1e1e1e;
    margin-bottom: -4px;
    padding-top: 61px;
  }

  .hero-split-long.phone {
    background-color: var(--medium-sea-green);
    margin-bottom: 20px;
  }

  .heading-2.phone {
    font-size: 7vw;
  }

  .image-5.phone {
    mix-blend-mode: darken;
    max-width: 8%;
  }

  .div-block-6.phone {
    justify-content: flex-start;
    margin-bottom: 2px;
    padding-top: 8px;
  }

  .h2.on-green, .h2.on-green.phone, .h2.phone {
    text-align: center;
  }

  .image-6 {
    object-fit: contain;
    object-position: 50% 50%;
    margin-top: 0;
    overflow: visible;
  }

  .heading-3 {
    color: var(--0dbc81);
    -webkit-text-stroke-color: transparent;
  }

  .container-8 {
    max-width: 728px;
  }

  .features-wrapper {
    justify-content: space-between;
  }

  .column {
    padding-top: 20px;
    padding-bottom: 23px;
  }

  .paragraph.phone {
    font-size: 14px;
  }

  .container-9 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .div-block-13 {
    flex-direction: column;
    align-items: center;
  }

  .div-block-13.phone {
    flex-direction: column;
  }

  .mask-3 {
    min-height: 45vh;
  }

  .image-10 {
    object-fit: scale-down;
  }

  .grid-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "Area Area-2"
                         "Area-3 Area-4"
                         "Area-5 Area-6";
  }

  .image-12 {
    object-fit: cover;
  }

  .div-block-16 {
    min-height: 35vh;
  }

  .div-block-16._2 {
    outline-offset: 0px;
    outline: 0 #333;
    min-height: 35vh;
  }

  .div-block-16.image.auto {
    background-color: var(--white-smoke);
    background-repeat: no-repeat;
  }

  .div-block-16.image.detail {
    background-image: url('../../public/desk_assure_2.gif');
    background-position: 50% 100%;
    background-size: cover;
  }

  .div-block-16.image.zoek {
    background-position: 50%, 100%;
    background-repeat: repeat-x, repeat;
    background-size: contain, auto;
  }

  .div-block-16.image.app {
    background-color: var(--medium-aquamarine);
    background-image: url('https://grid.com/web/images/iPhone-14-Pro.png');
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: contain;
  }

  .div-block-16.no-background {
    background-position: 50%;
    background-size: cover;
    min-height: 20vh;
  }

  .grid-5 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 20px;
  }

  .grid-5._4-blocks {
    padding-top: 0;
  }

  .container-10 {
    padding: 20px;
  }

  .container-10.header {
    outline-color: #C1F11D;
    outline-offset: 0px;
    text-align: left;
    outline-width: 1.5px;
    outline-style: solid;
    padding-bottom: 35px;
    display: flex;
  }

  .container-10.header.centre {
    min-height: 20vh;
  }

  .container-10._2 {
    background-color: var(--medium-aquamarine);
  }

  .container-10.map {
    padding-top: 32px;
  }

  .paragraph-7 {
    max-width: 100%;
    font-size: 16px;
  }

  .paragraph-7.white {
    text-align: left;
    align-self: center;
    font-size: 18px;
  }

  .paragraph-7.white.map {
    text-align: center;
    font-size: 16px;
  }

  .paragraph-7.white.h {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .paragraph-7.white.landing {
    text-align: left;
    font-size: 16px;
  }

  .heading-5 {
    font-size: 28px;
  }

  .heading-5.green {
    color: var(--medium-sea-green);
    text-align: center;
    align-self: center;
  }

  .heading-5.green.links {
    color: #fff;
    text-align: left;
  }

  .heading-5.green.left {
    text-align: left;
  }

  .title {
    font-size: 45px;
  }

  .title.heading.green {
    font-size: 70px;
  }

  .title.zwart {
    font-size: 30px;
  }

  .title.header {
    text-align: center;
    align-self: center;
    max-width: 80%;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .title.black {
    justify-content: flex-start;
    max-width: 100%;
    display: flex;
  }

  .section-4 {
    margin-bottom: 0;
    padding: 10px 20px 20px;
  }

  .div-block-17 {
    flex-direction: row;
    padding-bottom: 0;
  }

  .section-5 {
    margin-top: -250px;
  }

  .div-block-18.blog {
    flex-direction: column;
    align-self: flex-start;
    display: flex;
  }

  .store-buttons {
    max-width: 40%;
  }

  .div-block-21 {
    max-width: 700px;
  }

  .div-block-24 {
    padding-right: 0;
  }

  .footer-light {
    padding-top: 40px;
  }

  .container-12 {
    max-width: 728px;
  }

  .footer-wrapper-two {
    flex-wrap: wrap;
  }

  .footer-form {
    width: 100%;
    margin-top: 40px;
  }

  .footer-form-container {
    max-width: 350px;
  }

  .footer-link.bottom {
    color: var(--grey);
    font-size: 14px;
  }

  .container-tekst.tekstpagina, .container-tekst.blog, .heading-6, .heading-7 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .link-back {
    font-size: 18px;
  }

  .section-7 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .div-block-30 {
    justify-content: flex-end;
  }

  .title-2, .title-2.zwart {
    font-size: 45px;
  }

  .container-15 {
    padding: 20px;
  }

  .container-15._2 {
    background-color: #86ddbf;
  }

  .button-primary-4 {
    text-align: center;
    align-self: center;
  }

  .section-8 {
    margin-top: -250px;
  }

  .heading-11 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .grid-7 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 20px;
  }

  .paragraph-9 {
    font-size: 16px;
  }

  .paragraph-9.white {
    text-align: left;
    align-self: center;
    font-size: 18px;
  }

  .paragraph-9.white.h {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .heading-12 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .div-block-31 {
    min-height: 35vh;
  }

  .div-block-31.image.detail {
    background-image: url('https://grid.com/web/images/car.jpg');
    background-position: 50% 100%;
    background-size: cover;
  }

  .div-block-31.image.zoek {
    background-position: 50%, 100%;
    background-repeat: repeat-x, repeat;
    background-size: contain, auto;
  }

  .div-block-31._2 {
    outline-offset: 0px;
    outline: 0 #333;
    min-height: 35vh;
  }

  .heading-13 {
    font-size: 28px;
  }

  .section_contact {
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .columns-5 {
    flex-direction: column;
  }

  .wg-element {
    align-items: center;
    width: 50%;
    padding-top: 10px;
  }

  .div-block-33 {
    justify-content: center;
    padding-bottom: 5px;
  }

  .flex-block {
    justify-content: center;
  }

  .footer-link-2.bottom {
    color: #989898;
    font-size: 14px;
  }

  .div-block-34 {
    text-align: center;
    padding-bottom: 5px;
  }

  .div-block-35 {
    justify-content: center;
  }

  .wg-selector-1.s2, .wg-selector-1-2.s2 {
    padding-right: 10px;
  }

  .footer_links {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .footer_bottom-w {
    grid-template-rows: auto auto;
  }

  .footer-link-3.bottom {
    color: #989898;
    text-align: center;
    justify-content: center;
    padding-bottom: 5px;
    font-size: 14px;
    display: flex;
  }

  .wg-element-2 {
    align-items: center;
    width: 50%;
    padding-top: 10px;
  }

  .footer_link-text {
    font-size: 1rem;
  }

  .grid-8 {
    grid-template-rows: auto auto auto;
  }

  .heading-18 {
    margin-top: 20px;
  }

  .div-block-36 {
    padding: 10px;
  }

  .container-19 {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .image-21 {
    object-fit: cover;
    height: 37.4063%;
    position: static;
  }

  .image-24 {
    height: 100px;
  }

  .grid-9 {
    padding-top: 20px;
  }

  .text-block-15 {
    text-align: center;
  }

  .container-22 {
    max-width: 728px;
  }

  .hero-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -40px;
  }

  .hero-split {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .section-wide {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-wide.releases {
    padding-bottom: 160px;
  }

  .section-wide.black {
    background-image: url('https://grid.com/web/images/GRID-map2.jpg');
    background-position: 50%;
    background-size: auto;
    margin-top: 0;
    padding: 0 20px 20px;
  }

  .section-wide.black.clear {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-wide.releases {
    min-height: auto;
    padding-bottom: 180px;
  }

  .container-main-centered {
    padding: 20px;
  }

  .container-main-centered.bottom {
    max-width: 100%;
    margin: -160px 20px 20px;
  }

  .margin-xhuge {
    margin: 6rem;
  }

  .padding-huge {
    padding: 5rem;
  }

  .padding-medium {
    padding: 1.5rem;
  }

  .padding-xhuge {
    padding: 6rem;
  }

  .margin-large {
    margin: 2.5rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .margin-xxlarge {
    margin: 4rem;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-xxhuge {
    padding: 8rem;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .fs-styleguide_section {
    grid-column-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .margin-medium {
    margin: 1.5rem;
  }

  .fs-styleguide_2-col {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-xlarge {
    padding: 3rem;
  }

  .padding-large {
    padding: 2.5rem;
  }

  .margin-huge {
    margin: 5rem;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .container-medium.flex_bottom_space_ib {
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .heading-style-h4.is-resp-align-center {
    text-align: center;
  }

  .heading-style-h1.bigger {
    font-size: 6rem;
  }

  .fs-styleguide_1-col {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-xxhuge {
    margin: 8rem;
  }

  .fs-styleguide_3-col {
    grid-template-columns: 1fr;
  }

  .fs-styleguide_heading-medium {
    font-size: 3rem;
  }

  .nav_button {
    color: #fff;
  }

  .padding-xxlarge {
    padding: 4rem;
  }

  .margin-xlarge {
    margin: 3rem;
  }

  .nav_app {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    border-style: none;
    border-radius: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    display: flex;
  }

  .nav_section {
    margin-bottom: 0;
  }

  .app_cta_button {
    margin-top: 3rem;
  }

  .nav_component-3 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav_menu-button {
    color: #0385ce;
    padding: 1.25rem;
  }

  .nav_menu-button.w--open {
    z-index: 2;
    color: #0385ce;
    background-color: #ffffff0d;
    padding: 1.25rem;
  }

  ._3_block_grid_wrapper {
    grid-template-columns: 1fr;
  }

  .video_modal_wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .nav_link-1 {
    padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  }

  .nav_link-1.hide_desktop {
    font-size: 1.1rem;
    display: block;
  }

  .nav_link-1.hide_desktop.black {
    color: #1a1b1f;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    font-size: 1.2rem;
  }

  .nav_link-1.hide_desktop.small {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
  }

  .nav_menu-3 {
    background-color: #fff;
    height: 100vh;
    padding: 5rem 1.25rem 2rem;
    overflow: auto;
  }

  .nav_section_title {
    color: #0dbc81;
    font-size: 1.2rem;
  }

  .image_installatie_service {
    width: 100%;
    height: 25rem;
    display: none;
  }

  .image-46 {
    border-radius: 2rem;
    max-height: 25rem;
    padding: 1.5rem;
    left: 0;
  }

  .image-46.reverse {
    max-width: 35rem;
    left: auto;
  }

  .div-block-93 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
  }

  .flex_wrapper {
    flex-flow: column;
  }

  .flex_wrapper.reverse {
    flex-flow: column-reverse;
  }

  .flex_wrapper.bottom, .content_image_component {
    flex-flow: column;
  }

  .section-13.offer_optin {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .tab_content_image_wrapper {
    min-width: 15rem;
  }

  .content_wrapper, .content_wrapper.form.a_slide {
    width: 100%;
  }

  .content_wrapper.text-color-white.margin-bottom.margin-custom-775.a_slide {
    width: 65%;
  }

  .content_wrapper.resp_100w {
    width: 100%;
  }

  .column_grid {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .column_grid._2.image_padding {
    grid-template-columns: 1fr;
  }

  .charge_badge_wrapper {
    flex-flow: column;
  }

  .menu_icon_wrapper {
    display: none;
  }

  .video_embed {
    width: 100%;
  }

  .benefits_content_wrapper {
    z-index: 2;
    flex-flow: column;
    position: relative;
  }

  .button-4.nav {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: none;
  }

  .textblock_green {
    background-image: none;
    padding-right: 2.5rem;
  }

  .lottie-animation-3 {
    width: 2rem;
    height: 2rem;
  }

  .tab_option {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .mockup_wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  .mockup_wrapper.blue {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .app_cta_button-2 {
    margin-top: 3rem;
  }

  .section-15._4rem.image_slider {
    overflow: hidden;
  }

  .about_cta_component {
    flex-flow: column;
  }

  .accordion-paragraph---brix-2 {
    max-width: 100%;
  }

  .about_ticker_image {
    object-fit: cover;
    width: 100%;
    height: 25rem;
  }

  .section-16.offer_optin {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .accordion-item-title---brix {
    max-width: 88%;
  }
}

@media screen and (max-width: 2915px) {
  .grid {
    flex: 1;
    display: block;
  }

  .div-block-2 {
    flex: 1;
    margin: 10px;
  }

  .h3 {
    font-size: 28px;
  }

  .h3.on-green.phone {
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
  }

  .h3.on-green.phone.small {
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
  }

  .h3.green.phone {
    font-size: 24px;
    font-weight: 400;
  }

  .h3.phone {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
  }

  .container {
    margin-top: 30px;
  }

  .grid-3 {
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    padding: 0 30px;
  }

  .section-2 {
    padding: 18px 10px;
  }

  .section-2.black {
    align-items: center;
    padding: 10px 20px;
    display: flex;
  }

  .section-2.black.clear {
    min-height: 20vh;
    padding-bottom: 20px;
  }

  .div-block-3 {
    align-self: auto;
  }

  .div-block-3.phone {
    flex-direction: column;
    display: flex;
  }

  .image-2 {
    object-fit: cover;
    object-position: 0% 50%;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .navbar-logo-left-container {
    max-width: 100%;
  }

  .navbar-logo-left-container.shadow-three {
    padding: 10px 20px;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .nav-menu-two {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px #0000000d;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-divider {
    width: 200px;
    max-width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .button-primary {
    font-size: 16px;
  }

  .button-primary.on-green {
    text-align: left;
    font-size: 16px;
  }

  .button-primary.centre {
    font-size: 16px;
  }

  .image-3 {
    max-width: 100px;
  }

  .paragraph-usp {
    align-self: center;
  }

  .footer-subscribe {
    padding: 0 15px;
  }

  .footer-form-container-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-form-title {
    margin-right: 0;
  }

  .footer-form-block-two {
    width: 100%;
    margin-top: 20px;
  }

  .footer-form-input {
    width: 100%;
  }

  .footer-block-three {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-link-three {
    margin-bottom: 10px;
  }

  .footer-social-block-three {
    margin-top: 10px;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-copyright {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-legal-block {
    margin-top: 15px;
    margin-left: -24px;
  }

  .footer-legal-link {
    font-size: 14px;
    line-height: 20px;
  }

  .hero-heading-left {
    padding: 0 15px;
  }

  .container-6 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .hero-split-long {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero-split-long.map {
    background-position: 50% 80%;
    align-items: stretch;
    min-width: 100%;
  }

  .hero-split-long.map._2 {
    background-position: 50% 0;
  }

  .hero-split-long.tablet {
    background-color: var(--0dbc81);
  }

  .heading-2 {
    color: #fff;
    align-self: flex-start;
    margin-bottom: 0;
    font-size: 6vh;
  }

  .image-4 {
    object-fit: contain;
  }

  .div-block-7 {
    margin-top: -42px;
    padding-top: 0;
  }

  .button-secondary {
    font-size: 7vw;
  }

  .features-metrics {
    padding: 60px 15px;
  }

  .features-wrapper {
    flex-wrap: wrap;
    margin-bottom: -40px;
  }

  .features-block {
    width: 50%;
    margin-bottom: 40px;
  }

  .paragraph.phone {
    font-size: 14px;
  }

  .h2-on-green {
    font-family: Source Sans Pro, sans-serif;
  }

  .div-block-13.phone {
    flex-direction: column;
  }

  .image-10 {
    object-fit: contain;
    flex: 0 auto;
  }

  .grid-4 {
    grid-column-gap: 38px;
    grid-row-gap: 68px;
    grid-template: ". ."
                   ". ."
                   "Area-4 Area-4"
                   ". ."
                   "Area-5 Area-5"
                   "Area-6 ."
                   / 1fr;
  }

  .div-block-16, .div-block-16._2 {
    min-height: 55vh;
  }

  .div-block-16.wit {
    background-color: #C1F11D;
    height: 106%;
  }

  .div-block-16.image.auto {
    background-repeat: repeat-x;
    height: 400px;
  }

  .div-block-16.image.detail {
    background-repeat: repeat-x, repeat;
  }

  .div-block-16.image.zoek {
    background-repeat: no-repeat, repeat;
  }

  .div-block-16.image.voorkeur {
    background-color: var(--white-smoke);
    background-image: url('https://grid.com/web/images/macbook-car-small.webp');
    background-position: 50%;
    background-repeat: repeat-x;
    background-size: contain;
  }

  .div-block-16.image.koffie {
    background-position: 50%;
  }

  .div-block-16.image.app {
    background-image: url('https://grid.com/web/images/iphone-14-pro-small.webp');
  }

  .grid-5 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-bottom: 0px;
  }

  .grid-5._4-blocks {
    padding-top: 0;
  }

  .container-10 {
    padding: 19px;
  }

  .container-10._2 {
    background-color: #C1F11D;
  }

  .container-10.map {
    flex: 1;
    align-self: stretch;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .container-10.map.nog-border {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }

  .container-10._5 {
    background-color: #C1F11D;
  }

  .paragraph-7 {
    font-size: 14px;
  }

  .paragraph-7.white {
    max-width: 100%;
    padding-right: 0;
  }

  .paragraph-7.white.h {
    max-width: 100%;
    font-size: 14px;
  }

  .paragraph-7.white.inleiding {
    max-width: 100%;
  }

  .heading-5 {
    margin-top: 0;
    font-size: 20px;
  }

  .heading-5.green {
    text-align: left;
  }

  .heading-5.green.centre {
    text-align: center;
    margin-top: 0;
  }

  .heading-5.green.links {
    color: #fff;
    -webkit-text-stroke-color: #fff;
    text-transform: none;
  }

  .heading-5.green.left {
    color: var(--medium-sea-green);
    -webkit-text-stroke-color: var(--medium-sea-green);
  }

  .title {
    font-size: 35px;
  }

  .title.heading {
    font-size: 80px;
  }

  .title.green {
    color: var(--medium-sea-green);
  }

  .title.zwart {
    font-size: 35px;
  }

  .title.header {
    max-width: 85%;
    margin-bottom: 10px;
  }

  .section-5 {
    /* margin-top: -70px; */
  }

  .div-block-18 {
    padding-bottom: 40px;
  }

  .div-block-18.blog {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .div-block-19 {
    grid-column-gap: 10px;
  }

  .div-block-20 {
    width: 40%;
  }

  .div-block-21 {
    grid-column-gap: 0px;
  }

  .footer-light {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-bottom-2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-copyright-2 {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-social-block-two {
    margin-top: 20px;
  }

  .footer-social-link {
    margin-left: 20px;
  }

  .footer-header {
    font-size: 18px;
  }

  .container-tekst.tekstpagina {
    padding-top: 40px;
  }

  .container-tekst.blog {
    padding-left: 0;
    padding-right: 0;
  }

  .link-back {
    font-size: 16px;
  }

  .section-7 {
    margin-top: -90px;
  }

  .title-2, .title-2.zwart {
    font-size: 35px;
  }

  .container-15 {
    padding: 19px;
  }

  .container-15._2 {
    background-color: #86ddbf;
  }

  .button-primary-4.on-green {
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
  }

  .section-8 {
    margin-top: -90px;
  }

  .grid-7 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-bottom: 10px;
  }

  .paragraph-9 {
    font-size: 14px;
  }

  .paragraph-9.white {
    max-width: 100%;
    padding-right: 0;
  }

  .paragraph-9.white.h {
    max-width: 100%;
    font-size: 14px;
  }

  .div-block-31 {
    min-height: 20vh;
  }

  .div-block-31.image.voorkeur {
    background-color: #f0f0f0;
    background-image: url('https://grid.com/web/images/MacBook-Air-2022-car.png');
    background-position: 50%;
    background-repeat: repeat-x;
    background-size: contain;
  }

  .div-block-31.image.detail {
    background-repeat: repeat-x, repeat;
  }

  .div-block-31.image.koffie {
    background-position: 50%;
  }

  .div-block-31.image.zoek {
    background-repeat: no-repeat, repeat;
  }

  .div-block-31._2 {
    min-height: 20vh;
  }

  .div-block-31.wit {
    background-color: #0dbc81;
  }

  .heading-13 {
    margin-top: 0;
    font-size: 24px;
  }

  .link-block-8 {
    text-decoration: none;
  }

  .columns-4 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .text-block-6 {
    margin-bottom: 10px;
    font-size: 28px;
  }

  .heading-16 {
    margin-bottom: 20px;
    font-size: 45px;
  }

  .section_contact {
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-header-2 {
    font-size: 18px;
  }

  .wg-element {
    width: 100%;
  }

  .cookie-modal_closebutton {
    margin-right: 1.25rem;
  }

  .cookie-preference_wrapper {
    padding-top: 2rem;
  }

  .cookie-modal_closebutton-3 {
    margin-right: 1.25rem;
  }

  .cookie-modal_closebutton-3.is-sticky {
    top: -1rem;
  }

  .cookie-modal_content-wrap {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .cookie-modal_button-wrap {
    padding: 1.5rem;
  }

  .cookie-modal_button-wrap.in-pref-manger {
    margin: 1.5rem -1.5rem -2rem;
    padding-top: 1.25rem;
    position: sticky;
    bottom: -2rem;
  }

  .wg-element-2 {
    width: 100%;
  }

  .cookie-modal_form {
    padding-top: .75rem;
  }

  .grid-8 {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding-top: 10px;
  }

  .hero-heading-left-2 {
    padding: 60px 15px;
  }

  .image-29 {
    margin-left: -10px;
  }

  .section-wide {
    min-height: 50vh;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .section-wide.releases {
    padding-bottom: 160px;
  }

  .section-wide.black {
    align-items: center;
    padding: 10px 20px;
    display: flex;
  }

  .section-wide.black.clear {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
  }

  .section-wide.releases {
    min-height: auto;
    padding-bottom: 180px;
  }

  .container-main-centered {
    max-width: 100%;
    padding: 19px;
  }

  .container-main-centered.bottom {
    flex: 1;
    padding: 20px;
  }

  .heading-31, .heading-32 {
    font-size: 3rem;
  }

  .margin-xhuge {
    margin: 4rem;
  }

  .padding-huge {
    padding: 3.5rem;
  }

  .padding-medium {
    padding: 1.25rem;
  }

  .padding-xhuge {
    padding: 4rem;
  }

  .margin-large {
    margin: 1.5rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .margin-xxlarge {
    margin: 3rem;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-xxhuge {
    padding: 4.5rem;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .heading-style-h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .icon-1x1-medium._4rem {
    width: 3rem;
    height: 3rem;
  }

  .margin-medium {
    margin: 1.25rem;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-xlarge {
    padding: 2rem;
  }

  .padding-large {
    padding: 1.5rem;
  }

  .margin-huge {
    margin: 3.5rem;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .text-size-large {
    font-size: 1.25rem;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .fs-styleguide_section-header {
    font-size: .875rem;
  }

  .heading-style-h2, .heading-style-h2.text-color-green {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .heading-style-h2.smaller {
    font-size: 2.3rem;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .heading-style-h4 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .heading-style-h4.text-color-green._2rem {
    font-size: 2rem;
    line-height: 3rem;
  }

  .heading-style-h3 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .heading-style-h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .heading-style-h1.text-color-green {
    line-height: 3.5rem;
  }

  .heading-style-h1.bigger {
    font-size: 5rem;
    line-height: 6rem;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-xxhuge {
    margin: 4.5rem;
  }

  .fs-styleguide_heading-medium {
    font-size: 2rem;
  }

  .padding-xxlarge {
    padding: 3rem;
  }

  .text-style-nowrap {
    white-space: normal;
  }

  .margin-xlarge {
    margin: 2rem;
  }

  .heading-style-h5 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .text-size-medium.padding_lr_6rem.text-weight-light {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-global-2 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .nav_app {
    display: flex;
  }

  .app_cta_button {
    max-width: 100%;
  }

  .nav_component-3 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .nav_menu-button {
    padding: 1rem;
  }

  .nav_menu-button.w--open {
    flex: none;
  }

  .bg_shape_hero {
    height: 12rem;
  }

  .nav_link-1 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .nav_menu-3 {
    width: 50vw;
    max-height: 100vh;
  }

  .tabs-menu-3 {
    flex-flow: column;
  }

  .div-block-93 {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .section-13.title {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .button_wrapper {
    flex-flow: column;
    width: 100%;
    display: flex;
  }

  .content_wrapper.text-color-white.margin-bottom.margin-custom-775.a_slide {
    width: 100%;
  }

  .column_grid {
    grid-template-columns: 1fr;
  }

  .nav_brand-image {
    max-width: 14rem;
  }

  .benefits_content_wrapper.a_slide {
    justify-content: flex-start;
    align-items: center;
  }

  .tab_content_component {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .textblock_green {
    background-image: none;
    padding-right: 1.5rem;
  }

  .cookie-modal_closebutton-4 {
    margin-right: 1.25rem;
  }

  .hero_col.bg_greenbox, .hero_col.bg_ctpark, .hero_col.glp_1, .hero_col.glp_3 {
    display: none;
  }

  .tab_content_text.mw_65.gap2rem, .app_cta_button-2 {
    max-width: 100%;
  }

  .laadpaal_specs_grid {
    grid-template-columns: 1fr;
  }

  .heading-style-h4-3 {
    font-size: 1rem;
  }

  .faq_grid {
    grid-template-columns: 1fr;
  }

  .accordion-paragraph---brix-2 {
    max-width: 100%;
    font-size: 16px;
    line-height: 1.6;
  }

  .heading-style-h3-2 {
    font-size: 1.5rem;
  }

  .list_image {
    width: 16rem;
    height: 12rem;
  }

  .heading-style-h3-3 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .accordion-item-title---brix {
    max-width: 80%;
    font-size: 18px;
  }

  .column_grid-2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .div-block-2 {
    margin: 15px 0;
  }

  .h3.on-green.phone.small {
    font-size: 14px;
  }

  .h3.green {
    line-height: 150%;
  }

  .h3.phone {
    font-size: 14px;
  }

  .container {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 0;
  }

  .container.centre {
    text-align: center;
    margin-bottom: 0;
  }

  .grid-2 {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-2.left-block {
    grid-template-rows: auto auto;
  }

  .grid-2.left-block.padding {
    padding: 10px;
  }

  .grid-3 {
    grid-template-columns: 1fr;
  }

  .section-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .section-2.black {
    background-position: 50%;
    min-height: 40vh;
    padding-top: 10px;
  }

  .section-2.black.clear {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .section-2.green {
    padding-top: 10px;
  }

  .section-2.no-space {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .div-block-3.on-phone.small {
    min-width: 100%;
  }

  .div-block-3.phone {
    align-self: auto;
    min-width: 80vw;
  }

  .navbar-logo-left-container.shadow-three {
    padding: 10px 20px;
  }

  .container-4 {
    max-width: none;
  }

  .nav-menu-two {
    flex-direction: column;
  }

  .button-primary {
    margin-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .button-primary.on-green {
    padding-left: 12px;
    padding-right: 12px;
  }

  .button-primary.centre {
    margin-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .image-3 {
    max-width: 100px;
  }

  .footer-form-two {
    padding: 20px;
  }

  .footer-form-block-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-form-input {
    margin-bottom: 10px;
    padding-right: 20px;
  }

  .button-primary-2.footer-form-button {
    position: relative;
    right: 0;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .container-6 {
    max-width: none;
  }

  .hero-split-long {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-split-long.map {
    background-image: url('https://grid.com/web/images/Group-276.png');
    background-position: 50% 80%;
    background-size: cover;
    justify-content: flex-end;
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-split-long.phone {
    background-color: var(--0dbc81);
    min-height: 100%;
  }

  .heading-2.tablet.phone {
    font-size: 38px;
  }

  .heading-2.phone {
    font-size: 40px;
  }

  .div-block-5 {
    justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .text-block {
    margin-top: -11px;
    padding-top: 0;
    font-size: 26px;
  }

  .image-5.phone {
    max-width: 100%;
    padding-top: 14px;
  }

  .div-block-6.phone {
    flex-direction: column;
  }

  .button-secondary, .h2.on-green {
    font-size: 24px;
  }

  .h2.on-green.phone {
    font-size: 28px;
  }

  .h2.on-green.phone.small {
    font-size: 24px;
  }

  .heading-3 {
    color: var(--medium-sea-green);
    font-size: 26px;
  }

  .container-8 {
    max-width: none;
  }

  .slide-3._3 {
    min-height: 20vh;
  }

  .div-block-13 {
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
  }

  .div-block-13.phone {
    min-width: 80vw;
  }

  .image-10 {
    padding-top: 8px;
  }

  .grid-4 {
    grid-column-gap: 38px;
    grid-row-gap: 20px;
    grid-template: ". ."
                   ". Area-4"
                   "Area-5 ."
                   / 1fr;
  }

  .div-block-16, .div-block-16._2 {
    min-height: 15vh;
  }

  .div-block-16.image.detail {
    background-color: var(--white-smoke);
    background-position: 50% 100%;
  }

  .div-block-16.image.zoek {
    background-color: var(--white-smoke);
    background-image: url('https://grid.com/web/images/MacBook-Air-2022.png');
    background-repeat: repeat-x;
    display: none;
  }

  .div-block-16.image.app {
    background-image: url('https://grid.com/web/images/iphone-14-pro-small.webp');
    background-repeat: repeat-x;
    margin-bottom: 0;
  }

  .grid-5 {
    grid-row-gap: 20px;
    grid-template-rows: auto auto auto auto auto auto auto;
    padding: 10px;
  }

  .grid-5._4-blocks {
    padding-top: 10px;
  }

  .container-10 {
    background-color: #0dbc8100;
  }

  .container-10.header {
    padding: 0 10px 38px;
  }

  .container-10.map {
    margin-top: 10px;
    padding: 0 5px 8px;
  }

  .container-10.green {
    background-color: var(--medium-sea-green);
  }

  .container-10.blog {
    padding: 13px;
  }

  .container-10.white {
    padding: 10px 0;
  }

  .container-10.white.no-space {
    padding-top: 10px;
  }

  .heading-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .paragraph-7 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .paragraph-7.white {
    text-align: left;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }

  .paragraph-7.white.left {
    color: #fff;
  }

  .paragraph-7.white.map {
    max-width: 90%;
    font-size: 16px;
  }

  .paragraph-7.white.map.centre {
    padding-bottom: 10px;
    padding-left: 0;
  }

  .paragraph-7.white.h {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }

  .paragraph-7.white.inleiding {
    text-align: left;
    align-self: auto;
    font-size: 16px;
  }

  .heading-5 {
    font-size: 26px;
  }

  .heading-5.green.centre {
    max-width: none;
  }

  .heading-5.green.left {
    font-size: 26px;
  }

  .title.heading {
    font-size: 40px;
  }

  .title.heading.green {
    color: var(--medium-sea-green);
    font-size: 60px;
  }

  .title.header {
    max-width: none;
    margin-bottom: 10px;
    padding-bottom: 0;
    left: -17%;
    position: relative;
    
  }

  .title.black {
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 32px;
  }

  .container-11.map {
    padding-left: 17px;
    padding-right: 17px;
  }

  .div-block-17 {
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    flex-direction: column;
    width: 100%;
    min-width: 100px;
  }

  .section-5 {
    margin-top: -30vh;
  }

  .div-block-18 {
    padding-bottom: 0;
  }

  .div-block-20 {
    grid-column-gap: 0px;
    text-transform: none;
    flex-direction: row;
    flex: 1;
    align-items: center;
    min-width: 70px;
    text-decoration: none;
    display: flex;
  }

  .image-15 {
    max-width: 32px;
    padding-right: 0;
  }

  .div-block-21 {
    align-self: stretch;
  }

  .div-block-22.hidden, .div-block-23.hidden {
    display: none;
  }

  .footer-light {
    padding-top: 14px;
  }

  .container-12 {
    max-width: none;
  }

  .footer-wrapper-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-block-two {
    margin-top: 40px;
  }

  .footer-bottom-2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-social-block-two {
    margin-top: 20px;
  }

  .footer-header {
    justify-content: center;
    font-size: 15px;
  }

  .columns-2 {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .image-17 {
    display: block;
  }

  .container-tekst.blog {
    padding-top: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .heading-6, .heading-7 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .section-7 {
    padding-left: 20px;
    padding-right: 10px;
  }

  .utility-page-wrap {
    background-position: 40%;
  }

  .section-8 {
    margin-top: -30vh;
  }

  .grid-7 {
    grid-row-gap: 20px;
    grid-template-rows: auto auto auto auto auto auto auto;
    padding: 10px;
  }

  .paragraph-9 {
    font-size: 16px;
  }

  .paragraph-9.white {
    text-align: left;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }

  .paragraph-9.white.h {
    font-size: 16px;
  }

  .div-block-31 {
    min-height: 15vh;
  }

  .div-block-31.image.detail {
    background-color: #f0f0f0;
    background-position: 50% 100%;
  }

  .div-block-31.image.zoek {
    background-color: #f0f0f0;
    background-image: none;
    background-repeat: repeat-x;
    display: none;
  }

  .div-block-31._2 {
    min-height: 15vh;
  }

  .heading-13 {
    font-size: 26px;
  }

  .homepage-link {
    padding-bottom: 10px;
  }

  .columns-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-block-6 {
    font-size: 24px;
  }

  .section_contact {
    padding: 10px;
  }

  .footer-header-2 {
    justify-content: center;
    font-size: 15px;
  }

  .wg-element {
    height: auto;
  }

  .columns-6 {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .image-20 {
    display: block;
  }

  .cookie-modal_closebutton {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .footer_links {
    flex-wrap: wrap;
  }

  .cookie-preference_wrapper {
    max-width: 100%;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .cookie-modal_closebutton-3 {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .cookie-modal_content-wrap.is-small {
    padding-bottom: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .cookie-popup_component-2 {
    max-width: 100%;
    inset: auto 0% 0%;
  }

  .cookie-preference_component {
    align-items: flex-end;
  }

  .cookie-modal_button-wrap.in-pref-manger {
    bottom: 0;
  }

  .cookie-modal_button-wrap.is-small {
    justify-content: space-between;
    padding: 1.25rem;
  }

  .cookie-modal_button-3 {
    flex: auto;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }

  .wg-element-2 {
    height: auto;
  }

  .footer_top {
    grid-template-columns: .25fr .75fr;
  }

  .cookie-modal_component {
    max-width: 100%;
  }

  .heading-17.white {
    color: var(--0dbc81);
  }

  .grid-8 {
    padding-top: 0;
  }

  .div-block-36 {
    padding-top: 5px;
  }

  .container-19 {
    padding: 10px;
  }

  .div-block-38 {
    width: 100%;
  }

  .grid-9 {
    grid-template-rows: auto auto;
    margin-bottom: 40px;
    padding-bottom: 20px;
  }

  .text-block-15 {
    font-size: 15px;
  }

  .container-22 {
    max-width: none;
  }

  .section-wide {
    background-color: #1a1b1f;
    background-image: linear-gradient(#0000004a, #0000004a), url('https://grid.com/web/images/Header.webp');
    background-position: 0 0, 50%;
    background-size: auto, cover;
  }

  .section-wide.black {
    background-position: 50%;
    min-height: 40vh;
    padding-top: 10px;
  }

  .section-wide.black.clear {
    min-height: auto;
    padding: 10px;
    display: block;
  }

  .section-wide.releases {
    padding-bottom: 40px;
  }

  .container-main-centered {
    padding: 10px;
  }

  .container-main-centered.bottom {
    flex: 1;
    margin: 10px;
  }

  .heading-31, .heading-32 {
    font-size: 3rem;
  }

  .main-wrapper {
    overflow: hidden;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .button-2 {
    width: 100%;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .fs-styleguide_row {
    flex-wrap: wrap;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .container-medium {
    max-width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .heading-style-h2, .heading-style-h2.text-color-green {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .heading-style-h2.smaller {
    font-size: 2.3rem;
    line-height: 3rem;
  }

  .heading-style-h2.max-width-60 {
    max-width: 100%;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .container-large {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .heading-style-h4 {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .heading-style-h4.text-color-green._2rem {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .heading-style-h4.is-resp-align-center {
    line-height: 1.8rem;
  }

  .heading-style-h4.is-resp-align-center.laadpaal_title {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .heading-style-h4._1-1_mobile {
    font-size: 1.1rem;
    line-height: 2rem;
  }

  .heading-style-h3 {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .heading-style-h3._2-5rem.bold {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .heading-style-h1 {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  .heading-style-h1.text-color-green {
    word-break: normal;
    font-size: 2.8rem;
    line-height: 3rem;
  }

  .heading-style-h1.text-color-green.image_padding {
    margin-left: 0;
    font-size: 2.25rem;
  }

  .heading-style-h1.text-color-green.big_word_mobilefix {
    font-size: 2.4rem;
  }

  .heading-style-h1.bigger {
    font-size: 3rem;
    line-height: 3rem;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .c--designed-by-m7 {
    justify-content: flex-start;
  }

  .heading-style-h5 {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .c--m7-footer-overlay {
    justify-content: flex-start;
  }

  .text-size-medium.padding_lr_6rem.text-weight-light {
    padding-left: 0;
    padding-right: 0;
  }

  .text-size-medium._1-1 {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .text-size-medium._1-1.mobile_0-9 {
    font-size: .9rem;
    line-height: 1.3rem;
  }

  .nav_app {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    border-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem 0;
    font-weight: 400;
    display: flex;
  }

  .nav_app:hover {
    background-color: #0dbc8100;
  }

  .nav_section {
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .app_cta_button {
    width: 100%;
    max-width: 100%;
  }

  .app_cta_button.green {
    grid-column-gap: .35rem;
    grid-row-gap: .35rem;
    margin-top: 3.5rem;
    padding: .5rem 1rem;
  }

  .nav_component-3 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .div-block-94 {
    text-align: left;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .nav_menu-button {
    z-index: 2;
    color: #dfbe7d;
    padding: 0;
  }

  .nav_menu-button.w--open {
    z-index: 2;
    color: #fff;
    background-color: #e9f0f400;
    padding-left: 0;
    padding-right: 0;
  }

  .bg_shape_hero {
    z-index: 2;
    background-position: 50% 103%;
    background-size: 100%;
    height: 8rem;
    position: relative;
  }

  .video_modal_wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
    display: none;
  }

  .laadpaal_price {
    background-color: #0dbc8126;
    border-radius: 2rem;
    padding: 0 .5rem;
    display: flex;
  }

  .button-3 {
    width: 100%;
  }

  .button-3.hero_btn_mobile {
    display: block;
  }

  .button-3.hide_mobile {
    display: none;
  }

  .nav_link-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    font-size: 1rem;
  }

  .nav_link-1.hide_desktop {
    margin-left: 0;
    margin-right: 0;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }

  .nav_link-1.hide_desktop.black {
    color: #1a1b1f;
    font-size: 1.2rem;
  }

  .nav_link-1.hide_desktop.small {
    font-size: 1rem;
  }

  .grid_ready_mobile {
    z-index: 2;
    background-color: #fff;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: block;
    position: relative;
  }

  .grid_overlay {
    background-position: 55%;
    background-repeat: no-repeat;
    background-size: 500%;
  }

  .grid_overlay.ticker_grid_software {
    background-size: 300%;
  }

  .rich_text_list {
    padding-left: 0;
  }

  .nav_line_divider {
    opacity: .2;
    background-color: #0dbc81;
    flex: 1;
    height: 1px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .nav_menu-3 {
    z-index: 1;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    background-color: #fff;
    flex-flow: column;
    flex: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75vw;
    height: 100vh;
    max-height: none;
    padding-top: 5rem;
    padding-bottom: 1rem;
    display: flex;
    box-shadow: 0 2.5rem 2rem -20px #04041f1a;
  }

  .nav_section_title {
    color: #0dbc81;
    font-size: 1.2rem;
  }

  .tabs-menu-3 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .div-block-85 {
    display: none;
  }

  .image-46 {
    object-fit: contain;
    min-height: 15rem;
    max-height: 20rem;
  }

  .image-46.reverse {
    max-width: 100%;
  }

  .div-block-93 {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .flex_wrapper {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .flex_wrapper.reverse {
    padding-left: 0;
    padding-right: 0;
  }

  .flex_wrapper.bottom {
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
  }

  .text-block-26 {
    font-weight: 600;
  }

  .content_image_component {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .content_image_component.gap_6rem {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }

  .list_item_number {
    flex: none;
  }

  .section-13 {
    height: 100%;
    min-height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .section-13.grid_subtitle {
    display: none;
  }

  .section-13.top_6rem {
    padding-top: 4rem;
  }

  .section-13.hero_laadpaal_avrg {
    background-position: 59% 59%;
    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 0;
  }

  .section-13.title {
    background-size: cover;
    margin-top: 0;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .section-13.form {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .padding-global-3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .button_wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-flow: column;
    width: 100%;
  }

  .button_wrapper.ref_icons {
    flex-flow: row;
  }

  .ref_img_mobile {
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 0 0;
    background-size: auto;
    border-radius: 1rem;
    width: 100%;
    height: 10rem;
    display: flex;
  }

  .ref_img_mobile.greenbox {
    background-image: url('https://grid.com/web/images/grid-charger.webp');
    background-position: 50%;
    background-size: cover;
  }

  .ref_img_mobile.ct_park {
    background-image: url('https://grid.com/web/images/DJI_0087-2048x1534.jpg');
    background-position: 50%;
    background-size: cover;
  }

  .ref_img_mobile.ratio7 {
    background-image: url('https://grid.com/web/images/20240514_134644-min.jpg');
    background-position: 50%;
    background-size: cover;
  }

  .ref_img_mobile.wds_city {
    background-image: url('https://grid.com/web/images/WDS-City-publieke-laadpalen-min.png');
    background-position: 50%;
    background-size: cover;
  }

  .footer_link-text-2 {
    font-size: 1rem;
  }

  .grid_software_usp.grey_bg {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .9rem;
    line-height: 1.6rem;
  }

  .laadpaal_stats_mobile_wrapper {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    flex-flow: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .content_wrapper.form.a_slide {
    width: 100%;
  }

  .content_wrapper.text-color-white.margin-bottom.margin-custom-775 {
    width: 100%;
    margin-bottom: 0;
  }

  .content_wrapper.text-color-white.margin-bottom.margin-custom-775.a_slide, .content_wrapper.align_center.mw_80 {
    width: 100%;
  }

  .content_wrapper.align_center.mw_80.text-color-white._1-5rem.a_slide {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
  }

  .content_wrapper.mw_100._1-5rem {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .content_wrapper.mw_100.image_padding {
    padding-left: 0;
  }

  .column_grid {
    grid-template-columns: 1fr;
  }

  .column_grid._2.image_padding {
    padding-left: 0;
    padding-right: 0;
  }

  .image-44 {
    width: 5rem;
    height: 4.5rem;
  }

  .charge_badge_wrapper {
    grid-column-gap: .2rem;
    grid-row-gap: .2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .nav_brand-image {
    max-width: 10rem;
  }

  .tab_content_image {
    height: 18rem;
  }

  .tab_content_image.io7 {
    height: 20rem;
  }

  .tab_content_image.wds {
    height: 16rem;
  }

  .text-block-25 {
    font-size: .8rem;
  }

  .container_nav {
    height: 4rem;
    padding: .5rem 1.25rem;
  }

  .video_embed {
    height: 50vh;
  }

  .benefits_content_wrapper {
    z-index: 2;
    position: relative;
  }

  .benefits_content_wrapper.a_slide {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    padding: 1.5rem;
  }

  .number_block_float {
    flex: none;
    left: 0;
  }

  .mobile_1rem {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .optin_component_list_item {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tab_content_component {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    flex-flow: column;
    padding: 1.5rem;
  }

  .button-4.nav {
    text-align: center;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    padding-top: .75rem;
    padding-bottom: .75rem;
    display: none;
  }

  .textblock_green {
    background-image: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .div-block-78, .div-block-78:hover {
    width: 4rem;
    height: 4rem;
  }

  .cookie-modal_closebutton-4 {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .lottie-animation-3 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .gird_block_mint.resp_padding_right {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    padding-left: 2.5rem;
  }

  .tab_option {
    text-align: center;
    flex-flow: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .tab_option:hover {
    background-color: #e7f9f300;
  }

  .tab_option.w--current {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: flex-start;
    align-items: center;
  }

  .gird_soft_usp_list._2rem {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .hero_col {
    padding: 1.5rem;
  }

  .hero_col.grey.center_content {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .hero_col.flex_vertical_1rem.grey {
    padding: 1.5rem;
  }

  .hero_col.bg_greenbox, .hero_col.bg_ctpark, .hero_col.glp_1, .hero_col.glp_3 {
    display: none;
  }

  .richtext_image {
    object-fit: contain;
    object-position: 50% 50%;
    height: auto;
  }

  .tab_content_text.mw_65.gap2rem {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    max-width: 100%;
  }

  .list-2 {
    padding-left: 18px;
  }

  .app_cta_button-2 {
    width: 100%;
    max-width: 100%;
  }

  .app_cta_button-2.green {
    grid-column-gap: .35rem;
    grid-row-gap: .35rem;
    margin-top: 3.5rem;
    padding: .5rem 1rem;
  }

  .laadpaal_specs_grid {
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .div-block-95 {
    display: none;
  }

  .image-47 {
    width: 5rem;
    height: 4.5rem;
  }

  .specs_grid_item {
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .heading-style-h4-3 {
    font-size: 1.2rem;
  }

  .faq_grid {
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
    grid-template-columns: 1fr;
  }

  .image_slide_list._2 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .section-15 {
    height: 100%;
    min-height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .about_cta_component {
    margin-top: 2rem;
    padding: 1.5rem;
  }

  .accordion-paragraph---brix-2 {
    max-width: 100%;
    margin-top: .5rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 2;
  }

  .section-16 {
    height: 100%;
    min-height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .section-16.offer_optin.hero {
    padding-top: 8rem;
  }

  .image_slider_component {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .img_slider_gradient_left {
    width: 8rem;
  }

  .heading-style-h3-2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .heading-style-h3-2.text-color-green._2rem {
    line-height: 2.6rem;
  }

  .accordion-item---brix.tabs-accordion---brix {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .accordion-item---brix.tabs-accordion---brix.a_slide {
    margin-bottom: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .open-close-icon-wrapper---brix {
    margin-right: 16px;
  }

  .list_image {
    border-radius: 1rem;
    width: 12rem;
    height: 8rem;
  }

  .heading-style-h3-3 {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .image-48 {
    max-height: 25rem;
  }

  .accordion-trigger---brix {
    padding-bottom: 1rem;
  }

  .img_slider_gradient_right {
    width: 8rem;
  }

  .accordion-item-title---brix {
    max-width: 70%;
    font-size: 1.3rem;
  }

  .cta_ticker {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .content_wrapper-2.mw_100.center.padding-horizontal.padding-huge.a_slide {
    padding-left: 0;
    padding-right: 0;
  }

  .column_grid-2 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adce6 {
  grid-area: 2 / 1 / 3 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adce6 {
  grid-area: 1 / 2 / 2 / 3;
  height: 105%;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adce6 {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adce6 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adce6 {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adce6 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adce6 {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-fa78de3b-bb1f-9f6d-38d5-51cb56632550-117adce6 {
  grid-area: 1 / 1 / 2 / 2;
  /* height: 105%; */
  display: none;
}
/* .w-layout-grid.grid-5._4-blocks {
    display: none;
} */

#w-node-_596b18d4-2bc5-7cc5-0ef0-38855c1ced5f-117adce6 {
  grid-area: 1 / 2 / 2 / 3;
  /* height: 105%; */
  display: none;
}

#w-node-f54bfbb0-2421-5312-840b-3ecae3303885-117adce6 {
  grid-area: 2 / 2 / 3 / 3;
  align-self: auto;
}

#w-node-_81fae34e-2068-60c8-1384-3531e3285d04-e3285d04 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_9daeba24-3778-1d0f-4b75-22ba03d29670-03d2966e {
  grid-area: 2 / 2 / 3 / 3;
}

#w-node-_5c5713fe-5381-bdce-a779-91163e31f011-117adceb {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_5c5713fe-5381-bdce-a779-91163e31f01d-117adceb {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_5c5713fe-5381-bdce-a779-91163e31f029-117adceb {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_5c5713fe-5381-bdce-a779-91163e31f02a-117adceb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_694e0a32-8e0a-829c-b6f3-9ca0084d2ffb-117adceb {
  grid-area: 3 / 2 / 4 / 3;
}

#w-node-fb58664a-6abb-56da-8fb9-69971f8deebb-117adceb {
  grid-area: 2 / 2 / 3 / 3;
  place-self: stretch stretch;
}

#w-node-dfce49df-34ed-ed82-9345-3e23133dbaff-117adceb {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adcec {
  grid-area: 2 / 1 / 3 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adcec {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adcec {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adcec {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adcec {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adcec {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adcec {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-_590f7734-b4aa-40b7-3353-8c550733fb2d-0733fb2b, #w-node-bcb505f5-b874-8b97-dcd1-ad3aaaa7cb16-117adced {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adced {
  grid-area: 2 / 1 / 3 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adced {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adced {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adced {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adced {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adced {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adced {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-c7a42b0c {
  grid-area: 2 / 1 / 3 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-c7a42b0c {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-c7a42b0c {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-c7a42b0c {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-c7a42b0c {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-c7a42b0c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-c7a42b0c {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-0c5e28c6 {
  grid-area: 2 / 1 / 3 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-0c5e28c6 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-0c5e28c6 {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-0c5e28c6 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-0c5e28c6 {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-0c5e28c6 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-0c5e28c6 {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-_69a7afb0-68a6-7413-d04e-6f14b11998e2-c709075f, #w-node-_134577a8-5375-7584-7c90-aa83eeaafb46-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1de418c2-0a2a-f838-4c0d-6e181cd46573-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_36cad5fa-2653-f8d1-b87e-a2f3eeb8a3b0-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: center;
}

#w-node-_3493d8dc-b25b-9e3d-7a9e-1d471825ee2e-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_3493d8dc-b25b-9e3d-7a9e-1d471825ee2f-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: center;
}

#w-node-_3edd7cc1-7d17-6d99-7c34-8438d4f263cd-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-adb3e4cc-48fc-a9be-f7c8-4ce04ab1996a-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3edd7cc1-7d17-6d99-7c34-8438d4f263ce-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: center;
}

#w-node-_4bbcf177-67d5-7e45-171a-2b04c9ec6091-c709075f, #w-node-_6d288e6a-c121-d196-7134-9ac5503f7dc3-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9255643b-cb07-5771-7fba-5836ff7d90c9-c709075f {
  align-self: center;
}

#w-node-_1b60e406-85f6-10a6-93dc-d63c632163d4-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: auto;
}

#w-node-_18301b3a-9e1e-96cd-7b5b-bf0a4f959f33-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: end;
}

#w-node-c333bb8e-c3b9-1fea-16a6-51143cc8ff02-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5c4a7429-8541-90f3-6288-b45f463e47a3-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: end center;
}

#w-node-_5c4a7429-8541-90f3-6288-b45f463e47a4-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_58a6ee07-518c-9819-9077-5d51cbf4ec62-c709075f, #w-node-_8a4e94df-2149-99dc-4fa4-f73182713fea-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: end center;
}

#w-node-_8a4e94df-2149-99dc-4fa4-f73182713feb-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e004b2ba-f19e-201d-79d2-0e5dcfd812b4-c709075f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: auto;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e957b9-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e957be-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e957c3-653a62f4 {
  justify-self: start;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e9589e-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e958a6-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e958bd-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e958d6-653a62f4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e958d9-653a62f4 {
  justify-self: start;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e958db-653a62f4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e958de-653a62f4 {
  justify-self: start;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e95935-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e9593f-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95940-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95993-653a62f4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e959a0-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959a5-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959aa-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959af-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959b4-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959b9-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959be-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959c3-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959c8-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959cd-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959d2-653a62f4 {
  justify-self: start;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e959d6-653a62f4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e959d7-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959dc-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959e1-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959e6-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959fa-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e959ff-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a04-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a09-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a0e-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a13-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a1d-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a22-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a27-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a2c-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a31-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a36-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a3b-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a40-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a45-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a4a-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a4f-653a62f4 {
  justify-self: start;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e95a53-653a62f4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_72dbcc39-774f-cad4-3460-280a46e95a54-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a59-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a5e-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95a63-653a62f4, #w-node-_72dbcc39-774f-cad4-3460-280a46e95ae4-653a62f4 {
  justify-self: start;
}

#w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f08e3-13236f05 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f0933-13236f05, #w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f0934-13236f05, #w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f09ca-13236f05, #w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f09e2-13236f05, #w-node-_6cc742fd-8d6c-3645-dd56-100c965868d9-22f3cce9, #w-node-_6cc742fd-8d6c-3645-dd56-100c965868da-22f3cce9 {
  align-self: stretch;
}

#w-node-_0c150f9f-9dfa-a09e-f7f5-d24be058efd5-22f3cce9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0c150f9f-9dfa-a09e-f7f5-d24be058f0c1-22f3cce9, #w-node-_0c150f9f-9dfa-a09e-f7f5-d24be058f0d9-22f3cce9 {
  align-self: stretch;
}

#w-node-_7b791612-c662-b975-72cb-788b89706910-4dcbfc5f, #w-node-_7b791612-c662-b975-72cb-788b89706924-4dcbfc5f {
  align-self: start;
}

#w-node-_7b791612-c662-b975-72cb-788b8970699d-4dcbfc5f {
  align-self: stretch;
}

@media screen and (max-width: 991px) {
  #w-node-_69a7afb0-68a6-7413-d04e-6f14b11998e2-c709075f, #w-node-f13f5cb3-c4d9-ecb7-bd9a-a65ffd9ba223-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_1de418c2-0a2a-f838-4c0d-6e181cd46573-c709075f {
    grid-area: span 1 / span 3 / span 1 / span 3;
    align-self: center;
  }

  #w-node-_3493d8dc-b25b-9e3d-7a9e-1d471825ee2e-c709075f {
    grid-area: 2 / 1 / 3 / 4;
    align-self: center;
  }

  #w-node-_3edd7cc1-7d17-6d99-7c34-8438d4f263cd-c709075f {
    grid-area: 3 / 1 / 4 / 4;
    align-self: center;
  }

  #w-node-_6d288e6a-c121-d196-7134-9ac5503f7dc3-c709075f {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-_18301b3a-9e1e-96cd-7b5b-bf0a4f959f33-c709075f {
    justify-self: center;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adce6 {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adce6 {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adce6 {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adce6 {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adce6 {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adce6 {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adce6 {
    grid-area: 6 / 1 / 7 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8e-117adce6 {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-fa78de3b-bb1f-9f6d-38d5-51cb56632550-117adce6, #w-node-_596b18d4-2bc5-7cc5-0ef0-38855c1ced5f-117adce6 {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-f54bfbb0-2421-5312-840b-3ecae3303885-117adce6 {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-_81fae34e-2068-60c8-1384-3531e3285d04-e3285d04 {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-_9daeba24-3778-1d0f-4b75-22ba03d29670-03d2966e {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-_5c5713fe-5381-bdce-a779-91163e31f011-117adceb {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-_5c5713fe-5381-bdce-a779-91163e31f01d-117adceb {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-_5c5713fe-5381-bdce-a779-91163e31f029-117adceb {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-_5c5713fe-5381-bdce-a779-91163e31f02a-117adceb {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-_694e0a32-8e0a-829c-b6f3-9ca0084d2ffb-117adceb {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-fb58664a-6abb-56da-8fb9-69971f8deebb-117adceb, #w-node-dfce49df-34ed-ed82-9345-3e23133dbaff-117adceb {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adcec {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adcec, #w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adcec {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adcec {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adcec {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adcec {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adcec {
    grid-area: 6 / 1 / 7 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8e-117adcec {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-117adced {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-117adced, #w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-117adced {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-117adced {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-117adced {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adced {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-117adced {
    grid-area: 6 / 1 / 7 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8e-117adced {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-c7a42b0c {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-c7a42b0c, #w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-c7a42b0c {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-c7a42b0c {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-c7a42b0c {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-c7a42b0c {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-c7a42b0c {
    grid-area: 6 / 1 / 7 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8e-c7a42b0c {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c6d-0c5e28c6 {
    grid-area: 3 / 1 / 4 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c77-0c5e28c6, #w-node-d00632f3-03fb-2032-d93a-9f5d59610c78-0c5e28c6 {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c79-0c5e28c6 {
    grid-area: 1 / 1 / 2 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c85-0c5e28c6 {
    grid-area: 4 / 1 / 5 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-0c5e28c6 {
    grid-area: 5 / 1 / 6 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8d-0c5e28c6 {
    grid-area: 6 / 1 / 7 / 3;
  }

  #w-node-d00632f3-03fb-2032-d93a-9f5d59610c8e-0c5e28c6 {
    grid-area: 7 / 1 / 8 / 3;
  }

  #w-node-_6d288e6a-c121-d196-7134-9ac5503f7dc3-c709075f {
    grid-area: 2 / 1 / 3 / 3;
  }

  #w-node-_9255643b-cb07-5771-7fba-5836ff7d90c9-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_1b60e406-85f6-10a6-93dc-d63c632163d4-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: auto;
  }

  #w-node-d31812fe-7365-1c88-1020-d211d4bcddca-c709075f, #w-node-_87c59548-bb39-24af-d9f3-6546ad51038a-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_18301b3a-9e1e-96cd-7b5b-bf0a4f959f33-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
    place-self: end center;
  }

  #w-node-_5c4a7429-8541-90f3-6288-b45f463e47a3-c709075f {
    grid-area: 1 / 3 / 2 / 5;
    place-self: end center;
  }

  #w-node-_58a6ee07-518c-9819-9077-5d51cbf4ec62-c709075f {
    grid-area: 2 / 1 / 3 / 3;
    place-self: end center;
  }

  #w-node-_8a4e94df-2149-99dc-4fa4-f73182713fea-c709075f {
    grid-area: 2 / 3 / 3 / 5;
    place-self: end center;
  }

  #w-node-e004b2ba-f19e-201d-79d2-0e5dcfd812b4-c709075f {
    grid-area: 2 / 1 / 3 / 3;
    align-self: auto;
  }

  #w-node-e004b2ba-f19e-201d-79d2-0e5dcfd812a9-c709075f, #w-node-da8db31b-7e69-def6-5447-52435be900ad-c709075f, #w-node-da8db31b-7e69-def6-5447-52435be900b6-c709075f {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_9652a4d6-8e4d-01bf-80fd-746ccd5f0935-13236f05, #w-node-_6cc742fd-8d6c-3645-dd56-100c965868db-22f3cce9 {
    order: 0;
  }
}
h1.title {
    text-align: left;
}
h2.heading-5 {
    text-align: left;
}
p.paragraph-7 {
    text-align: left;
}

div#w-node-d00632f3-03fb-2032-d93a-9f5d59610c86-117adce6 {
    height: 95%;
}
h2.title {
    line-height: 109%;
    letter-spacing: -2px;
}
